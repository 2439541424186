import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { get } from 'lodash';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { Account } from '@app/account/account';
import { AbsSubmitPartHelper } from './abs-submit-part-helper';

@Component({
	selector: 'rd-cms-entry-abs-submit-part-b',
	templateUrl: './cms-entry-abs-submit-part-b.component.html'
})
export class CmsEntryAbsSubmitPartBComponent extends AbsSubmitPartHelper implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	sessionUser: Account;

	ngOnInit() {
		this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;

		const numberValidators = [Validators.max(8000000), Validators.maxLength(5), Validators.pattern(/^[0-9]*$/)];
		const decimalValidators = [
			Validators.max(99.99),
			Validators.maxLength(5),
			Validators.pattern(/^\d+(?:\.\d{1,2})?$/)
		];
		this.form = this.formBuilder.group({
			b_total_enr: [this.cmsResult.b_total_enr, numberValidators],
			b_ftns_fall: [this.cmsResult.b_ftns_fall, numberValidators],
			b_ftns_spring: [this.cmsResult.b_ftns_spring, numberValidators],
			b_ftns_total: { value: this.cmsResult.b_ftns_total, disabled: true },
			b_recruited_athletes: [this.cmsResult.b_recruited_athletes, decimalValidators]
		});

		if (!this.hasWritePermission()) {
			this.form.disable();
		}

		this.updateTotals('b_ftns_total', ['b_ftns_fall', 'b_ftns_spring']);

		this.listenForFormChanges();
	}

	updateTotals(updateField: string, fields: string[], tweakTotal: Function | null = null): void {
		const _updateTotals = () => {
			let total = 0;
			fields.forEach((field: string) => {
				total += parseInt(this.form.controls[field].value || 0);
			});
			if (tweakTotal) {
				total = tweakTotal(total);
			}
			this.form.controls[updateField].setValue(total);
		};
		fields.forEach((field: string) => {
			this.form.controls[field].valueChanges.subscribe(_updateTotals);
		});
		_updateTotals(); // trigger an update just in case legacy data wasn't saved properly
	}

	async save(): Promise<any> {
		if (this.form!.invalid) {
			this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
			return;
		}
		if (this.isSubmitting) {
			return;
		}

		const data = this.form.getRawValue();
		if (!this.sessionUser.isAdmin) {
			data.status = 0; // status should always be 0 unless an admin is saving, in which case we keep the status unchanged
		}
		data.naccap_abs_surveys_id = this.cmsResult.report.id;
		data.naccap_orgs_id = this.cmsResult.orgId || this.sessionUser.orgId;
		data.user_id = this.sessionUser.userId;
		data.b_last_user = this.sessionUser.userId;
		data.b_last_update = new Date();
		const entryId = this.cmsResult.entryId;
		if (entryId) {
			data.id = entryId;
		}
		this.isSubmitting = true;
		this.error = {};
		try {
			if (data.id) {
				await this.absEntryService.update(data);
			} else {
				const result = await this.absEntryService.create(data);
				this.form.patchValue({ id: result.insertId });
			}
			this.isSaved = true;
			this.onSaveSuccess();
		} catch (err) {
			this.error = err.error.error;
		}
		this.isSubmitting = false;
		this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
		setTimeout(() => {
			const url = get(this.cmsResult.urls, 'abs-submit-part-c');
			this.router.navigate([url], { queryParamsHandling: 'merge' });
		}, 2000);
	}
}
