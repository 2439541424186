import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trim } from 'lodash';

import { Account } from '@app/account/account';
import { AbsEntryService } from '@app/cms/abs-submit/abs-entry.service';
import { FunnelEntryService } from '@app/funnel/funnel-entry.service';

enum Type {
	ABS = 'abs',
	Funnel = 'funnel'
}

@Component({
	selector: 'rd-admin-report-actions',
	templateUrl: './admin-report-actions.component.html'
})
export class AdminReportActionsComponent {
	@Input()
	user: Account;

	@Input()
	entryId: number;

	@Input()
	orgId: number;

	@Input()
	reportId: number;

	@Input()
	type: Type;

	@Output()
	onSave: EventEmitter<number> = new EventEmitter();

	editor = null;
	config = {
		toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
	};
	rejectReason: any = '';
	error: { message: string } | null = null;
	isSubmitting = false;
	savedVerification = false;
	savedReject = false;

	constructor(private absEntryService: AbsEntryService, private funnelEntryService: FunnelEntryService) {}

	async verify() {
		const data = { id: this.entryId, status: 2 };
		await this.save(data);
		this.savedVerification = true;
		this.sendAcceptEmail();
		this.sendVerifiedEmail();
	}

	async reject() {
		const isReasonEmpty = trim(this.stripTags(this.rejectReason));
		if (!isReasonEmpty) {
			this.error = { message: 'Please add a reason first.' };
			return;
		}
		const data = { id: this.entryId, reject_reason: this.rejectReason, status: 0 };
		await this.save(data);
		this.savedReject = true;
		this.sendRejectEmail();
	}

	async save(data: any) {
		if (!this.user.isAdmin) {
			return;
		}
		if (!this.entryId) {
			throw new Error('Missing entryId');
		}
		if (!this.orgId) {
			throw new Error('Missing orgId');
		}
		if (!this.reportId) {
			throw new Error('Missing reportId');
		}
		data = { naccap_orgs_id: this.orgId, user_id: this.user.userId, ...data };
		this.appendReportId(data);
		this.isSubmitting = true;
		this.error = null;
		try {
			if (this.type === Type.ABS) {
				await this.absEntryService.update(data);
			} else if (this.type === Type.Funnel) {
				await this.funnelEntryService.update(data);
			} else {
				throw new Error('Missing type');
			}
		} catch (err) {
			this.error = { message: err.error.error.message };
			this.isSubmitting = false;
			throw err;
		}
		this.isSubmitting = false;
		this.onSave.emit(data.status);
	}

	appendReportId(data: any) {
		switch (this.type) {
			case Type.ABS:
				data.naccap_abs_surveys_id = this.reportId;
				break;
			case Type.Funnel:
				data.naccap_app_reports_id = this.reportId;
				break;
		}
	}

	sendRejectEmail() {
		switch (this.type) {
			case Type.ABS:
				this.absEntryService.sendRejectedEmail(this.orgId, this.reportId, this.rejectReason);
				break;
			case Type.Funnel:
				this.funnelEntryService.sendRejectedEmail(this.orgId, this.reportId, this.rejectReason);
				break;
		}
	}

	sendAcceptEmail() {
		switch (this.type) {
			case Type.ABS:
				this.absEntryService.sendAcceptedEmail(this.orgId, this.reportId);
				break;
			case Type.Funnel:
				this.funnelEntryService.sendAcceptedEmail(this.orgId, this.reportId);
				break;
		}
	}

	sendVerifiedEmail() {
		switch (this.type) {
			case Type.ABS:
				this.absEntryService.sendVerifiedEmail(this.user.userId, this.reportId, this.orgId);
				break;
			case Type.Funnel:
				this.funnelEntryService.sendVerifiedEmail(this.user.userId, this.reportId, this.orgId);
				break;
		}
	}

	stripTags(str: string) {
		const html = str;
		const div = document.createElement('div');
		div.innerHTML = html;
		return div.textContent || div.innerText || '';
	}
}
