"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_abs_base_1 = require("./cms-entry-result-abs-base");
var CmsEntryResultAbsList = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultAbsList, _super);
    function CmsEntryResultAbsList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CmsEntryResultAbsList.prototype, "isEntryVerified", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'status') == 2;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsList.prototype, "completedAbsReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsList.prototype, "report", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsList.prototype, "paginatedReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsList.prototype, "reportEntry", void 0);
    CmsEntryResultAbsList = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_abs_base_1.CmsEntryResultAbsBase)
    ], CmsEntryResultAbsList);
    return CmsEntryResultAbsList;
}(cms_entry_result_abs_base_1.CmsEntryResultAbsBase));
exports.CmsEntryResultAbsList = CmsEntryResultAbsList;
