"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var account_1 = require("@app/account/account");
var account_service_1 = require("@app/account/account.service");
var CmsEntryForgotPasswordComponent = /** @class */ (function () {
    function CmsEntryForgotPasswordComponent(formBuilder, accountService) {
        this.formBuilder = formBuilder;
        this.accountService = accountService;
        this.cmsResult = null;
        this.error = null;
        this.saved = false;
        this.isSubmitting = false;
        this.createForm();
    }
    CmsEntryForgotPasswordComponent.prototype.createForm = function () {
        this.form = this.formBuilder.group({
            email: ['', [forms_1.Validators.required]]
        });
    };
    CmsEntryForgotPasswordComponent.prototype.ngOnInit = function () { };
    CmsEntryForgotPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.isSubmitting) {
            return;
        }
        if (this.form.invalid) {
            this.error = { message: 'Fix errors and try again.' };
            return;
        }
        this.error = null;
        this.isSubmitting = true;
        this.accountService
            .forgotPassword(this.form.value.email)
            .then(function (data) {
            _this.saved = true;
            _this.isSubmitting = false;
        })
            .catch(function (data) {
            _this.error = { message: data.error.error.message };
            _this.isSubmitting = false;
        });
    };
    return CmsEntryForgotPasswordComponent;
}());
exports.CmsEntryForgotPasswordComponent = CmsEntryForgotPasswordComponent;
