"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultHomepage = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultHomepage, _super);
    function CmsEntryResultHomepage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CmsEntryResultHomepage.prototype, "mainTitle", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.feature.0.title.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "title1", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.feature.0.title1.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "content1", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.feature.0.content1.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "title2", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.feature.0.title2.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "content2", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.feature.0.content2.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "title3", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.feature.0.title3.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "content3", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.feature.0.content3.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "testimonialTitle", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.testimonial.0.title.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "testimonialContent", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.testimonial.0.content.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "testimonialName", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.testimonial.0.name.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "testimonialOrg", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.testimonial.0.org.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "showTestimonial", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.testimonial.0.show.value', 'no') === 'yes';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "absTitle", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.abs.0.title.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "absContent", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.abs.0.content.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "absLinkText", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.abs.0.link_text.value', 'Learn More');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "funnelTitle", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.funnel.0.title.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "funnelContent", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.funnel.0.content.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "funnelLinkText", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.funnel.0.link_text.value', 'View Summary');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "funnelImage", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.funnel.0.image.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "showContactSection", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.contact.0.show.value', 'no') === 'yes';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "contactTitle", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.contact.0.title.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "contactContent", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.contact.0.content.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "footerLogo", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.footer.0.logo.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "footerCopyright", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.footer.0.copyright.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "footerPhone", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.footer.0.phone.value', '(336) 603-6046');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "footerPhoneUrl", {
        get: function () {
            return 'tel:' + this.footerPhone.replace(/\D/g, '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "footerRightTitle", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.footer.0.right_title.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "footerRightContent", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.footer.0.right_content.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultHomepage.prototype, "footerContactText", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.footer.0.contact_text.value');
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultHomepage.prototype, "openFunnelReport", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultHomepage.prototype, "completedFunnelReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultHomepage.prototype, "openAbsReport", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultHomepage.prototype, "mostRecentAbsReport", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultHomepage.prototype, "completedAbsReports", void 0);
    CmsEntryResultHomepage = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultHomepage);
    return CmsEntryResultHomepage;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultHomepage = CmsEntryResultHomepage;
