"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var MOUSE_LOCS_TRACKED = 3;
var DELAY = 300;
var DropdownDirective = /** @class */ (function () {
    function DropdownDirective(_elementRef, router) {
        var _this = this;
        this._elementRef = _elementRef;
        this.router = router;
        this.activeDropdown = null;
        this.mouseLocations = [];
        this.lastDelayLocation = null;
        this.timeoutId = null;
        this.router.events.subscribe(function () {
            _this.deactivate();
        });
    }
    DropdownDirective.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.triggerChildren.changes.subscribe(function (changes) {
            _this._addTriggerEventListeners(changes);
        });
        this._addTriggerEventListeners(this.triggerChildren);
    };
    DropdownDirective.prototype._addTriggerEventListeners = function (triggers) {
        var _this = this;
        this.triggerChildren.forEach(function (trigger) {
            trigger.nativeElement.addEventListener('mouseenter', function (e) {
                if (_this.timeoutId) {
                    clearTimeout(_this.timeoutId);
                }
                _this.possiblyActivate(e.srcElement);
            });
            trigger.nativeElement.addEventListener('click', function () { return _this.activate(trigger.nativeElement); });
        });
    };
    DropdownDirective.prototype.onMouseLeave = function () {
        var _this = this;
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        if (this.activeDropdown) {
            this.timeoutId = setTimeout(function () {
                _this.deactivate();
                _this.activeDropdown = null;
            }, DELAY);
        }
    };
    DropdownDirective.prototype.onMouseMove = function (x, y) {
        this.mouseLocations.push({ x: x, y: y });
        if (this.mouseLocations.length > MOUSE_LOCS_TRACKED) {
            this.mouseLocations.shift();
        }
    };
    DropdownDirective.prototype.possiblyActivate = function (dropdown) {
        var _this = this;
        var delay = this.activationDelay();
        if (delay) {
            this.timeoutId = setTimeout(function () {
                _this.possiblyActivate(dropdown);
            }, delay);
        }
        else {
            this.activate(dropdown);
        }
    };
    DropdownDirective.prototype.activate = function (dropdown) {
        if (dropdown === this.activeDropdown) {
            return;
        }
        if (this.activeDropdown) {
            this.deactivate();
        }
        this.toggleDropdown(dropdown, true);
        this.activeDropdown = dropdown;
    };
    DropdownDirective.prototype.deactivate = function () {
        if (!this.activeDropdown) {
            return;
        }
        this.toggleDropdown(this.activeDropdown, false);
    };
    DropdownDirective.prototype.activationDelay = function () {
        if (!this.activeDropdown) {
            return 0;
        }
        var tolerance = 75;
        var native = this._elementRef.nativeElement;
        var _a = native.getBoundingClientRect(), menuHeight = _a.height, menuWidth = _a.width;
        var lowerLeft = {
            x: native.offsetLeft,
            y: native.offsetTop + menuHeight + tolerance
        }, lowerRight = {
            x: native.offsetLeft + menuWidth,
            y: lowerLeft.y
        }, location = this.mouseLocations[this.mouseLocations.length - 1];
        var prevLocation = this.mouseLocations[0];
        if (!location) {
            return 0;
        }
        if (!prevLocation) {
            prevLocation = location;
        }
        if (prevLocation.x < native.offsetLeft ||
            prevLocation.x > lowerRight.x ||
            prevLocation.y < native.offsetTop ||
            prevLocation.y > lowerRight.y) {
            return 0;
        }
        if (this.lastDelayLocation && location.x === this.lastDelayLocation.x && location.y === this.lastDelayLocation.y) {
            return 0;
        }
        var slope = function (a, b) { return (b.y - a.y) / (b.x - a.x); };
        var decreasingCorner = lowerRight, increasingCorner = lowerLeft;
        var decreasingSlope = slope(location, decreasingCorner), increasingSlope = slope(location, increasingCorner), prevDecreasingSlope = slope(prevLocation, decreasingCorner), prevIncreasingSlope = slope(prevLocation, increasingCorner);
        if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
            this.lastDelayLocation = location;
            return DELAY;
        }
        this.lastDelayLocation = null;
        return 0;
    };
    DropdownDirective.prototype.toggleDropdown = function (dropdown, show) {
        var index = this.triggerChildren
            .toArray()
            .map(function (ref) { return ref.nativeElement; })
            .findIndex(function (el) { return el === dropdown; });
        var classList = this.dropdownChildren.toArray()[index].nativeElement.classList;
        if (show) {
            classList.add('show');
        }
        else {
            classList.remove('show');
        }
    };
    return DropdownDirective;
}());
exports.DropdownDirective = DropdownDirective;
