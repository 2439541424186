"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var AbsSubmitFieldComponent = /** @class */ (function () {
    function AbsSubmitFieldComponent() {
        this.showLastYear = true;
        this.isReadonly = false;
    }
    AbsSubmitFieldComponent.prototype.ngOnInit = function () {
        this.helpText = this.cmsResult.getHelpText(this.field);
        if (!this.lastYearValue) {
            var key = "previous_" + this.field;
            this.lastYearValue = this.cmsResult[key];
        }
    };
    AbsSubmitFieldComponent.prototype.toggleHelp = function () {
        this.cmsResult.activeInput = this.field;
    };
    return AbsSubmitFieldComponent;
}());
exports.AbsSubmitFieldComponent = AbsSubmitFieldComponent;
