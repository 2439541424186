"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var cms_entry_result_funnel_base_1 = require("./cms-entry-result-funnel-base");
var CmsEntryResultFunnelSummary = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultFunnelSummary, _super);
    function CmsEntryResultFunnelSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CmsEntryResultFunnelSummary.prototype.getChartDataByField = function (field) {
        var data = [];
        var labels = [];
        if (this.summaryData[field + "_more"] > 0) {
            data.push(this.summaryData[field + "_more"]);
            labels.push('More');
        }
        if (this.summaryData[field + "_fewer"] > 0) {
            data.push(this.summaryData[field + "_fewer"]);
            labels.push('Fewer');
        }
        if (this.summaryData[field + "_less"] > 0) {
            data.push(this.summaryData[field + "_less"]);
            labels.push('Less');
        }
        if (this.summaryData[field + "_even"] > 0) {
            data.push(this.summaryData[field + "_even"]);
            labels.push('Even');
        }
        if (field === 'vs_goal' && this.summaryData["vs_goal_no_goal"] > 0) {
            data.push(this.summaryData["vs_goal_no_goal"]);
            labels.push('No Goal');
        }
        else if (this.summaryData[field + "_nc"] > 0) {
            data.push(this.summaryData[field + "_nc"]);
            labels.push('No Comp.');
        }
        return { data: data, labels: labels };
    };
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelSummary.prototype, "report", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelSummary.prototype, "summaryData", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelSummary.prototype, "mostRecentReport", void 0);
    CmsEntryResultFunnelSummary = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_funnel_base_1.CmsEntryResultFunnelBase)
    ], CmsEntryResultFunnelSummary);
    return CmsEntryResultFunnelSummary;
}(cms_entry_result_funnel_base_1.CmsEntryResultFunnelBase));
exports.CmsEntryResultFunnelSummary = CmsEntryResultFunnelSummary;
