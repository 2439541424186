"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var cms_entry_service_1 = require("@app/cms/cms-entry.service");
var account_service_1 = require("@app/account/account.service");
var funnel_entry_service_1 = require("@app/funnel/funnel-entry.service");
var abs_entry_service_1 = require("@app/cms/abs-submit/abs-entry.service");
var cms_entry_result_homepage_1 = require("@app/cms/results/cms-entry-result-homepage");
var environment_1 = require("@env/environment");
var AppComponent = /** @class */ (function () {
    function AppComponent(cmsEntryService, accountService, funnelEntryService, absEntryService, router) {
        var _this = this;
        this.cmsEntryService = cmsEntryService;
        this.accountService = accountService;
        this.funnelEntryService = funnelEntryService;
        this.absEntryService = absEntryService;
        this.router = router;
        this.navigation = [];
        this.navigationIncludingHidden = [];
        this.now = new Date();
        this.urls = {};
        this.environment = environment_1.environment;
        this.isMenuExpanded = false;
        this.cmsEntryService.navigation$.subscribe(function (navigation) {
            _this.navigation = navigation;
        });
        this.cmsEntryService.navigationIncludingHidden$.subscribe(function (navigationIncludingHidden) {
            _this.navigationIncludingHidden = navigationIncludingHidden;
        });
        this.accountService.loggedIn.subscribe(function (loggedIn) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.accountService.getSessionUser()];
                    case 1:
                        _a.sessionUser = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        this.router.events.subscribe(function () {
            _this.isMenuExpanded = false;
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, entry;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.cmsEntryService.getSiteNavigation();
                        this.cmsEntryService.getSiteNavigationIncludingHidden();
                        this.cmsEntryService.getSiteUrlMap().then(function (urls) { return (_this.urls = urls); });
                        return [4 /*yield*/, Promise.all([
                                this.accountService.getSessionUser(),
                                this.funnelEntryService.getLatestOpenReport(),
                                this.absEntryService.getOpenReport(),
                                this.absEntryService.getLatestOpenReport(),
                                this.cmsEntryService.getEntryByUrl('/index')
                            ])];
                    case 1:
                        _a = _b.sent(), this.sessionUser = _a[0], this.funnelReport = _a[1], this.absOpenReport = _a[2], this.absLatestReport = _a[3], entry = _a[4];
                        if (entry instanceof cms_entry_result_homepage_1.CmsEntryResultHomepage) {
                            this.homepageEntry = entry;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.logout = function () {
        this.accountService.logout().then(function () {
            window.location.reload();
        });
    };
    AppComponent.prototype.navigate = function (url) {
        this.router.navigate([url]);
    };
    AppComponent.prototype.toggleMobileNav = function () {
        this.isMenuExpanded = !this.isMenuExpanded;
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
