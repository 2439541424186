"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var FullResourcePath = /** @class */ (function () {
    function FullResourcePath() {
    }
    FullResourcePath.prototype.transform = function (path) {
        return transformFullResourcePath(path);
    };
    FullResourcePath.ngInjectableDef = i0.defineInjectable({ factory: function FullResourcePath_Factory() { return new FullResourcePath(); }, token: FullResourcePath, providedIn: "root" });
    return FullResourcePath;
}());
exports.FullResourcePath = FullResourcePath;
function transformFullResourcePath(path) {
    if (!path) {
        return path;
    }
    var resourceRoot = 'https://s3.amazonaws.com/naccap';
    if (path.indexOf(resourceRoot) === 0) {
        return path;
    }
    return resourceRoot + path;
}
exports.transformFullResourcePath = transformFullResourcePath;
