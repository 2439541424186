"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MAX_NUM_OF_PAGES = 11;
var PaginationComponent = /** @class */ (function () {
    function PaginationComponent() {
        this.onPageChanged = new core_1.EventEmitter();
    }
    PaginationComponent.prototype.ngOnChanges = function (changes) {
        this.refresh();
    };
    PaginationComponent.prototype.refresh = function () {
        var total = Math.ceil(this.numItems / this.perPage);
        this.pageLast = total;
        this.pages = [];
        var first = 1;
        var last = total;
        if (total > 1) {
            if (total > MAX_NUM_OF_PAGES) {
                if (this.page >= total - 5) {
                    first = total - MAX_NUM_OF_PAGES;
                }
                else if (this.page - 5 > 0) {
                    first = this.page - 5;
                }
                last = this.page < 6 ? 10 : first + MAX_NUM_OF_PAGES;
            }
            for (var i = first; i <= last; i++) {
                this.pages.push(i);
            }
        }
        this.showFirst = first > 1;
        this.showLast = last < total;
    };
    PaginationComponent.prototype.onNext = function () {
        if (this.page >= this.pageLast) {
            this.page = this.pageLast;
            return;
        }
        this.page++;
        this.refresh();
        this.onPageChanged.emit(this.page);
    };
    PaginationComponent.prototype.onPrev = function () {
        if (this.page <= 1) {
            this.page = 1;
            return;
        }
        this.page--;
        this.refresh();
        this.onPageChanged.emit(this.page);
    };
    PaginationComponent.prototype.onPage = function (page) {
        this.page = page;
        this.refresh();
        this.onPageChanged.emit(this.page);
    };
    return PaginationComponent;
}());
exports.PaginationComponent = PaginationComponent;
