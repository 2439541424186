"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var cms_entry_result_abs_landing_1 = require("@app/cms/results/cms-entry-result-abs-landing");
var account_1 = require("@app/account/account");
var account_service_1 = require("@app/account/account.service");
var CmsEntryAbsLandingComponent = /** @class */ (function () {
    function CmsEntryAbsLandingComponent(accountService) {
        this.accountService = accountService;
        this.filters = {
            publicReport: null,
            memberReport: null
        };
    }
    CmsEntryAbsLandingComponent.prototype.ngOnInit = function () { };
    CmsEntryAbsLandingComponent.prototype.ngOnChanges = function (changes) {
        if ((lodash_1.get(changes, 'cmsResult', false) || lodash_1.get(changes, 'sessionUser', false)) && this.cmsResult) {
            var reportId_1 = lodash_1.get(changes, 'cmsResult.currentValue.report.id');
            var publicReport = lodash_1.find(lodash_1.get(changes, 'cmsResult.currentValue.completedAbsReports', []), function (report) { return report.id == reportId_1; });
            this.filters.publicReport = publicReport;
            var memberReport = lodash_1.find(this.getValidatedReportsForUser(), function (report) { return report.id == reportId_1; });
            this.filters.memberReport = memberReport;
        }
    };
    CmsEntryAbsLandingComponent.prototype.getValidatedReportsForUser = function () {
        if (lodash_1.get(this.sessionUser, 'isAdmin', false)) {
            return lodash_1.get(this.cmsResult, 'completedAbsReports', []);
        }
        return lodash_1.get(this.cmsResult, 'verifiedAbsReportsForUser', []);
    };
    return CmsEntryAbsLandingComponent;
}());
exports.CmsEntryAbsLandingComponent = CmsEntryAbsLandingComponent;
