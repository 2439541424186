"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var cms_entry_result_funnel_summary_1 = require("@app/cms/results/cms-entry-result-funnel-summary");
var FunnelSummarySectionComponent = /** @class */ (function () {
    function FunnelSummarySectionComponent() {
    }
    FunnelSummarySectionComponent.prototype.ngOnInit = function () { };
    return FunnelSummarySectionComponent;
}());
exports.FunnelSummarySectionComponent = FunnelSummarySectionComponent;
