"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var account_service_1 = require("@app/account/account.service");
var environment_1 = require("@env/environment");
var LoginComponent = /** @class */ (function () {
    function LoginComponent(activatedRoute, router, accountService, formBuilder) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.accountService = accountService;
        this.formBuilder = formBuilder;
        this.type = 'standalone';
        this.error = null;
        this.defaultBackLocation = '/';
        this.login = {
            back_location: '',
            email: '',
            password: ''
        };
        this.errors = [];
        this.createForm();
    }
    LoginComponent.prototype.createForm = function () {
        this.form = this.formBuilder.group({
            email: ['', [forms_1.Validators.required]],
            password: ['', forms_1.Validators.required]
        });
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (params) {
            var back = params['back_location'];
            _this.login.back_location = back && back.length > 0 ? back : _this.defaultBackLocation;
        });
    };
    Object.defineProperty(LoginComponent.prototype, "email", {
        get: function () {
            return this.form.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "password", {
        get: function () {
            return this.form.get('password');
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.redirectToAdmin = function (url) {
        url = url == '/' ? '/admin' : url;
        if (environment_1.environment.production) {
            window.location.href = url;
        }
        else {
            window.location.href = "http://localhost:3000" + url;
        }
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.form.invalid) {
            this.error = { message: 'Please add a reason for placing this report on hold.' };
            return;
        }
        this.accountService
            .login(this.form.value.email, this.form.value.password)
            .then(function (data) {
            if (data.user.isAdmin &&
                (_this.login.back_location == _this.defaultBackLocation || _this.login.back_location.indexOf('/admin') > -1)) {
                _this.redirectToAdmin(_this.login.back_location);
            }
            else {
                _this.router.navigate([_this.login.back_location]);
            }
        })
            .catch(function (data) {
            _this.error = { message: data.error.error.message };
        });
    };
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
