"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var BaseTemplateComponent = /** @class */ (function () {
    function BaseTemplateComponent() {
    }
    BaseTemplateComponent.prototype.ngOnInit = function () { };
    return BaseTemplateComponent;
}());
exports.BaseTemplateComponent = BaseTemplateComponent;
