"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("@env/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var AbsEntryService = /** @class */ (function () {
    function AbsEntryService(http) {
        this.http = http;
    }
    AbsEntryService.prototype.create = function (data) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/abs-entry", data).toPromise();
    };
    AbsEntryService.prototype.update = function (data) {
        return this.http.patch(environment_1.environment.apiUrl + "/crud/abs-entry", data).toPromise();
    };
    AbsEntryService.prototype.getOpenReport = function () {
        return this.http.get(environment_1.environment.apiUrl + "/crud/abs/open").toPromise();
    };
    AbsEntryService.prototype.getLatestOpenReport = function () {
        return this.http.get(environment_1.environment.apiUrl + "/crud/abs/open-latest").toPromise();
    };
    AbsEntryService.prototype.sendConfirmationEmail = function (user_id, report_id) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/email/abs-confirmation", { user_id: user_id, report_id: report_id }).toPromise();
    };
    AbsEntryService.prototype.sendAdminEmail = function (user_id, report_id) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/email/abs-completed", { user_id: user_id, report_id: report_id }).toPromise();
    };
    AbsEntryService.prototype.sendRejectedEmail = function (org_id, report_id, reason) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/email/abs-rejected", { org_id: org_id, report_id: report_id, reason: reason })
            .toPromise();
    };
    AbsEntryService.prototype.sendAcceptedEmail = function (org_id, report_id) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/email/abs-accepted", { org_id: org_id, report_id: report_id }).toPromise();
    };
    AbsEntryService.prototype.sendVerifiedEmail = function (user_id, report_id, org_id) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/email/abs-verified", { user_id: user_id, report_id: report_id, org_id: org_id })
            .toPromise();
    };
    AbsEntryService.prototype.sendCommentToAdmin = function (user_id, report_id, part, comment) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/email/abs-comment-to-admin/" + part, { user_id: user_id, report_id: report_id, comment: comment })
            .toPromise();
    };
    AbsEntryService.prototype.sendCommentToOrg = function (org_id, report_id, part, comment) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/email/abs-comment-to-org/" + part, { org_id: org_id, report_id: report_id, comment: comment })
            .toPromise();
    };
    AbsEntryService.prototype.addComment = function (part, name, comment, user_id, naccap_abs_data_id) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/abs-comment", { part: part, name: name, comment: comment, user_id: user_id, naccap_abs_data_id: naccap_abs_data_id })
            .toPromise();
    };
    AbsEntryService.prototype.getComments = function (part, naccap_abs_data_id) {
        return this.http.get(environment_1.environment.apiUrl + "/crud/abs-comment/list/" + naccap_abs_data_id + "/" + part).toPromise();
    };
    AbsEntryService.ngInjectableDef = i0.defineInjectable({ factory: function AbsEntryService_Factory() { return new AbsEntryService(i0.inject(i1.HttpClient)); }, token: AbsEntryService, providedIn: "root" });
    return AbsEntryService;
}());
exports.AbsEntryService = AbsEntryService;
