"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var platform_browser_1 = require("@angular/platform-browser");
var rxjs_1 = require("rxjs");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_1 = require("@app/cms/results/cms-entry-result");
var cms_entry_result_homepage_1 = require("@app/cms/results/cms-entry-result-homepage");
var cms_entry_result_abs_landing_1 = require("@app/cms/results/cms-entry-result-abs-landing");
var cms_entry_result_abs_list_1 = require("@app/cms/results/cms-entry-result-abs-list");
var cms_entry_result_abs_summary_1 = require("@app/cms/results/cms-entry-result-abs-summary");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var cms_entry_result_funnel_landing_1 = require("@app/cms/results/cms-entry-result-funnel-landing");
var cms_entry_result_funnel_summary_1 = require("@app/cms/results/cms-entry-result-funnel-summary");
var cms_entry_result_funnel_report_1 = require("@app/cms/results/cms-entry-result-funnel-report");
var cms_entry_result_report_history_1 = require("@app/cms/results/cms-entry-result-report-history");
var cms_entry_result_admin_abs_tool_1 = require("@app/cms/results/cms-entry-result-admin-abs-tool");
var cms_entry_result_404_1 = require("@app/cms/results/cms-entry-result-404");
var environment_1 = require("@env/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("@angular/platform-browser");
var i3 = require("@angular/router");
var CmsEntryService = /** @class */ (function () {
    function CmsEntryService(http, sanitizer, router) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.router = router;
        // private member that triggers events to the public variable
        this.navigationSource = new rxjs_1.Subject();
        this.navigationSourceIncludingHidden = new rxjs_1.Subject();
        // Observable navigation stream that can be subscribed to
        this.navigation$ = this.navigationSource.asObservable();
        this.navigationIncludingHidden$ = this.navigationSourceIncludingHidden.asObservable();
        this.navigationSource.next([]);
        this.navigationSourceIncludingHidden.next([]);
    }
    CmsEntryService.prototype.getEntryByUrl = function (url, queryParams) {
        var _this = this;
        if (queryParams === void 0) { queryParams = {}; }
        return this.http
            .get(environment_1.environment.apiUrl + "/crud/content/entry/by/url", {
            params: tslib_1.__assign({ url: url }, queryParams)
        })
            .toPromise()
            .then(function (res) {
            return _this.deserializeByResponse(res);
        })
            .catch(function (res) {
            var errorType = lodash_1.get(res, 'error.error.name');
            console.log('CMS entry error', errorType);
            if (res.status === 404) {
                return cerialize_1.Deserialize(res, cms_entry_result_404_1.CmsEntryResult404);
            }
            else {
                switch (errorType) {
                    case 'NoAccessError':
                        _this.router.navigate(['login'], {
                            queryParams: { back_location: window.location.pathname }
                        });
                        break;
                    default:
                        // TODO: we need to handle other status codes better
                        return _this.deserializeByResponse(res);
                }
            }
        })
            .then(function (entry) {
            return entry.sanitize(_this.sanitizer);
        });
    };
    CmsEntryService.prototype.deserializeByResponse = function (res) {
        var template = lodash_1.get(res, 'cms_template.template');
        switch (template) {
            case 'index':
                return cerialize_1.Deserialize(res, cms_entry_result_homepage_1.CmsEntryResultHomepage);
            case 'subpage':
            case 'my-account':
                return cerialize_1.Deserialize(res, cms_entry_result_1.CmsEntryResult);
            case 'abs-landing':
                return cerialize_1.Deserialize(res, cms_entry_result_abs_landing_1.CmsEntryResultAbsLanding);
            case 'abs-list':
                return cerialize_1.Deserialize(res, cms_entry_result_abs_list_1.CmsEntryResultAbsList);
            case 'abs-summary':
                return cerialize_1.Deserialize(res, cms_entry_result_abs_summary_1.CmsEntryResultAbsSummary);
            case 'abs-submit':
            case 'abs-submit-part-a':
            case 'abs-submit-part-b':
            case 'abs-submit-part-c':
            case 'abs-submit-part-d':
                return cerialize_1.Deserialize(res, cms_entry_result_abs_submit_1.CmsEntryResultAbsSubmit);
            case 'funnel-landing':
            case 'watchlist':
                return cerialize_1.Deserialize(res, cms_entry_result_funnel_landing_1.CmsEntryResultFunnelLanding);
            case 'funnel-summary':
                return cerialize_1.Deserialize(res, cms_entry_result_funnel_summary_1.CmsEntryResultFunnelSummary);
            case 'funnel-report':
                return cerialize_1.Deserialize(res, cms_entry_result_funnel_report_1.CmsEntryResultFunnelReport);
            case 'report-history':
                return cerialize_1.Deserialize(res, cms_entry_result_report_history_1.CmsEntryResultReportHistory);
            case 'admin-abs-tool':
                return cerialize_1.Deserialize(res, cms_entry_result_admin_abs_tool_1.CmsEntryResultAdminAbsTool);
            default:
                return cerialize_1.Deserialize(res, cms_entry_result_1.CmsEntryResult);
        }
    };
    CmsEntryService.prototype.getSiteNavigation = function () {
        var _this = this;
        return this.http
            .get(environment_1.environment.apiUrl + "/crud/content/entry/list/site-navigation")
            .toPromise()
            .then(function (items) {
            _this.navigationSource.next(items);
            return items;
        });
    };
    CmsEntryService.prototype.getSiteNavigationIncludingHidden = function () {
        var _this = this;
        return this.http
            .get(environment_1.environment.apiUrl + "/crud/content/entry/list/site-navigation-including-hidden")
            .toPromise()
            .then(function (items) {
            _this.navigationSourceIncludingHidden.next(items);
            return items;
        });
    };
    CmsEntryService.prototype.getSiteUrlMap = function () {
        return this.http.get(environment_1.environment.apiUrl + "/crud/cms/url-map").toPromise();
    };
    CmsEntryService.ngInjectableDef = i0.defineInjectable({ factory: function CmsEntryService_Factory() { return new CmsEntryService(i0.inject(i1.HttpClient), i0.inject(i2.DomSanitizer), i0.inject(i3.Router)); }, token: CmsEntryService, providedIn: "root" });
    return CmsEntryService;
}());
exports.CmsEntryService = CmsEntryService;
