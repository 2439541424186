"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_funnel_base_1 = require("./cms-entry-result-funnel-base");
var CmsEntryResultFunnelLanding = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultFunnelLanding, _super);
    function CmsEntryResultFunnelLanding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CmsEntryResultFunnelLanding.prototype, "isEntryVerified", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'status') == 2;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelLanding.prototype, "report", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelLanding.prototype, "reportEntry", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelLanding.prototype, "watchlist", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelLanding.prototype, "paginatedReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelLanding.prototype, "mostRecentReport", void 0);
    CmsEntryResultFunnelLanding = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_funnel_base_1.CmsEntryResultFunnelBase)
    ], CmsEntryResultFunnelLanding);
    return CmsEntryResultFunnelLanding;
}(cms_entry_result_funnel_base_1.CmsEntryResultFunnelBase));
exports.CmsEntryResultFunnelLanding = CmsEntryResultFunnelLanding;
