"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var FunnelReportFilterComponent = /** @class */ (function () {
    function FunnelReportFilterComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.includeSearch = false;
        this.isAbs = false;
        this.update = new core_1.EventEmitter();
        this.filters = this.formBuilder.group({
            search: '',
            report: '',
            year: ''
        });
    }
    FunnelReportFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filters
            .get('search')
            .valueChanges.pipe(operators_1.debounceTime(250))
            .subscribe(function (search) {
            console.log('search', search);
            _this.sendUpdate();
        });
        this.filters.get('year').valueChanges.subscribe(function (year) {
            if (_this.isAbs) {
                _this.sendUpdate();
            }
            // TODO: find a matching report for the new year
        });
    };
    FunnelReportFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.reports) {
            this.initCompletedReportsMap(changes.reports.currentValue);
        }
        if (changes.defaults) {
            if (!this.filters.controls.report.value) {
                if (this.isAbs) {
                    this.filters.controls.year.setValue(this.defaults.reportId, { emitEvent: false });
                }
                else {
                    this.filters.controls.year.setValue(this.getReportYear(this.defaults.reportId), { emitEvent: false });
                    this.filters.controls.report.setValue(this.defaults.reportId, { emitEvent: false });
                }
            }
            if (!this.filters.controls.search.value) {
                this.filters.controls.search.setValue(this.defaults.search, { emitEvent: false });
            }
        }
    };
    FunnelReportFilterComponent.prototype.getReportsForYear = function (year) {
        if (!this.reportsMap) {
            return [];
        }
        return this.reportsMap[year];
    };
    FunnelReportFilterComponent.prototype.sendUpdate = function () {
        if (this.isAbs) {
            var search = this.filters.controls.search.value;
            var reportId = this.filters.controls.year.value;
            this.update.emit({ search: search, reportId: reportId });
        }
        else {
            var search = this.filters.controls.search.value;
            var reportId = this.filters.controls.report.value;
            var year = this.filters.controls.year.value;
            // if placeholder "Select report" is selected, no action
            if (reportId !== 'null') {
                this.update.emit({ search: search, reportId: reportId, year: year });
            }
        }
    };
    FunnelReportFilterComponent.prototype.initCompletedReportsMap = function (reports) {
        var _this = this;
        if (!reports) {
            return;
        }
        this.reportsMap = {};
        this.reportYears = [];
        reports.forEach(function (report) {
            var year = report.reportYear;
            var map = _this.reportsMap[year];
            if (!map) {
                map = [];
            }
            map.push(report);
            _this.reportsMap[year] = map;
            _this.reportYears.push(year);
        });
        this.reportYears = lodash_1.uniq(this.reportYears);
    };
    FunnelReportFilterComponent.prototype.getReportYear = function (id) {
        if (this.isAbs) {
            return this.reports;
        }
        if (!id) {
            return;
        }
        var report = this.getReportById(id);
        return report ? report.reportYear : undefined;
    };
    FunnelReportFilterComponent.prototype.getReportById = function (id) {
        return lodash_1.find(this.reports, function (report) { return report.id == id; });
    };
    return FunnelReportFilterComponent;
}());
exports.FunnelReportFilterComponent = FunnelReportFilterComponent;
