"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var account_service_1 = require("@app/account/account.service");
var abs_entry_service_1 = require("./abs-entry.service");
var AbsSubmitPartHelper = /** @class */ (function () {
    function AbsSubmitPartHelper(formBuilder, absEntryService, router, accountService) {
        this.formBuilder = formBuilder;
        this.absEntryService = absEntryService;
        this.router = router;
        this.accountService = accountService;
    }
    AbsSubmitPartHelper.prototype.listenForFormChanges = function () {
        var _this = this;
        this.form.valueChanges.subscribe(function (values) {
            for (var field in values) {
                if (values[field] !== _this.cmsResult[field]) {
                    _this.accountService.hasUnsavedChanges = true;
                    return;
                }
            }
            _this.accountService.hasUnsavedChanges = false;
        });
    };
    AbsSubmitPartHelper.prototype.onSaveSuccess = function () {
        this.accountService.hasUnsavedChanges = false;
    };
    AbsSubmitPartHelper.prototype.canSubmit = function () {
        return ((this.hasWritePermission() && this.cmsResult.isOpen && this.cmsResult.existingStatus === 0) ||
            this.sessionUser.isAdmin === true);
    };
    AbsSubmitPartHelper.prototype.hasWritePermission = function () {
        return (this.sessionUser.isAdmin || (this.cmsResult.existingStatus === 0 && this.sessionUser.orgId === this.getOrgId()));
    };
    AbsSubmitPartHelper.prototype.hasReadPermission = function () {
        return (this.sessionUser.isAdmin || this.sessionUser.orgId === this.getOrgId() || this.cmsResult.isUserReportValidated());
    };
    AbsSubmitPartHelper.prototype.getOrgId = function () {
        return this.cmsResult.org.id;
    };
    return AbsSubmitPartHelper;
}());
exports.AbsSubmitPartHelper = AbsSubmitPartHelper;
