"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var cms_entry_result_funnel_landing_1 = require("@app/cms/results/cms-entry-result-funnel-landing");
var account_service_1 = require("@app/account/account.service");
var account_1 = require("@app/account/account");
var CmsEntryFunnelLandingComponent = /** @class */ (function () {
    function CmsEntryFunnelLandingComponent(router, route, accountService) {
        this.router = router;
        this.route = route;
        this.accountService = accountService;
        this.page = 1;
        this.filters = {};
        this.defaults = {};
    }
    CmsEntryFunnelLandingComponent.prototype.ngOnInit = function () {
        this.defaults = {
            reportId: this.route.snapshot.queryParams.id || this.cmsResult.mostRecentReport.id,
            search: this.route.snapshot.queryParams.search
        };
        this.page = this.route.snapshot.queryParams.page;
    };
    CmsEntryFunnelLandingComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.cmsResult) {
            this.cmsResult.paginatedReports.items.map(function (item) {
                item._is_on_watchlist = _this.cmsResult.watchlist.indexOf(item.naccap_orgs_id) > -1;
                return item;
            });
        }
    };
    Object.defineProperty(CmsEntryFunnelLandingComponent.prototype, "reportId", {
        get: function () {
            return this.filters.reportId || this.defaults.reportId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryFunnelLandingComponent.prototype, "search", {
        get: function () {
            return this.filters.search || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryFunnelLandingComponent.prototype, "isVerifiedToViewResults", {
        get: function () {
            return this.sessionUser.isAdmin || this.cmsResult.isEntryVerified;
        },
        enumerable: true,
        configurable: true
    });
    CmsEntryFunnelLandingComponent.prototype.export = function () {
        this.accountService.exportFunnel(this.cmsResult.report.id);
    };
    CmsEntryFunnelLandingComponent.prototype.exportFinal = function () {
        this.accountService.exportFunnelFinal(this.cmsResult.report.id);
    };
    CmsEntryFunnelLandingComponent.prototype.onPageChanged = function (page) {
        this.page = page;
        this.updatePage();
    };
    CmsEntryFunnelLandingComponent.prototype.onFilterUpdate = function (filters) {
        this.filters = filters;
        this.page = 1;
        this.updatePage();
    };
    CmsEntryFunnelLandingComponent.prototype.updatePage = function () {
        var page = this.page;
        var search = this.search;
        var id = this.reportId;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: page, search: search, id: id }
        });
    };
    CmsEntryFunnelLandingComponent.prototype.onWatchlistChanged = function (report) {
        if (report._is_on_watchlist) {
            return this.accountService.addOrgToWatchlist(this.sessionUser.userId, report.naccap_orgs_id);
        }
        return this.accountService.removeOrgFromWatchlist(this.sessionUser.userId, report.naccap_orgs_id);
    };
    return CmsEntryFunnelLandingComponent;
}());
exports.CmsEntryFunnelLandingComponent = CmsEntryFunnelLandingComponent;
