"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var cms_entry_result_abs_summary_1 = require("@app/cms/results/cms-entry-result-abs-summary");
var account_1 = require("@app/account/account");
var account_service_1 = require("@app/account/account.service");
var CmsEntryAbsSummaryComponent = /** @class */ (function () {
    function CmsEntryAbsSummaryComponent(route, router, accountService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.accountService = accountService;
        this.filters = {
            publicReport: null,
            memberReport: null
        };
        this.chartId = 0;
        this.pageFilters = {};
        this.budgetFieldsMarketing = [
            { name: 'Advertising', field: 'a_advertising' },
            { name: 'Publications', field: 'a_publications' },
            { name: 'Digital Media', field: 'a_elec_media' },
            { name: 'Direct Mail', field: 'a_direct_mail' }
        ];
        this.budgetFieldsOther = [
            { name: 'Travel', field: 'a_travel' },
            { name: 'Campus Guest Experiences', field: 'a_oncampus_ent' },
            { name: 'Student Wages', field: 'a_student_wages' },
            { name: 'Phone', field: 'a_phone' },
            { name: 'Postage', field: 'a_postage' },
            { name: 'Office Supplies', field: 'a_office_supplies' },
            { name: 'Outside Assistance', field: 'a_outside_assistance' },
            { name: 'Athletics Recruiting', field: 'a_athletics_recruiting' },
            { name: 'CRM and Admission Technology', field: 'a_crm' },
            { name: 'Professional Development', field: 'a_professional_development' },
            { name: 'Other', field: 'a_other_budget' }
        ];
        this.percentageFields2 = [
            { name: 'Travel', field: 'a_travel' },
            { name: 'Campus Guest Experiences', field: 'a_oncampus_ent' },
            { name: 'Phone', field: 'a_phone' },
            { name: 'Postage', field: 'a_postage' },
            { name: 'Office Supplies', field: 'a_office_supplies' },
            { name: 'Outside Assistance', field: 'a_outside_assistance' },
            { name: 'Athletics Recruiting', field: 'a_athletics_recruiting' },
            { name: 'CRM and Admission Technology', field: 'a_crm' },
            { name: 'Professional Development', field: 'a_professional_development' },
            { name: 'Other', field: 'a_other_budget' },
            { name: 'Non-Salary Total', field: 'a_nonsalary_subtotal' },
            { name: 'Professional Salaries', field: 'a_prof_salaries' },
            { name: 'Support Salaries', field: 'a_support_salaries' },
            { name: 'Student Wages', field: 'a_student_wages' },
            { name: 'Total Salary and Fringe', field: 'a_salary_subtotal' }
        ];
        this.fteAdministartorStaffFields = [
            { name: 'FTE Professional Staff', field: 'c_pro_staff' },
            { name: 'FTE Support Staff', field: 'c_support_staff' }
        ];
        this.route.queryParams.subscribe(function (params) {
            var schools;
            if (params.comparison_schools) {
                schools = params.comparison_schools;
                if (!lodash_1.isArray(schools)) {
                    schools = [schools];
                }
                schools = schools.map(function (id) { return +id; });
            }
            _this.pageFilters = {
                survey_id: params.survey_id ? +params.survey_id : undefined,
                org_id: params.org_id ? +params.org_id : undefined,
                comparison_schools: schools
            };
        });
    }
    Object.defineProperty(CmsEntryAbsSummaryComponent.prototype, "isEntryVerified", {
        get: function () {
            return this.cmsResult.isEntryVerified || this.sessionUser.isAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryAbsSummaryComponent.prototype, "isAdminBuild", {
        get: function () {
            return !!this.pageFilters.org_id;
        },
        enumerable: true,
        configurable: true
    });
    CmsEntryAbsSummaryComponent.prototype.setEnrollmentFields = function () {
        this.enrollmentFields = [
            { name: 'Total Undergrad Enrollment', field: 'b_total_enr', type: 'number' },
            { name: "Fall " + this.cmsResult.report.year + " FT New Students", field: 'b_ftns_fall', type: 'number' },
            { name: "Spring " + this.cmsResult.report.year + " FT New Students", field: 'b_ftns_spring', type: 'number' },
            { name: "Total " + this.cmsResult.report.year + " FT New Students", field: 'b_ftns_total', type: 'number' },
            { name: 'FTE Professional Staff', field: 'c_pro_staff', type: 'round' },
            { name: 'FTE Prof Staff &ndash; Direct Recruiting', field: 'c_pro_staff_direct_recruiting', type: 'round' },
            { name: 'FTE Support Staff', field: 'c_support_staff', type: 'round' },
            { name: 'FTE Total Admission Staff', field: 'total_admission_staff', type: 'round' },
            { name: 'Starting Counselor Salary', field: 'c_adm_cnslr_start_sal', type: 'currency' },
            { name: 'Ratio: New Students / Prof Staff FTE', field: 'ratio_new_students_per_staff', type: 'round' },
            { name: 'Ratio: New Students / Recruiting FTE', field: 'ratio_new_students_per_recruiting', type: 'round' },
            { name: 'Ratio: Total Budget $ / New Student', field: 'ratio_total_budget_per_new_student', type: 'currency' },
            { name: 'Professional Staff Overhead', field: 'professional_staff_overhead', type: 'round' },
            { name: 'Support Staff / Total Staff', field: 'ratio_support_staff_per_total_staff', type: 'percentage' },
            { name: 'New Transfers', field: 'new_transfers', type: 'number' },
            {
                name: "Transfer % of Total Fall " + this.cmsResult.report.year + " New Students",
                field: 'percent_new_student_transfers',
                type: 'percentage'
            },
            { name: '% Recruited Student Athletes', field: 'b_recruited_athletes', type: 'percentage_only' }
        ];
    };
    CmsEntryAbsSummaryComponent.prototype.setAdmissionFields = function () {
        this.admissionFields = [
            { name: this.cmsResult.report.year + " Grads Who Inquired", field: 'd_inquired', type: 'number' },
            { name: 'Inquiry to Application %', field: 'inquiry_to_app_percentage', type: 'percentage' },
            { name: this.cmsResult.report.year + " Grads Who Applied", field: 'd_applied', type: 'number' },
            { name: 'App to Completed App %', field: 'app_to_completed_percentage', type: 'percentage' },
            { name: this.cmsResult.report.year + " Grads Who Completed Apps", field: 'd_completed_app', type: 'number' },
            { name: 'Completed App to Admitted %', field: 'completed_app_to_admitted_percentage', type: 'percentage' },
            { name: this.cmsResult.report.year + " Grads Who Were Admitted", field: 'd_accepted', type: 'number' },
            { name: 'Admitted to Enrolled %', field: 'admits_to_enrolled_percentage', type: 'percentage' },
            { name: this.cmsResult.report.year + " Grads Who Enrolled", field: 'd_enrolled', type: 'number' }
        ];
    };
    CmsEntryAbsSummaryComponent.prototype.percentageChartData = function () {
        return {
            data: [
                +((this.cmsResult.nationalPercentages.a_prof_salaries || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_support_salaries || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_fringe_benefit || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_student_wages || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_advertising || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_publications || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_elec_media || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_direct_mail || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_travel || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_oncampus_ent || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_phone || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_postage || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_office_supplies || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalPercentages.a_other_budget || 0) * 100).toFixed(2)
            ],
            labels: [
                'Professional Salaries',
                'Support Salaries',
                'Fringe Benefit Allowance',
                'Student Wages',
                'Advertising',
                'Publications',
                'Digital Media',
                'Direct Mail',
                'Travel',
                'Campus Guest Experiences',
                'Phone',
                'Postage',
                'Office Supplies',
                'Other'
            ]
        };
    };
    CmsEntryAbsSummaryComponent.prototype.setAverageCostToRecruitChartData = function () {
        var _this = this;
        var data = [];
        var nationalData = [];
        var labels = [];
        this.cmsResult.costToRecruitData.forEach(function (item) {
            if (item.grouping != 'National') {
                data.push(+item.average.toFixed(2));
                labels.push(item.grouping);
                nationalData.push(+_this.cmsResult.costToRecruitData[0].average.toFixed(2));
            }
        });
        this.averageCostToRecruitChartData = { data: data, labels: labels, options: { label: 'Enrollment & Region Cost To Recruit' } };
        this.nationalAverageChartData = {
            data: nationalData,
            options: { label: 'National Average', type: 'line', fill: false }
        };
    };
    CmsEntryAbsSummaryComponent.prototype.admitsChartData = function () {
        return {
            data: [
                +((this.cmsResult.nationalAdmits.admits_to_enrolled_percentage || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalAdmits.completed_app_to_admitted_percentage || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalAdmits.app_to_completed_percentage || 0) * 100).toFixed(2),
                +((this.cmsResult.nationalAdmits.inquiry_to_app_percentage || 0) * 100).toFixed(2)
            ],
            labels: [
                'Admitted to Enrolled',
                'Completed App to Admitted',
                'Application to Completed App',
                'Inquired to Applied'
            ]
        };
    };
    CmsEntryAbsSummaryComponent.prototype.ngOnInit = function () {
        this.setEnrollmentFields();
        this.setAdmissionFields();
        this.pageFilters.survey_id = this.cmsResult.report.id;
    };
    CmsEntryAbsSummaryComponent.prototype.ngOnChanges = function (changes) {
        this.setFTEAdmissionStaffTotals();
        this.setAverageCostToRecruitChartData();
        this.chartId = +this.pageFilters.survey_id || 0;
    };
    CmsEntryAbsSummaryComponent.prototype.getMarketingSubtotal = function (type) {
        var _this = this;
        var total = 0;
        switch (type) {
            case 'org':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.orgAverages[field.field] || 0); });
                break;
            case 'watchlist':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.watchlistAverages[field.field] || 0); });
                break;
            case 'region':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.regionAverages[field.field] || 0); });
                break;
            case 'enrollment':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.enrollmentAverages[field.field] || 0); });
                break;
            case 'type':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.typeAverages[field.field] || 0); });
                break;
            case 'national':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.nationalAverages[field.field] || 0); });
                break;
        }
        return total;
    };
    CmsEntryAbsSummaryComponent.prototype.setFTEAdmissionStaffTotals = function () {
        var _this = this;
        var total = 0;
        this.fteAdministartorStaffFields.forEach(function (field) { return (total += _this.cmsResult.orgAverages[field.field] || 0); });
        this.cmsResult.orgAverages['total_admission_staff'] = total;
        total = 0;
        this.fteAdministartorStaffFields.forEach(function (field) { return (total += _this.cmsResult.watchlistAverages[field.field] || 0); });
        this.cmsResult.watchlistAverages['total_admission_staff'] = total;
        total = 0;
        this.fteAdministartorStaffFields.forEach(function (field) { return (total += _this.cmsResult.regionAverages[field.field] || 0); });
        this.cmsResult.regionAverages['total_admission_staff'] = total;
        total = 0;
        this.fteAdministartorStaffFields.forEach(function (field) { return (total += _this.cmsResult.enrollmentAverages[field.field] || 0); });
        this.cmsResult.enrollmentAverages['total_admission_staff'] = total;
        total = 0;
        this.fteAdministartorStaffFields.forEach(function (field) { return (total += _this.cmsResult.typeAverages[field.field] || 0); });
        this.cmsResult.typeAverages['total_admission_staff'] = total;
        total = 0;
        this.fteAdministartorStaffFields.forEach(function (field) { return (total += _this.cmsResult.nationalAverages[field.field] || 0); });
        this.cmsResult.nationalAverages['total_admission_staff'] = total;
    };
    CmsEntryAbsSummaryComponent.prototype.getMarketingPercentageSubtotal = function (type) {
        var _this = this;
        var total = 0;
        switch (type) {
            case 'org':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.orgPercentages[field.field] || 0); });
                break;
            case 'watchlist':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.watchlistPercentages[field.field] || 0); });
                break;
            case 'region':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.regionPercentages[field.field] || 0); });
                break;
            case 'enrollment':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.enrollmentPercentages[field.field] || 0); });
                break;
            case 'type':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.typePercentages[field.field] || 0); });
                break;
            case 'national':
                this.budgetFieldsMarketing.forEach(function (field) { return (total += _this.cmsResult.nationalPercentages[field.field] || 0); });
                break;
        }
        return total;
    };
    CmsEntryAbsSummaryComponent.prototype.updatePage = function () {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: this.pageFilters
        });
    };
    CmsEntryAbsSummaryComponent.prototype.onFilterUpdate = function (filters) {
        this.pageFilters = tslib_1.__assign({}, this.pageFilters, filters);
        this.updatePage();
    };
    CmsEntryAbsSummaryComponent.prototype.onReportUpdate = function (filters) {
        this.pageFilters = tslib_1.__assign({}, this.pageFilters, { survey_id: filters.reportId });
        this.updatePage();
    };
    CmsEntryAbsSummaryComponent.prototype.export = function () {
        if (this.isAdminBuild) {
            this.accountService.exportAbsCustomSummary(this.cmsResult.report.id, this.cmsResult.org.id, this.pageFilters.comparison_schools);
        }
        else {
            this.accountService.exportAbsSummary(this.cmsResult.report.id);
        }
    };
    return CmsEntryAbsSummaryComponent;
}());
exports.CmsEntryAbsSummaryComponent = CmsEntryAbsSummaryComponent;
