"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var account_1 = require("@app/account/account");
var abs_entry_service_1 = require("@app/cms/abs-submit/abs-entry.service");
var funnel_entry_service_1 = require("@app/funnel/funnel-entry.service");
var Type;
(function (Type) {
    Type["ABS"] = "abs";
    Type["Funnel"] = "funnel";
})(Type || (Type = {}));
var AdminReportActionsComponent = /** @class */ (function () {
    function AdminReportActionsComponent(absEntryService, funnelEntryService) {
        this.absEntryService = absEntryService;
        this.funnelEntryService = funnelEntryService;
        this.onSave = new core_1.EventEmitter();
        this.editor = null;
        this.config = {
            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
        };
        this.rejectReason = '';
        this.error = null;
        this.isSubmitting = false;
        this.savedVerification = false;
        this.savedReject = false;
    }
    AdminReportActionsComponent.prototype.verify = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = { id: this.entryId, status: 2 };
                        return [4 /*yield*/, this.save(data)];
                    case 1:
                        _a.sent();
                        this.savedVerification = true;
                        this.sendAcceptEmail();
                        this.sendVerifiedEmail();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminReportActionsComponent.prototype.reject = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isReasonEmpty, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isReasonEmpty = lodash_1.trim(this.stripTags(this.rejectReason));
                        if (!isReasonEmpty) {
                            this.error = { message: 'Please add a reason first.' };
                            return [2 /*return*/];
                        }
                        data = { id: this.entryId, reject_reason: this.rejectReason, status: 0 };
                        return [4 /*yield*/, this.save(data)];
                    case 1:
                        _a.sent();
                        this.savedReject = true;
                        this.sendRejectEmail();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminReportActionsComponent.prototype.save = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.user.isAdmin) {
                            return [2 /*return*/];
                        }
                        if (!this.entryId) {
                            throw new Error('Missing entryId');
                        }
                        if (!this.orgId) {
                            throw new Error('Missing orgId');
                        }
                        if (!this.reportId) {
                            throw new Error('Missing reportId');
                        }
                        data = tslib_1.__assign({ naccap_orgs_id: this.orgId, user_id: this.user.userId }, data);
                        this.appendReportId(data);
                        this.isSubmitting = true;
                        this.error = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!(this.type === Type.ABS)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.absEntryService.update(data)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        if (!(this.type === Type.Funnel)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.funnelEntryService.update(data)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5: throw new Error('Missing type');
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        this.error = { message: err_1.error.error.message };
                        this.isSubmitting = false;
                        throw err_1;
                    case 8:
                        this.isSubmitting = false;
                        this.onSave.emit(data.status);
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminReportActionsComponent.prototype.appendReportId = function (data) {
        switch (this.type) {
            case Type.ABS:
                data.naccap_abs_surveys_id = this.reportId;
                break;
            case Type.Funnel:
                data.naccap_app_reports_id = this.reportId;
                break;
        }
    };
    AdminReportActionsComponent.prototype.sendRejectEmail = function () {
        switch (this.type) {
            case Type.ABS:
                this.absEntryService.sendRejectedEmail(this.orgId, this.reportId, this.rejectReason);
                break;
            case Type.Funnel:
                this.funnelEntryService.sendRejectedEmail(this.orgId, this.reportId, this.rejectReason);
                break;
        }
    };
    AdminReportActionsComponent.prototype.sendAcceptEmail = function () {
        switch (this.type) {
            case Type.ABS:
                this.absEntryService.sendAcceptedEmail(this.orgId, this.reportId);
                break;
            case Type.Funnel:
                this.funnelEntryService.sendAcceptedEmail(this.orgId, this.reportId);
                break;
        }
    };
    AdminReportActionsComponent.prototype.sendVerifiedEmail = function () {
        switch (this.type) {
            case Type.ABS:
                this.absEntryService.sendVerifiedEmail(this.user.userId, this.reportId, this.orgId);
                break;
            case Type.Funnel:
                this.funnelEntryService.sendVerifiedEmail(this.user.userId, this.reportId, this.orgId);
                break;
        }
    };
    AdminReportActionsComponent.prototype.stripTags = function (str) {
        var html = str;
        var div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };
    return AdminReportActionsComponent;
}());
exports.AdminReportActionsComponent = AdminReportActionsComponent;
