"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var Account = /** @class */ (function () {
    function Account(json) {
        this.json = json;
        this.raw = json;
        this._emailConfirm = lodash_1.get(this.raw, 'email');
        this._passwordConfirm = lodash_1.get(this.raw, 'password');
    }
    Object.defineProperty(Account.prototype, "userId", {
        get: function () {
            return lodash_1.get(this.raw, 'id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "username", {
        get: function () {
            return lodash_1.get(this.raw, 'username');
        },
        set: function (username) {
            this.raw.username = username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "firstName", {
        get: function () {
            return lodash_1.get(this.raw, 'fname');
        },
        set: function (name) {
            this.raw.fname = name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "lastName", {
        get: function () {
            return lodash_1.get(this.raw, 'lname');
        },
        set: function (name) {
            this.raw.lname = name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "fullName", {
        get: function () {
            return lodash_1.get(this.raw, 'fullname');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "email", {
        get: function () {
            return lodash_1.get(this.raw, 'email');
        },
        set: function (email) {
            this.raw.email = email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "emailConfirm", {
        get: function () {
            return this._emailConfirm;
        },
        set: function (email) {
            this._emailConfirm = email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "emailsMatch", {
        get: function () {
            return this.email === this.emailConfirm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "password", {
        get: function () {
            return lodash_1.get(this.raw, 'password');
        },
        set: function (password) {
            this.raw.password = password;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "passwordConfirm", {
        get: function () {
            return this._passwordConfirm;
        },
        set: function (password) {
            this._passwordConfirm = password;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "passwordsMatch", {
        get: function () {
            return this.password === this.passwordConfirm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "address", {
        get: function () {
            return lodash_1.get(this.raw, 'address');
        },
        set: function (address) {
            this.raw.address = address;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "city", {
        get: function () {
            return lodash_1.get(this.raw, 'city');
        },
        set: function (city) {
            this.raw.city = city;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "country", {
        get: function () {
            return lodash_1.get(this.raw, 'country');
        },
        set: function (country) {
            this.raw.country = country;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "state", {
        get: function () {
            return lodash_1.get(this.raw, 'state');
        },
        set: function (state) {
            this.raw.state = state;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "zip", {
        get: function () {
            return lodash_1.get(this.raw, 'zip');
        },
        set: function (zip) {
            this.raw.zip = zip;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "phone", {
        get: function () {
            return lodash_1.get(this.raw, 'phone');
        },
        set: function (phone) {
            this.raw.phone = phone;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "isAdmin", {
        get: function () {
            return !!lodash_1.get(this.raw, 'isAdmin');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "isOrgSuperuser", {
        get: function () {
            return !!lodash_1.get(this.raw, 'isOrgSuperuser');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "isEditor", {
        get: function () {
            return !!lodash_1.get(this.raw, 'isEditor');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "orgId", {
        get: function () {
            return lodash_1.get(this.raw, 'naccap_orgs_id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Account.prototype, "org", {
        get: function () {
            return lodash_1.get(this.raw, 'org');
        },
        enumerable: true,
        configurable: true
    });
    return Account;
}());
exports.Account = Account;
