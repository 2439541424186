import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { get } from 'lodash';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { Account } from '@app/account/account';
import { AbsSubmitPartHelper } from './abs-submit-part-helper';

@Component({
	selector: 'rd-cms-entry-abs-submit-part-d',
	templateUrl: './cms-entry-abs-submit-part-d.component.html'
})
export class CmsEntryAbsSubmitPartDComponent extends AbsSubmitPartHelper implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	sessionUser: Account;

	isSavedFinal = false;
	isSavedTemporary = false;
	isSavedAndReturning = false;

	hasEmptiesBeenNotified = false;
	empties = { a: false, b: false, c: false, d: false };
	sectionCRequirementMet = false;

	ngOnInit() {
		this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;

		const numberValidators = [Validators.max(8000000), Validators.pattern(/^[0-9]*$/)];
		const numberSmallValidators = [Validators.max(32000), Validators.pattern(/^[0-9]*$/)];
		this.form = this.formBuilder.group(
			{
				d_inquired: [this.cmsResult.d_inquired, numberValidators],
				d_applied: [this.cmsResult.d_applied, numberSmallValidators],
				d_completed_app: [this.cmsResult.d_completed_app, numberSmallValidators],
				d_accepted: [this.cmsResult.d_accepted, numberSmallValidators],
				d_acceptance_rate: { value: '', disabled: true },
				d_enrolled: [this.cmsResult.d_enrolled, numberSmallValidators],
				reject_reason: ''
			},
			{ validator: baseValidator }
		);

		this.updateTotals('d_acceptance_rate', ['d_accepted', 'd_completed_app'], (totals: number) => {
			if (!this.form.controls.d_completed_app.value) {
				return 0;
			}
			const total = (+this.form.controls.d_accepted.value / +this.form.controls.d_completed_app.value) * 100;
			return total;
		});

		if (!this.hasWritePermission()) {
			this.form.disable();
		}

		this.listenForFormChanges();
	}

	updateTotals(updateField: string, fields: string[], tweakTotal: Function | null = null): void {
		const _updateTotals = () => {
			let total = 0;
			fields.forEach((field: string) => {
				total += parseInt(this.form.controls[field].value || 0);
			});
			if (tweakTotal) {
				total = tweakTotal(total);
			}
			this.form.controls[updateField].setValue(total);
		};
		fields.forEach((field: string) => {
			this.form.controls[field].valueChanges.subscribe(_updateTotals);
		});
		_updateTotals(); // trigger an update just in case legacy data wasn't saved properly
	}

	async saveFinal(): Promise<any> {
		// First check empties
		let hasEmpties = false;
		if (!this.hasEmptiesBeenNotified) {
			if (this.hasEmptiesInPartA()) {
				this.hasEmptiesBeenNotified = true;
				this.empties.a = true;
				hasEmpties = true;
			}
			if (this.hasEmptiesInPartB()) {
				this.hasEmptiesBeenNotified = true;
				this.empties.b = true;
				hasEmpties = true;
			}
			if (this.hasEmptiesInPartD()) {
				this.hasEmptiesBeenNotified = true;
				this.empties.d = true;
				hasEmpties = true;
			}
			const [hasEmptiesInPartC, metRequirement] = this.hasEmptiesInPartC();
			this.sectionCRequirementMet = metRequirement;
			if (hasEmptiesInPartC) {
				this.hasEmptiesBeenNotified = true;
				this.empties.c = true;
				hasEmpties = true;
			}
			if (!this.sectionCRequirementMet) {
				this.hasEmptiesBeenNotified = false;
				hasEmpties = true;
			}
		}
		if (hasEmpties) {
			const data = this.form.getRawValue();
			data.naccap_abs_surveys_id = this.cmsResult.report.id;
			data.naccap_orgs_id = this.orgId;
			data.user_id = this.sessionUser.userId;
			data.d_last_user = this.sessionUser.userId;
			data.d_last_update = new Date();
			const entryId = this.cmsResult.entryId;
			if (entryId) {
				data.id = entryId;
			}
			if (data.id) {
				await this.absEntryService.update(data);
			} else {
				const result = await this.absEntryService.create(data);
				this.form.patchValue({ id: result.insertId });
			}
			return;
		}
		const previousStatus = this.cmsResult.existingStatus;
		await this.save(1);
		this.isSavedFinal = true;
		// If a user from this org is saving the final data send emails
		if (this.sessionUser.orgId == this.cmsResult.orgId && previousStatus != this.cmsResult.existingStatus) {
			this.absEntryService.sendConfirmationEmail(this.sessionUser.userId, this.cmsResult.surveyId);
			this.absEntryService.sendAdminEmail(this.sessionUser.userId, this.cmsResult.surveyId);
		}
	}

	async saveTemporary(): Promise<any> {
		await this.save();
		this.isSavedTemporary = true;
	}

	async saveAndGoBack(): Promise<any> {
		await this.save();
		this.isSavedAndReturning = true;
		setTimeout(() => {
			const url = get(this.cmsResult.urls, 'abs-submit-part-c');
			this.router.navigate([url], { queryParamsHandling: 'merge' });
		}, 2000);
	}

	async save(status: number = 0, forceStatus: boolean = false): Promise<any> {
		if (this.form!.invalid) {
			this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
			return;
		}
		if (this.isSubmitting) {
			return;
		}

		const data = this.form.getRawValue();
		// NOTE: we set the status (which gets passed into this function as an argument)
		// if the user is not an admin
		// or if the user is an admin and the status is higher than the current status i.e. going from incomplete to complete, or complete to verified.
		if (forceStatus || !this.sessionUser.isAdmin || status > this.cmsResult.existingStatus) {
			data.status = status;
		}
		data.naccap_abs_surveys_id = this.cmsResult.report.id;
		data.naccap_orgs_id = this.orgId;
		data.user_id = this.sessionUser.userId;
		data.d_last_user = this.sessionUser.userId;
		data.d_last_update = new Date();
		const entryId = this.cmsResult.entryId;
		if (entryId) {
			data.id = entryId;
		}
		this.isSubmitting = true;
		this.error = {};
		try {
			if (data.id) {
				await this.absEntryService.update(data);
			} else {
				const result = await this.absEntryService.create(data);
				this.form.patchValue({ id: result.insertId });
			}
			this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
			this.isSaved = true;
			this.isSubmitting = false;
			this.onSaveSuccess();
		} catch (err) {
			this.error = err.error.error;
			this.isSubmitting = false;
			throw err;
		}
	}

	// Event emitted from admin actions
	onAdminReportActions(status: number) {}

	hasEmptiesInPartA(): boolean {
		const a_fields = [
			'a_prof_salaries',
			'a_support_salaries',
			'a_fringe_benefit',
			'a_student_wages',
			'a_salary_subtotal',
			'a_advertising',
			'a_publications',
			'a_elec_media',
			'a_direct_mail',
			'a_travel',
			'a_oncampus_ent',
			'a_phone',
			'a_postage',
			'a_outside_assistance',
			'a_athletics_recruiting',
			'a_crm',
			'a_professional_development',
			'a_office_supplies',
			'a_nonsalary_subtotal',
			'a_grand_total'
		];
		if (this.cmsResult.a_other_budget) {
			a_fields.push('a_other_breakdown');
		}
		const [empty] = this.hasEmptyFieldsInReport(a_fields);
		return empty;
	}

	hasEmptiesInPartB(): boolean {
		const b_fields = ['b_total_enr', 'b_ftns_fall', 'b_ftns_spring', 'b_ftns_total'];
		const [empty] = this.hasEmptyFieldsInReport(b_fields);
		return empty;
	}

	hasEmptiesInPartC(): [boolean, boolean] {
		const c_fields = [
			'c_pro_staff',
			'c_pro_staff_avg_sal',
			'c_pro_staff_direct_recruiting',
			'c_support_staff',
			'c_support_staff_avg_sal',
			'c_stud_labor',
			'c_adm_cnslr_start_sal',
			'c_asst_dir_sal',
			'c_asst_dir_yrs_adm',
			'c_asst_dir_yrs_pos',
			'c_assoc_dir_sal',
			'c_assoc_dir_yrs_adm',
			'c_assoc_dir_yrs_pos',
			'c_dir_adm_sal',
			'c_dir_adm_yrs_adm',
			'c_dir_adm_yrs_pos',
			'c_vpdean_title',
			'c_vpdean_sal',
			'c_vpdean_yrs_adm',
			'c_vpdean_yrs_pos',
			'c_min_edu_adm_cnslr',
			'c_min_edu_asst_dir',
			'c_min_edu_dir',
			'c_min_edu_vpdean'
		];
		if (this.cmsResult.c_sr_cnslr_pos) {
			c_fields.push('c_sr_cnslr_sal');
		}
		if (this.cmsResult.c_car_other_staff) {
			c_fields.push('c_car_others');
		}
		const [hasEmpties, numEmpties] = this.hasEmptyFieldsInReport(c_fields);
		return [hasEmpties, numEmpties / c_fields.length < 0.5];
	}

	hasEmptiesInPartD(): boolean {
		let numEmpty = 0;
		const d_fields = ['d_inquired', 'd_applied', 'd_completed_app', 'd_accepted', 'd_acceptance_rate', 'd_enrolled'];
		d_fields.forEach(field => {
			const value = this.form.controls[field].value;
			if (!value || value == 0) {
				numEmpty++;
			}
		});
		return numEmpty > 0;
	}

	hasEmptyFieldsInReport(fields: string[]): [boolean, number] {
		let numEmpty = 0;
		fields.forEach(field => {
			const value = this.cmsResult.reportEntry[field];
			if (!value || value == 0) {
				numEmpty++;
			}
		});
		return [numEmpty > 0, numEmpty];
	}
}

function baseValidator(formGroup: FormGroup): ValidationErrors | null {
	const errors: any = {};
	let hasErrors = false;

	if (+formGroup.controls.d_inquired.value - +formGroup.controls.d_applied.value < 0) {
		errors.moreApplicantsThanInquiries = true;
		hasErrors = true;
	}

	if (+formGroup.controls.d_applied.value - +formGroup.controls.d_completed_app.value < 0) {
		errors.moreCompletedAppsThanApplications = true;
		hasErrors = true;
	}

	if (+formGroup.controls.d_completed_app.value - +formGroup.controls.d_accepted.value < 0) {
		errors.moreAcceptedThanCompleted = true;
		hasErrors = true;
	}

	if (+formGroup.controls.d_accepted.value - +formGroup.controls.d_enrolled.value < 0) {
		errors.moreEnrolledThanAccepted = true;
		hasErrors = true;
	}

	return hasErrors ? errors : null;
}
