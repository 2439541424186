"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_abs_base_1 = require("./cms-entry-result-abs-base");
var CmsEntryResultAbsSubmit = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultAbsSubmit, _super);
    function CmsEntryResultAbsSubmit() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CmsEntryResultAbsSubmit.prototype.getLabel = function (field) {
        return lodash_1.get(this.getField(field), 'label.value', '').replace('[[year]]', this.year);
    };
    CmsEntryResultAbsSubmit.prototype.getHelpText = function (field) {
        return lodash_1.get(this.getField(field), 'help.value', '').replace('[[year]]', this.year);
    };
    CmsEntryResultAbsSubmit.prototype.getField = function (field) {
        return lodash_1.find(lodash_1.get(this.entry, 'entries.field'), function (cms_field) { return lodash_1.get(cms_field, 'key.value') === field; });
    };
    CmsEntryResultAbsSubmit.prototype.getDegreeLabel = function (field) {
        return lodash_1.get(this.reportEntry, "degrees." + field);
    };
    CmsEntryResultAbsSubmit.prototype.getPreviousDegreeLabel = function (field) {
        return lodash_1.get(this.previousReportEntry, "degrees." + field);
    };
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "surveyId", {
        get: function () {
            return lodash_1.get(this.report, 'id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "instructions", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.instructions[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "introduction", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.introduction[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "staff1", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.staff[0].field_1.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "staff2", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.staff[0].field_2.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "salary", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.salary[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "car", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.car[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "qualifications", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.qualifications[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "inquiries", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.inquiries[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "applications", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.applications[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "completedApplications", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.completed_applications[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "admitted", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.admitted[0].value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "enrolled", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.enrolled[0].value');
        },
        enumerable: true,
        configurable: true
    });
    CmsEntryResultAbsSubmit.prototype.isUserReportValidated = function () {
        return lodash_1.get(this.sessionUserReportEntry, 'status') == 2;
    };
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "isOpen", {
        get: function () {
            return lodash_1.get(this.report, 'isOpen');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "year", {
        get: function () {
            return lodash_1.get(this.report, 'year');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "existingStatus", {
        // Entry data
        get: function () {
            return lodash_1.get(this.reportEntry, 'status', 0);
        },
        set: function (status) {
            lodash_1.set(this.reportEntry, 'status', status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "entryId", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'id', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "orgId", {
        get: function () {
            return lodash_1.get(this.org, 'id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "naccap_abs_surveys_id", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'naccap_abs_surveys_id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "naccap_orgs_id", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'naccap_orgs_id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "user_id", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'user_id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_prof_salaries", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_prof_salaries');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_support_salaries", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_support_salaries');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_fringe_benefit", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_fringe_benefit');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_student_wages", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_student_wages');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_salary_subtotal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_salary_subtotal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_advertising", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_advertising');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_publications", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_publications');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_elec_media", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_elec_media');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_direct_mail", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_direct_mail');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_travel", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_travel');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_oncampus_ent", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_oncampus_ent');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_phone", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_phone');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_postage", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_postage');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_ph_post_note", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_ph_post_note');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_outside_assistance", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_outside_assistance');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_athletics_recruiting", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_athletics_recruiting');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_crm", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_crm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_professional_development", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_professional_development');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_office_supplies", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_office_supplies');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_other_budget", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_other_budget');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_other_breakdown", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_other_breakdown');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_nonsalary_subtotal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_nonsalary_subtotal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_grand_total", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_grand_total');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_comments", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_last_user", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "a_last_update", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'a_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_total_enr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_total_enr');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_ftns_fall", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_ftns_fall');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_ftns_spring", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_ftns_spring');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_ftns_total", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_ftns_total');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_males", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_males');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_females", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_females');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_mf_ratio", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_mf_ratio');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_recruited_athletes", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_recruited_athletes');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_comments", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_last_user", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "b_last_update", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'b_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_pro_staff", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_pro_staff');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_pro_staff_avg_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_pro_staff_avg_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_pro_staff_direct_recruiting", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_pro_staff_direct_recruiting');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_support_staff", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_support_staff');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_support_staff_avg_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_support_staff_avg_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_stud_labor", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_stud_labor');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_adm_cnslr_start_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_adm_cnslr_start_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_sr_cnslr_pos", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_sr_cnslr_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_sr_cnslr_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_sr_cnslr_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_asst_dir_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_asst_dir_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_asst_dir_yrs_adm", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_asst_dir_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_asst_dir_yrs_pos", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_asst_dir_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_assoc_dir_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_assoc_dir_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_assoc_dir_yrs_adm", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_assoc_dir_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_assoc_dir_yrs_pos", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_assoc_dir_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_dir_adm_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_dir_adm_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_dir_adm_yrs_adm", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_dir_adm_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_dir_adm_yrs_pos", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_dir_adm_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_vpdean_title", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_vpdean_title');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_vpdean_sal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_vpdean_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_vpdean_yrs_adm", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_vpdean_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_vpdean_yrs_pos", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_vpdean_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_car_adm_cnslr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_car_adm_cnslr');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_car_other_staff", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_car_other_staff');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_car_others", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_car_others');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_min_edu_adm_cnslr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_min_edu_adm_cnslr');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_min_edu_asst_dir", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_min_edu_asst_dir');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_min_edu_dir", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_min_edu_dir');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_min_edu_vpdean", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_min_edu_vpdean');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_comments", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_last_user", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "c_last_update", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'c_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_inquired", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_inquired');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_applied", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_applied');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_completed_app", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_completed_app');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_accepted", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_accepted');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_enrolled", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_enrolled');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_comments", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_last_user", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "d_last_update", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'd_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "status", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'status');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_prof_salaries", {
        // previous year entries
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_prof_salaries');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_support_salaries", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_support_salaries');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_fringe_benefit", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_fringe_benefit');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_student_wages", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_student_wages');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_salary_subtotal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_salary_subtotal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_advertising", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_advertising');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_publications", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_publications');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_elec_media", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_elec_media');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_direct_mail", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_direct_mail');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_travel", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_travel');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_oncampus_ent", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_oncampus_ent');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_phone", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_phone');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_postage", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_postage');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_ph_post_note", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_ph_post_note');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_outside_assistance", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_outside_assistance');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_athletics_recruiting", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_athletics_recruiting');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_crm", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_crm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_professional_development", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_professional_development');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_office_supplies", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_office_supplies');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_other_budget", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_other_budget');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_other_breakdown", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_other_breakdown');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_nonsalary_subtotal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_nonsalary_subtotal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_grand_total", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_grand_total');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_comments", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_last_user", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_a_last_update", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'a_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_total_enr", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_total_enr');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_ftns_fall", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_ftns_fall');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_ftns_spring", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_ftns_spring');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_ftns_total", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_ftns_total');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_males", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_males');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_females", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_females');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_mf_ratio", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_mf_ratio');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_comments", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_last_user", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_b_last_update", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'b_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_pro_staff", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_pro_staff');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_pro_staff_avg_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_pro_staff_avg_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_pro_staff_direct_recruiting", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_pro_staff_direct_recruiting');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_support_staff", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_support_staff');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_support_staff_avg_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_support_staff_avg_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_stud_labor", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_stud_labor');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_adm_cnslr_start_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_adm_cnslr_start_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_sr_cnslr_pos", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_sr_cnslr_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_sr_cnslr_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_sr_cnslr_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_asst_dir_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_asst_dir_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_asst_dir_yrs_adm", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_asst_dir_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_asst_dir_yrs_pos", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_asst_dir_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_assoc_dir_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_assoc_dir_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_assoc_dir_yrs_adm", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_assoc_dir_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_assoc_dir_yrs_pos", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_assoc_dir_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_dir_adm_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_dir_adm_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_dir_adm_yrs_adm", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_dir_adm_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_dir_adm_yrs_pos", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_dir_adm_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_vpdean_title", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_vpdean_title');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_vpdean_sal", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_vpdean_sal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_vpdean_yrs_adm", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_vpdean_yrs_adm');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_vpdean_yrs_pos", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_vpdean_yrs_pos');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_car_adm_cnslr", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_car_adm_cnslr');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_car_other_staff", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_car_other_staff');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_car_others", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_car_others');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_min_edu_adm_cnslr", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_min_edu_adm_cnslr');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_min_edu_asst_dir", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_min_edu_asst_dir');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_min_edu_dir", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_min_edu_dir');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_min_edu_vpdean", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_min_edu_vpdean');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_comments", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_last_user", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_c_last_update", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'c_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_inquired", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_inquired');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_applied", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_applied');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_completed_app", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_completed_app');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_accepted", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_accepted');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_enrolled", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_enrolled');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_comments", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_comments');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_last_user", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_last_user');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_d_last_update", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'd_last_update');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "previous_status", {
        get: function () {
            return lodash_1.get(this.previousReportEntry, 'status');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultAbsSubmit.prototype, "onHoldMessages", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'on_hold_messages', []);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSubmit.prototype, "org", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSubmit.prototype, "report", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSubmit.prototype, "reportEntry", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSubmit.prototype, "reportEntryUser", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSubmit.prototype, "sessionUserReportEntry", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSubmit.prototype, "previousReportEntry", void 0);
    CmsEntryResultAbsSubmit = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_abs_base_1.CmsEntryResultAbsBase)
    ], CmsEntryResultAbsSubmit);
    return CmsEntryResultAbsSubmit;
}(cms_entry_result_abs_base_1.CmsEntryResultAbsBase));
exports.CmsEntryResultAbsSubmit = CmsEntryResultAbsSubmit;
