"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./abs-submit-save-messages.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../core/alert/alert.component.ngfactory");
var i3 = require("../../../core/alert/alert.component");
var i4 = require("@angular/router");
var i5 = require("@angular/common");
var i6 = require("./abs-submit-save-messages.component");
var styles_AbsSubmitSaveMessagesComponent = [i0.styles];
var RenderType_AbsSubmitSaveMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AbsSubmitSaveMessagesComponent, data: {} });
exports.RenderType_AbsSubmitSaveMessagesComponent = RenderType_AbsSubmitSaveMessagesComponent;
function View_AbsSubmitSaveMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "rd-alert", [["type", "warning"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Data Has Been Updated"])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please be sure to return before the submission window closes to submit your final data."]))], function (_ck, _v) { var currVal_0 = "warning"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AbsSubmitSaveMessagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "rd-alert", [["type", "success"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Final Data Has Been Submitted"])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are no longer able to update your data for this report. You will be notified via e-mail when your submission has been validated and you are granted access to view data for this report."]))], function (_ck, _v) { var currVal_0 = "success"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AbsSubmitSaveMessagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "rd-alert", [["type", "success"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Data Has Been Validated"])), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Other verified data is available for viewing. "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["View ABS Data"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "success"; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.url; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); }); }
function View_AbsSubmitSaveMessagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AbsSubmitSaveMessagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AbsSubmitSaveMessagesComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AbsSubmitSaveMessagesComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.show && (_co.status == 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.show && (_co.status == 1)); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.show && (_co.status == 2)); _ck(_v, 5, 0, currVal_2); }, null); }
exports.View_AbsSubmitSaveMessagesComponent_0 = View_AbsSubmitSaveMessagesComponent_0;
function View_AbsSubmitSaveMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rd-abs-submit-save-messages", [], null, null, null, View_AbsSubmitSaveMessagesComponent_0, RenderType_AbsSubmitSaveMessagesComponent)), i1.ɵdid(1, 114688, null, 0, i6.AbsSubmitSaveMessagesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AbsSubmitSaveMessagesComponent_Host_0 = View_AbsSubmitSaveMessagesComponent_Host_0;
var AbsSubmitSaveMessagesComponentNgFactory = i1.ɵccf("rd-abs-submit-save-messages", i6.AbsSubmitSaveMessagesComponent, View_AbsSubmitSaveMessagesComponent_Host_0, { show: "show", status: "status", url: "url" }, {}, []);
exports.AbsSubmitSaveMessagesComponentNgFactory = AbsSubmitSaveMessagesComponentNgFactory;
