"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cms_entry_result_1 = require("@app/cms/results/cms-entry-result");
var account_1 = require("@app/account/account");
var CmsEntryLoginComponent = /** @class */ (function () {
    function CmsEntryLoginComponent() {
    }
    return CmsEntryLoginComponent;
}());
exports.CmsEntryLoginComponent = CmsEntryLoginComponent;
