"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResult404 = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResult404, _super);
    function CmsEntryResult404() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CmsEntryResult404.prototype, "type", {
        get: function () {
            return '404';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResult404.prototype, "title", {
        get: function () {
            return 'Page Not Found';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResult404.prototype, "headerImage", {
        get: function () {
            // TODO: return an image for 404's
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return CmsEntryResult404;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResult404 = CmsEntryResult404;
