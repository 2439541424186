"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var account_1 = require("@app/account/account");
var abs_submit_part_helper_1 = require("./abs-submit-part-helper");
var CmsEntryAbsSubmitPartCComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryAbsSubmitPartCComponent, _super);
    function CmsEntryAbsSubmitPartCComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CmsEntryAbsSubmitPartCComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;
        var numberValidators = [forms_1.Validators.max(8000000), forms_1.Validators.maxLength(5), forms_1.Validators.pattern(/^[0-9]*$/)];
        var decimalValidators = [
            forms_1.Validators.max(99.99),
            forms_1.Validators.maxLength(5),
            forms_1.Validators.pattern(/^\d+(?:\.\d{1,2})?$/)
        ];
        this.form = this.formBuilder.group({
            c_pro_staff: [this.cmsResult.c_pro_staff, decimalValidators],
            c_pro_staff_avg_sal: { value: this.cmsResult.c_pro_staff_avg_sal, disabled: true },
            c_pro_staff_direct_recruiting: [this.cmsResult.c_pro_staff_direct_recruiting, decimalValidators],
            c_support_staff: [this.cmsResult.c_support_staff, decimalValidators],
            c_support_staff_avg_sal: { value: this.cmsResult.c_support_staff_avg_sal, disabled: true },
            c_stud_labor: [this.cmsResult.c_stud_labor, numberValidators],
            c_stud_labor_avg_wage: { value: '', disabled: true },
            c_adm_cnslr_start_sal: [this.cmsResult.c_adm_cnslr_start_sal, numberValidators],
            c_sr_cnslr_pos: [this.cmsResult.c_sr_cnslr_pos, numberValidators],
            c_sr_cnslr_sal: [this.cmsResult.c_sr_cnslr_sal, numberValidators],
            c_asst_dir_sal: [this.cmsResult.c_asst_dir_sal, numberValidators],
            c_asst_dir_yrs_adm: [this.cmsResult.c_asst_dir_yrs_adm, numberValidators],
            c_asst_dir_yrs_pos: [this.cmsResult.c_asst_dir_yrs_pos, numberValidators],
            c_assoc_dir_sal: [this.cmsResult.c_assoc_dir_sal, numberValidators],
            c_assoc_dir_yrs_adm: [this.cmsResult.c_assoc_dir_yrs_adm, numberValidators],
            c_assoc_dir_yrs_pos: [this.cmsResult.c_assoc_dir_yrs_pos, numberValidators],
            c_dir_adm_sal: [this.cmsResult.c_dir_adm_sal, numberValidators],
            c_dir_adm_yrs_adm: [this.cmsResult.c_dir_adm_yrs_adm, numberValidators],
            c_dir_adm_yrs_pos: [this.cmsResult.c_dir_adm_yrs_pos, numberValidators],
            c_vpdean_title: [this.cmsResult.c_vpdean_title],
            c_vpdean_sal: [this.cmsResult.c_vpdean_sal, numberValidators],
            c_vpdean_yrs_adm: [this.cmsResult.c_vpdean_yrs_adm, numberValidators],
            c_vpdean_yrs_pos: [this.cmsResult.c_vpdean_yrs_pos, numberValidators],
            c_car_adm_cnslr: [this.cmsResult.c_car_adm_cnslr, numberValidators],
            c_car_others: [this.cmsResult.c_car_others],
            c_car_other_staff: [this.cmsResult.c_car_other_staff, numberValidators],
            c_min_edu_adm_cnslr: [this.cmsResult.c_min_edu_adm_cnslr],
            c_min_edu_asst_dir: [this.cmsResult.c_min_edu_asst_dir],
            c_min_edu_dir: [this.cmsResult.c_min_edu_dir],
            c_min_edu_vpdean: [this.cmsResult.c_min_edu_vpdean]
        });
        if (!this.hasWritePermission()) {
            this.form.disable();
        }
        this.updateTotals('c_pro_staff_avg_sal', ['c_pro_staff'], function (totals) {
            if (!_this.cmsResult.a_prof_salaries) {
                return 0;
            }
            return Math.round(+_this.cmsResult.a_prof_salaries / parseFloat(_this.form.controls.c_pro_staff.value));
        });
        this.updateTotals('c_support_staff_avg_sal', ['c_support_staff'], function (totals) {
            if (!_this.cmsResult.a_support_salaries) {
                return 0;
            }
            totals = Math.round(+_this.cmsResult.a_support_salaries / parseFloat(_this.form.controls.c_support_staff.value));
            return isNaN(totals) ? 0 : totals;
        });
        this.updateTotals('c_stud_labor_avg_wage', ['c_stud_labor'], function (totals) {
            if (!_this.cmsResult.a_student_wages) {
                return 0;
            }
            totals = +_this.cmsResult.a_student_wages / (30 * parseFloat(_this.form.controls.c_stud_labor.value));
            return isNaN(totals) ? 0 : totals;
        });
        this.listenForFormChanges();
    };
    CmsEntryAbsSubmitPartCComponent.prototype.updateTotals = function (updateField, fields, tweakTotal) {
        var _this = this;
        if (tweakTotal === void 0) { tweakTotal = null; }
        var _updateTotals = function () {
            var total = 0;
            fields.forEach(function (field) {
                total += parseInt(_this.form.controls[field].value || 0);
            });
            if (tweakTotal) {
                total = tweakTotal(total);
            }
            _this.form.controls[updateField].setValue(total);
        };
        fields.forEach(function (field) {
            _this.form.controls[field].valueChanges.subscribe(_updateTotals);
        });
        _updateTotals(); // trigger an update just in case legacy data wasn't saved properly
    };
    CmsEntryAbsSubmitPartCComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, entryId, result, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.form.invalid) {
                            this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
                            return [2 /*return*/];
                        }
                        if (this.isSubmitting) {
                            return [2 /*return*/];
                        }
                        data = this.form.getRawValue();
                        if (!this.sessionUser.isAdmin) {
                            data.status = 0; // status should always be 0 unless an admin is saving, in which case we keep the status unchanged
                        }
                        data.naccap_abs_surveys_id = this.cmsResult.report.id;
                        data.naccap_orgs_id = this.orgId;
                        data.user_id = this.sessionUser.userId;
                        data.c_last_user = this.sessionUser.userId;
                        data.c_last_update = new Date();
                        entryId = this.cmsResult.entryId;
                        if (entryId) {
                            data.id = entryId;
                        }
                        this.isSubmitting = true;
                        this.error = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!data.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.absEntryService.update(data)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.absEntryService.create(data)];
                    case 4:
                        result = _a.sent();
                        this.form.patchValue({ id: result.insertId });
                        _a.label = 5;
                    case 5:
                        this.isSaved = true;
                        this.onSaveSuccess();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        this.error = err_1.error.error;
                        return [3 /*break*/, 7];
                    case 7:
                        this.isSubmitting = false;
                        this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
                        setTimeout(function () {
                            var url = lodash_1.get(_this.cmsResult.urls, 'abs-submit-part-d');
                            _this.router.navigate([url], { queryParamsHandling: 'merge' });
                        }, 2000);
                        return [2 /*return*/];
                }
            });
        });
    };
    CmsEntryAbsSubmitPartCComponent.prototype.hasWritePermission = function () {
        return (this.sessionUser.isAdmin ||
            (this.cmsResult.existingStatus === 0 &&
                this.sessionUser.isOrgSuperuser &&
                this.sessionUser.orgId === this.getOrgId()));
    };
    CmsEntryAbsSubmitPartCComponent.prototype.hasReadPermission = function () {
        return this.cmsResult.isVerifiedToViewPartC(this.sessionUser, this.getOrgId());
    };
    return CmsEntryAbsSubmitPartCComponent;
}(abs_submit_part_helper_1.AbsSubmitPartHelper));
exports.CmsEntryAbsSubmitPartCComponent = CmsEntryAbsSubmitPartCComponent;
