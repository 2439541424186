"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./cms-entry-404.component");
var styles_CmsEntry404Component = [];
var RenderType_CmsEntry404Component = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsEntry404Component, data: {} });
exports.RenderType_CmsEntry404Component = RenderType_CmsEntry404Component;
function View_CmsEntry404Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The page you requested could not be found."]))], null, null); }
exports.View_CmsEntry404Component_0 = View_CmsEntry404Component_0;
function View_CmsEntry404Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rd-cms-entry-404", [], null, null, null, View_CmsEntry404Component_0, RenderType_CmsEntry404Component)), i0.ɵdid(1, 49152, null, 0, i1.CmsEntry404Component, [], null, null)], null, null); }
exports.View_CmsEntry404Component_Host_0 = View_CmsEntry404Component_Host_0;
var CmsEntry404ComponentNgFactory = i0.ɵccf("rd-cms-entry-404", i1.CmsEntry404Component, View_CmsEntry404Component_Host_0, { cmsResult: "cmsResult", sessionUser: "sessionUser" }, {}, []);
exports.CmsEntry404ComponentNgFactory = CmsEntry404ComponentNgFactory;
