"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultAbsSummary = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultAbsSummary, _super);
    function CmsEntryResultAbsSummary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.watchlist = {};
        _this.watchlistAverages = {};
        _this.watchlistPercentages = {};
        _this.watchlistAdmits = {};
        return _this;
    }
    Object.defineProperty(CmsEntryResultAbsSummary.prototype, "isEntryVerified", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'status') == 2;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "report", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "reportEntry", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "org", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "completedAbsReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "watchlist", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "orgAverages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "watchlistAverages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "regionAverages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "enrollmentAverages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "typeAverages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "nationalAverages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "orgPercentages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "watchlistPercentages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "regionPercentages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "enrollmentPercentages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "typePercentages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "nationalPercentages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "orgAdmits", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "watchlistAdmits", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "regionAdmits", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "enrollmentAdmits", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "typeAdmits", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "nationalAdmits", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsSummary.prototype, "costToRecruitData", void 0);
    CmsEntryResultAbsSummary = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultAbsSummary);
    return CmsEntryResultAbsSummary;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultAbsSummary = CmsEntryResultAbsSummary;
