"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var cms_entry_result_funnel_report_1 = require("@app/cms/results/cms-entry-result-funnel-report");
var funnel_entry_service_1 = require("@app/funnel/funnel-entry.service");
var account_1 = require("@app/account/account");
var CmsEntryFunnelReportComponent = /** @class */ (function () {
    function CmsEntryFunnelReportComponent(formBuilder, funnelEntryService, route) {
        this.formBuilder = formBuilder;
        this.funnelEntryService = funnelEntryService;
        this.route = route;
        this.hasIncompleteData = false;
        this.isSubmitting = false;
        this.isSaved = false;
        this.isSpecificReportLookup = false;
        this.updateGoal = false;
        this.updateOriginalGoal = false;
        this.useOriginalGoalFeature = true; // TODO
        this.editor = null;
        this.config = {
            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
        };
    }
    CmsEntryFunnelReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        var finalFormValidators = this.cmsResult.report.isFinalReport ? [forms_1.Validators.required] : [];
        this.useOriginalGoalFeature = this.cmsResult.report.reportYear >= 2021;
        this.form = this.formBuilder.group({
            id: this.cmsResult.entryId,
            fr_app_curr: [this.cmsResult.fr_app_curr, forms_1.Validators.required],
            fr_app_prev: [this.cmsResult.fr_app_prev, forms_1.Validators.required],
            fr_adm_curr: [this.cmsResult.fr_adm_curr, forms_1.Validators.required],
            fr_adm_prev: [this.cmsResult.fr_adm_prev, forms_1.Validators.required],
            fr_dep_curr: [this.cmsResult.fr_dep_curr, forms_1.Validators.required],
            fr_dep_prev: [this.cmsResult.fr_dep_prev, forms_1.Validators.required],
            fr_dep_canceled_curr: [this.cmsResult.fr_dep_canceled_curr],
            fr_dep_canceled_prev: [this.cmsResult.fr_dep_canceled_prev],
            tr_app_curr: [this.cmsResult.tr_app_curr, forms_1.Validators.required],
            tr_app_prev: [this.cmsResult.tr_app_prev, forms_1.Validators.required],
            tr_adm_curr: [this.cmsResult.tr_adm_curr, forms_1.Validators.required],
            tr_adm_prev: [this.cmsResult.tr_adm_prev, forms_1.Validators.required],
            tr_dep_curr: [this.cmsResult.tr_dep_curr, forms_1.Validators.required],
            tr_dep_prev: [this.cmsResult.tr_dep_prev, forms_1.Validators.required],
            tr_dep_canceled_curr: [this.cmsResult.tr_dep_canceled_curr],
            tr_dep_canceled_prev: [this.cmsResult.tr_dep_canceled_prev],
            tns_app_curr: { value: this.cmsResult.tns_app_curr, disabled: true },
            tns_app_prev: { value: this.cmsResult.tns_app_prev, disabled: true },
            tns_adm_curr: { value: this.cmsResult.tns_adm_curr, disabled: true },
            tns_adm_prev: { value: this.cmsResult.tns_adm_prev, disabled: true },
            tns_dep_curr: { value: this.cmsResult.tns_dep_curr, disabled: true },
            tns_dep_prev: { value: this.cmsResult.tns_dep_prev, disabled: true },
            tns_dep_canceled_curr: { value: this.cmsResult.tns_dep_canceled_curr, disabled: true },
            tns_dep_canceled_prev: { value: this.cmsResult.tns_dep_canceled_prev, disabled: true },
            incomplete_explanation: this.cmsResult.incomplete_explanation,
            curr_goal: this.cmsResult.curr_goal,
            new_goal: '',
            original_goal: lodash_1.get(this.cmsResult.original_goal, 'goal'),
            modified_goal: lodash_1.get(this.cmsResult.original_goal, 'modified.goal'),
            prev_actual: this.cmsResult.prev_actual,
            fr_enr_curr: [this.cmsResult.fr_enr_curr, finalFormValidators],
            fr_enr_prev: [this.cmsResult.fr_enr_prev, finalFormValidators],
            tr_enr_curr: [this.cmsResult.tr_enr_curr, finalFormValidators],
            tr_enr_prev: [this.cmsResult.tr_enr_prev, finalFormValidators],
            tns_enr_curr: { value: this.cmsResult.tns_enr_curr, disabled: true },
            tns_enr_prev: { value: this.cmsResult.tns_enr_prev, disabled: true },
            reject_reason: '',
            original_goal_edit_enabled: false,
            original_goal_edit: ''
        }, { validator: formBaseValidator });
        this.hasIncompleteData =
            this.form.controls.incomplete_explanation.value && this.form.controls.incomplete_explanation.value.length;
        var totals = [
            'app_curr',
            'app_prev',
            'adm_curr',
            'adm_prev',
            'dep_curr',
            'dep_prev',
            'dep_canceled_curr',
            'dep_canceled_prev',
            'enr_curr',
            'enr_prev'
        ];
        totals.forEach(function (field) {
            var _updateTotals = function () {
                _this.form.controls['tns_' + field].setValue(+_this.form.controls['fr_' + field].value + +_this.form.controls['tr_' + field].value);
            };
            _this.form.controls['fr_' + field].valueChanges.subscribe(_updateTotals);
            _this.form.controls['tr_' + field].valueChanges.subscribe(_updateTotals);
        });
        if (!this.canSubmit()) {
            this.form.disable();
        }
        this.isSpecificReportLookup = this.route.snapshot.queryParams.id;
    };
    CmsEntryFunnelReportComponent.prototype.canSubmit = function () {
        return ((this.hasWritePermission() && this.cmsResult.isOpen && this.cmsResult.existingStatus === 0) ||
            this.sessionUser.isAdmin === true);
    };
    CmsEntryFunnelReportComponent.prototype.hasWritePermission = function () {
        return (this.sessionUser.isAdmin || (this.cmsResult.existingStatus === 0 && this.sessionUser.orgId === this.getOrgId()));
    };
    CmsEntryFunnelReportComponent.prototype.hasReadPermission = function () {
        return (this.sessionUser.isAdmin || this.sessionUser.orgId === this.getOrgId() || this.cmsResult.isUserReportValidated());
    };
    CmsEntryFunnelReportComponent.prototype.getOrgId = function () {
        return this.cmsResult.org.id;
    };
    CmsEntryFunnelReportComponent.prototype.updateGoalState = function () {
        this.updateGoal = true;
        this.form.patchValue({ new_goal: this.form.value.modified_goal || this.form.value.original_goal });
    };
    CmsEntryFunnelReportComponent.prototype.updateOriginalGoalState = function () {
        this.updateOriginalGoal = true;
        this.form.patchValue({
            original_goal_edit: this.form.value.original_goal,
            original_goal_edit_enabled: true
        });
    };
    /**
     * Does not care about form validation.
     * Does not update form status.
     */
    CmsEntryFunnelReportComponent.prototype.submitProgress = function () {
        var _this = this;
        var data = this.form.getRawValue();
        this.save(data).catch(function (err) {
            _this.isSubmitting = false;
            _this.error = err.error.error;
        });
    };
    /**
     * Checks form status to make sure it passes validation.
     * Updates form status to 1, or leaves at 2 (if admin).
     */
    CmsEntryFunnelReportComponent.prototype.submitFinal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, previousStatus, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.form.invalid && !this.form.controls.incomplete_explanation.value) {
                            this.hasIncompleteData = true;
                            this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
                            return [2 /*return*/];
                        }
                        data = this.form.getRawValue();
                        previousStatus = this.cmsResult.existingStatus;
                        if (this.cmsResult.existingStatus == 2 && this.sessionUser.isAdmin) {
                            data.status = 2; // keep status at 2 if admin is updating data, and report was already verified
                        }
                        else {
                            data.status = 1; // mark entry as complete
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.save(data)];
                    case 2:
                        _a.sent();
                        // if the user saving data is part of this org (non-admin)
                        // and the status has been updated, then we'll send emails.
                        if (this.sessionUser.orgId == this.getOrgId() && previousStatus != data.status) {
                            this.funnelEntryService.sendConfirmationEmail(this.sessionUser.userId, this.cmsResult.reportId);
                            this.funnelEntryService.sendAdminEmail(this.sessionUser.userId, this.cmsResult.reportId);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.isSubmitting = false;
                        this.error = err_1.error.error;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CmsEntryFunnelReportComponent.prototype.save = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var entryId, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data.naccap_app_reports_id = this.cmsResult.report.id;
                        data.naccap_orgs_id = this.getOrgId();
                        data.user_id = this.sessionUser.userId;
                        if (this.useOriginalGoalFeature) {
                            data.curr_goal = data.new_goal || data.original_goal_edit || data.original_goal;
                        }
                        if (this.cmsResult.report.isFinalReport) {
                            data.curr_goal = data.tns_enr_curr;
                            if (this.useOriginalGoalFeature) {
                                data.prev_actual = data.new_goal || data.original_goal_edit || data.original_goal;
                            }
                        }
                        if (data.original_goal_edit_enabled) {
                            data.new_goal = data.original_goal_edit;
                        }
                        entryId = lodash_1.get(this.cmsResult, 'reportEntry.id');
                        if (entryId) {
                            data.id = entryId;
                        }
                        this.isSubmitting = true;
                        this.error = {};
                        if (!data.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.funnelEntryService.update(data)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.funnelEntryService.create(data)];
                    case 3:
                        result = _a.sent();
                        this.form.patchValue({ id: result.insertId });
                        _a.label = 4;
                    case 4:
                        this.isSubmitting = false;
                        this.isSaved = true;
                        this.cmsResult.existingStatus = data.status;
                        return [2 /*return*/];
                }
            });
        });
    };
    // Event emitted from admin actions
    CmsEntryFunnelReportComponent.prototype.onAdminReportActions = function (status) { };
    CmsEntryFunnelReportComponent.prototype.hasBaseFormError = function (field) {
        return lodash_1.get(this.form, "errors.baseErrors." + field);
    };
    CmsEntryFunnelReportComponent.prototype.hasControlError = function (field) {
        var control = this.form.controls[field];
        return lodash_1.get(control, 'touched') && lodash_1.get(control, 'errors.required');
    };
    return CmsEntryFunnelReportComponent;
}());
exports.CmsEntryFunnelReportComponent = CmsEntryFunnelReportComponent;
function formBaseValidator(formGroup) {
    var errors = {};
    var hasErrors = false;
    if (+formGroup.controls['fr_app_curr'].value < +formGroup.controls['fr_adm_curr'].value) {
        errors['fr_app_curr'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['fr_adm_curr'].value < +formGroup.controls['fr_dep_curr'].value) {
        errors['fr_adm_curr'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['fr_app_prev'].value < +formGroup.controls['fr_adm_prev'].value) {
        errors['fr_app_prev'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['fr_adm_prev'].value < +formGroup.controls['fr_dep_prev'].value) {
        errors['fr_adm_prev'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['fr_adm_prev'].value < +formGroup.controls['fr_enr_prev'].value) {
        errors['fr_enr_prev'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['fr_adm_curr'].value < +formGroup.controls['fr_enr_curr'].value) {
        errors['fr_enr_curr'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['tr_app_curr'].value < +formGroup.controls['tr_adm_curr'].value) {
        errors['tr_app_curr'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['tr_adm_curr'].value < +formGroup.controls['tr_dep_curr'].value) {
        errors['tr_adm_curr'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['tr_app_prev'].value < +formGroup.controls['tr_adm_prev'].value) {
        errors['tr_app_prev'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['tr_adm_prev'].value < +formGroup.controls['tr_dep_prev'].value) {
        errors['tr_adm_prev'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['tr_adm_prev'].value < +formGroup.controls['tr_enr_prev'].value) {
        errors['tr_enr_prev'] = true;
        hasErrors = true;
    }
    if (+formGroup.controls['tr_adm_curr'].value < +formGroup.controls['tr_enr_curr'].value) {
        errors['tr_enr_curr'] = true;
        hasErrors = true;
    }
    return hasErrors ? { baseErrors: errors } : null;
}
