"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./save-screenshot.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./save-screenshot.component");
var styles_SaveScreenshotComponent = [i0.styles];
var RenderType_SaveScreenshotComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SaveScreenshotComponent, data: {} });
exports.RenderType_SaveScreenshotComponent = RenderType_SaveScreenshotComponent;
function View_SaveScreenshotComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "grid__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "grid__col--12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn--gold float--right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save Screenshot"]))], null, null); }
function View_SaveScreenshotComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { download: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["download", 1]], null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SaveScreenshotComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filename; _ck(_v, 4, 0, currVal_0); }, null); }
exports.View_SaveScreenshotComponent_0 = View_SaveScreenshotComponent_0;
function View_SaveScreenshotComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rd-save-screenshot", [], null, null, null, View_SaveScreenshotComponent_0, RenderType_SaveScreenshotComponent)), i1.ɵdid(1, 114688, null, 0, i3.SaveScreenshotComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SaveScreenshotComponent_Host_0 = View_SaveScreenshotComponent_Host_0;
var SaveScreenshotComponentNgFactory = i1.ɵccf("rd-save-screenshot", i3.SaveScreenshotComponent, View_SaveScreenshotComponent_Host_0, { filename: "filename" }, {}, ["*"]);
exports.SaveScreenshotComponentNgFactory = SaveScreenshotComponentNgFactory;
