"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cms_entry_result_404_1 = require("@app/cms/results/cms-entry-result-404");
var account_1 = require("@app/account/account");
var CmsEntry404Component = /** @class */ (function () {
    function CmsEntry404Component() {
    }
    return CmsEntry404Component;
}());
exports.CmsEntry404Component = CmsEntry404Component;
