"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../alert/alert.component.ngfactory");
var i2 = require("../alert/alert.component");
var i3 = require("../../../../node_modules/ngx-quill/ngx-quill.ngfactory");
var i4 = require("ngx-quill");
var i5 = require("@angular/platform-browser");
var i6 = require("@angular/common");
var i7 = require("@angular/forms");
var i8 = require("./admin-report-actions.component");
var i9 = require("../../cms/abs-submit/abs-entry.service");
var i10 = require("../../funnel/funnel-entry.service");
var styles_AdminReportActionsComponent = [];
var RenderType_AdminReportActionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminReportActionsComponent, data: {} });
exports.RenderType_AdminReportActionsComponent = RenderType_AdminReportActionsComponent;
function View_AdminReportActionsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "rd-alert", [["type", "danger"]], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i2.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Error"])), (_l()(), i0.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error.message; _ck(_v, 4, 0, currVal_1); }); }
function View_AdminReportActionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You may now verify the data using the buttons below."])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "input", [["class", "btn btn--gold"], ["name", "submit_verify"], ["type", "submit"], ["value", "Verify Data"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.verify() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["class", "btn btn--ghost btn--ghost--gold"], ["name", "submit_reject"], ["type", "submit"], ["value", "Place data on Hold"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "quill-editor", [["id", "reject-reason"], ["name", "reject_reason"], ["placeholder", "Reason for placing data on hold..."]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.rejectReason = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_QuillEditorComponent_0, i3.RenderType_QuillEditorComponent)), i0.ɵdid(6, 4898816, null, 0, i4.QuillEditorComponent, [i0.ElementRef, i5.DomSanitizer, i6.DOCUMENT, i0.PLATFORM_ID, i0.Renderer2, i0.NgZone, i4.QUILL_CONFIG_TOKEN], { placeholder: [0, "placeholder"] }, null), i0.ɵprd(1024, null, i7.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.QuillEditorComponent]), i0.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.QuillEditorComponent]), i0.ɵdid(9, 671744, null, 0, i7.NgModel, [[8, null], [6, i7.NG_VALIDATORS], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i0.ɵdid(11, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminReportActionsComponent_2)), i0.ɵdid(13, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_9 = "Reason for placing data on hold..."; _ck(_v, 6, 0, currVal_9); var currVal_10 = "reject_reason"; var currVal_11 = _co.rejectReason; _ck(_v, 9, 0, currVal_10, currVal_11); var currVal_12 = _co.error; _ck(_v, 13, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSubmitting; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isSubmitting; _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 11).ngClassValid; var currVal_7 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_AdminReportActionsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "rd-alert", [["type", "success"]], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i2.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Success"])), (_l()(), i0.ɵted(-1, 0, [" You have successfully validated this report.\n"]))], function (_ck, _v) { var currVal_0 = "success"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AdminReportActionsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "rd-alert", [["type", "warning"]], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i2.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Success"])), (_l()(), i0.ɵted(-1, 0, [" You have successfully placed this report on hold. An email will be sent to the users of this organization letting them know that their report has been reopened.\n"]))], function (_ck, _v) { var currVal_0 = "warning"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AdminReportActionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "hr", [["class", "hr--thick"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Admin"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminReportActionsComponent_1)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminReportActionsComponent_3)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminReportActionsComponent_4)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.savedVerification && !_co.savedReject); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.savedVerification; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.savedReject; _ck(_v, 8, 0, currVal_2); }, null); }
exports.View_AdminReportActionsComponent_0 = View_AdminReportActionsComponent_0;
function View_AdminReportActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rd-admin-report-actions", [], null, null, null, View_AdminReportActionsComponent_0, RenderType_AdminReportActionsComponent)), i0.ɵdid(1, 49152, null, 0, i8.AdminReportActionsComponent, [i9.AbsEntryService, i10.FunnelEntryService], null, null)], null, null); }
exports.View_AdminReportActionsComponent_Host_0 = View_AdminReportActionsComponent_Host_0;
var AdminReportActionsComponentNgFactory = i0.ɵccf("rd-admin-report-actions", i8.AdminReportActionsComponent, View_AdminReportActionsComponent_Host_0, { user: "user", entryId: "entryId", orgId: "orgId", reportId: "reportId", type: "type" }, { onSave: "onSave" }, []);
exports.AdminReportActionsComponentNgFactory = AdminReportActionsComponentNgFactory;
