"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var account_service_1 = require("@app/account/account.service");
var PendingChangesGuard = /** @class */ (function () {
    function PendingChangesGuard(accountService) {
        this.accountService = accountService;
    }
    PendingChangesGuard.prototype.canDeactivate = function (component) {
        if (!this.accountService.hasUnsavedChanges) {
            return true;
        }
        if (confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.')) {
            this.accountService.hasUnsavedChanges = false;
            return true;
        }
        return false;
    };
    return PendingChangesGuard;
}());
exports.PendingChangesGuard = PendingChangesGuard;
