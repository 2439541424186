"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var cms_entry_result_funnel_landing_1 = require("@app/cms/results/cms-entry-result-funnel-landing");
var account_service_1 = require("@app/account/account.service");
var account_1 = require("@app/account/account");
var CmsEntryWatchlistComponent = /** @class */ (function () {
    function CmsEntryWatchlistComponent(router, route, accountService) {
        this.router = router;
        this.route = route;
        this.accountService = accountService;
        this.page = 1;
        this.filters = {};
        this.defaults = {};
    }
    CmsEntryWatchlistComponent.prototype.ngOnInit = function () {
        this.defaults = {
            reportId: this.route.snapshot.queryParams.id || this.cmsResult.mostRecentReport.id,
            search: this.route.snapshot.queryParams.search
        };
        this.page = this.route.snapshot.queryParams.page;
    };
    CmsEntryWatchlistComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.cmsResult) {
            if (!this.cmsResult.paginatedReports) {
                this.cmsResult.paginatedReports = { items: [] };
            }
            if (this.cmsResult.reportEntry) {
                this.cmsResult.paginatedReports.items.unshift(this.cmsResult.reportEntry);
            }
            this.cmsResult.paginatedReports.items.map(function (item) {
                item._is_on_watchlist = _this.cmsResult.watchlist.indexOf(item.naccap_orgs_id) > -1;
                return item;
            });
        }
    };
    Object.defineProperty(CmsEntryWatchlistComponent.prototype, "isVerifiedToViewResults", {
        get: function () {
            return this.sessionUser.isAdmin || this.cmsResult.isEntryVerified;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryWatchlistComponent.prototype, "reportId", {
        get: function () {
            return this.filters.reportId || this.defaults.reportId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryWatchlistComponent.prototype, "search", {
        get: function () {
            return this.filters.search || '';
        },
        enumerable: true,
        configurable: true
    });
    CmsEntryWatchlistComponent.prototype.onPageChanged = function (page) {
        this.page = page;
        this.updatePage();
    };
    CmsEntryWatchlistComponent.prototype.onFilterUpdate = function (filters) {
        this.filters = filters;
        this.page = 1;
        this.updatePage();
    };
    CmsEntryWatchlistComponent.prototype.updatePage = function () {
        var page = this.page;
        var search = this.search;
        var id = this.reportId;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: page, search: search, id: id }
        });
    };
    CmsEntryWatchlistComponent.prototype.onWatchlistChanged = function (report) {
        if (report._is_on_watchlist) {
            return this.accountService.addOrgToWatchlist(this.sessionUser.userId, report.naccap_orgs_id);
        }
        return this.accountService.removeOrgFromWatchlist(this.sessionUser.userId, report.naccap_orgs_id);
    };
    return CmsEntryWatchlistComponent;
}());
exports.CmsEntryWatchlistComponent = CmsEntryWatchlistComponent;
