"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var account_1 = require("@app/account/account");
var environment_1 = require("@env/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var AccountService = /** @class */ (function () {
    function AccountService(http) {
        var _this = this;
        this.http = http;
        this._loggedIn = new rxjs_1.BehaviorSubject(false);
        this.loggedIn = this._loggedIn.asObservable();
        // Use this variable to keep users from leaving a form with unsaved changes
        this.hasUnsavedChanges = false;
        this.http
            .get(environment_1.environment.apiUrl + "/crud/session")
            .toPromise()
            .then(function (user) {
            _this._loggedIn.next(user.id != null);
        });
    }
    AccountService.prototype.saveAccount = function (account, isCreate) {
        var path = environment_1.environment.apiUrl + "/crud/user/" + account.userId;
        var user = { username: account.username, email: account.email };
        var roles = { is_admin: account.isAdmin, is_editor: account.isEditor, is_org_superuser: account.isOrgSuperuser };
        if (isCreate) {
            path = environment_1.environment.apiUrl + "/crud/user";
        }
        else {
            user.id = account.userId;
        }
        return this.http
            .post(path, tslib_1.__assign({ user: user }, roles))
            .toPromise()
            .catch(this._handleError);
    };
    AccountService.prototype.getSessionUser = function () {
        return this.http
            .get(environment_1.environment.apiUrl + "/crud/user")
            .toPromise()
            .then(function (res) { return new account_1.Account(res); })
            .catch(this._handleError);
    };
    AccountService.prototype.forgotPassword = function (email) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/login/forgot", { email: email })
            .toPromise()
            .catch(this._handleError);
    };
    AccountService.prototype.updatePassword = function (user_id, old_password, new_password, new_password_confirm) {
        if (new_password !== new_password_confirm) {
            return Promise.reject(new Error('New password and confirmation password do not match.'));
        }
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/user/" + user_id + "/password", { old_password: old_password, new_password: new_password })
            .toPromise()
            .catch(this._handleError);
    };
    AccountService.prototype.login = function (email, password) {
        var _this = this;
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/login", { email: email, password: password })
            .toPromise()
            .then(function (data) {
            _this._loggedIn.next(true);
            return _this.http
                .get(environment_1.environment.apiUrl + "/crud/session")
                .toPromise()
                .then(function (user) {
                data.user = user;
                return data;
            });
        });
    };
    AccountService.prototype.logout = function () {
        var _this = this;
        return this.http
            .get(environment_1.environment.apiUrl + "/logout")
            .toPromise()
            .then(function () { return _this._loggedIn.next(false); })
            .catch(this._handleError);
    };
    AccountService.prototype.isLoggedIn = function () {
        var _this = this;
        if (this._loggedIn.getValue() !== null) {
            return this.loggedIn;
        }
        return this.http.get(environment_1.environment.apiUrl + "/crud/session").pipe(operators_1.map(function (user) {
            _this._loggedIn.next(user.id != null);
            return _this._loggedIn.getValue();
        }));
    };
    AccountService.prototype.addOrgToWatchlist = function (user_id, naccap_orgs_id) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/watchlist", { user_id: user_id, naccap_orgs_id: naccap_orgs_id }).toPromise();
    };
    AccountService.prototype.removeOrgFromWatchlist = function (user_id, naccap_orgs_id) {
        return this.http.delete(environment_1.environment.apiUrl + "/crud/watchlist/" + user_id + "/" + naccap_orgs_id).toPromise();
    };
    AccountService.prototype.sendContactRequest = function (data) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/email/contact", data).toPromise();
    };
    AccountService.prototype.exportHistory = function (type, org_id, report_ids, include_watchlist) {
        var extraParams = [];
        if (report_ids && report_ids.length) {
            extraParams.push('&ids[]=' + report_ids.join('&ids[]='));
        }
        if (include_watchlist) {
            extraParams.push("&include_watchlist=" + include_watchlist);
        }
        window.location.href = environment_1.environment.apiUrl + "/export/history?type=" + type + "&org_id=" + org_id + extraParams.join();
    };
    AccountService.prototype.exportFunnel = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/funnel/" + report_id;
    };
    AccountService.prototype.exportFunnelFinal = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/funnel-final/" + report_id;
    };
    AccountService.prototype.exportCostToRecruit = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/abs/cost-to-recruit/" + report_id;
    };
    AccountService.prototype.exportAbsFunnelSummary = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/abs/funnel-summary/" + report_id;
    };
    AccountService.prototype.exportAbsFunnel = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/abs/funnel/" + report_id;
    };
    AccountService.prototype.exportAbsBudget = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/abs/budget/" + report_id;
    };
    AccountService.prototype.exportAbsBudgetComplete = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/abs/budget-complete/" + report_id;
    };
    AccountService.prototype.exportAbsSummary = function (report_id) {
        window.location.href = environment_1.environment.apiUrl + "/export/abs/summary/" + report_id;
    };
    AccountService.prototype.exportAbsCustomSummary = function (report_id, org_id, org_ids) {
        var extraParams = [];
        if (org_ids && org_ids.length) {
            extraParams.push('&org_ids[]=' + org_ids.join('&org_ids[]='));
        }
        window.location.href = environment_1.environment.apiUrl + "/export/abs/summary-custom/" + report_id + "/" + org_id + "?" + extraParams.join();
    };
    AccountService.prototype._handleError = function (err) {
        console.log('Account service error: ', err);
        throw err;
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
exports.AccountService = AccountService;
