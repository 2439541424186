"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var currency_pipe_1 = require("./currency.pipe");
var CurrencyInputDirective = /** @class */ (function () {
    function CurrencyInputDirective(elementRef, currencyPipe, control) {
        this.elementRef = elementRef;
        this.currencyPipe = currencyPipe;
        this.control = control;
        this.el = this.elementRef.nativeElement;
    }
    CurrencyInputDirective.prototype.ngOnInit = function () {
        this.el.value = this.currencyPipe.transform(this.el.value, 0);
    };
    CurrencyInputDirective.prototype.onFocus = function (value) {
        this.el.value = this.currencyPipe.parse(value, 0); // opossite of transform
    };
    CurrencyInputDirective.prototype.onBlur = function (value) {
        var val = parseCurrency(value);
        if (val !== 0 && !val) {
            val = '';
        }
        if (this.control && this.control.control) {
            this.control.control.setValue(val);
        }
        this.el.value = this.currencyPipe.transform(val, 0);
    };
    CurrencyInputDirective.prototype.onKeyDown = function (e) {
        if (
        // Allow: Delete, Backspace, Tab, Escape, Enter
        [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
            (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
            (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
            (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
            (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
            (e.keyCode === 82 && e.ctrlKey === true) || // Allow: Ctrl+R
            (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
            (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
            (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
            (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
            (e.keyCode === 82 && e.metaKey === true) || // Cmd+R (Mac)
            (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
        ) {
            return; // let it happen, don't do anything
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
            (e.keyCode < 96 || e.keyCode > 105) &&
            e.keyCode != 190 &&
            e.keyCode != 188 // . and , are ok
        ) {
            e.preventDefault();
        }
    };
    return CurrencyInputDirective;
}());
exports.CurrencyInputDirective = CurrencyInputDirective;
function parseCurrency(val) {
    if (lodash_1.isString(val)) {
        val = val.replace(',', '');
    }
    return Math.round(parseFloat(val));
}
exports.parseCurrency = parseCurrency;
