"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var account_1 = require("@app/account/account");
var abs_submit_part_helper_1 = require("./abs-submit-part-helper");
var CmsEntryAbsSubmitPartDComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryAbsSubmitPartDComponent, _super);
    function CmsEntryAbsSubmitPartDComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isSavedFinal = false;
        _this.isSavedTemporary = false;
        _this.isSavedAndReturning = false;
        _this.hasEmptiesBeenNotified = false;
        _this.empties = { a: false, b: false, c: false, d: false };
        _this.sectionCRequirementMet = false;
        return _this;
    }
    CmsEntryAbsSubmitPartDComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;
        var numberValidators = [forms_1.Validators.max(8000000), forms_1.Validators.pattern(/^[0-9]*$/)];
        var numberSmallValidators = [forms_1.Validators.max(32000), forms_1.Validators.pattern(/^[0-9]*$/)];
        this.form = this.formBuilder.group({
            d_inquired: [this.cmsResult.d_inquired, numberValidators],
            d_applied: [this.cmsResult.d_applied, numberSmallValidators],
            d_completed_app: [this.cmsResult.d_completed_app, numberSmallValidators],
            d_accepted: [this.cmsResult.d_accepted, numberSmallValidators],
            d_acceptance_rate: { value: '', disabled: true },
            d_enrolled: [this.cmsResult.d_enrolled, numberSmallValidators],
            reject_reason: ''
        }, { validator: baseValidator });
        this.updateTotals('d_acceptance_rate', ['d_accepted', 'd_completed_app'], function (totals) {
            if (!_this.form.controls.d_completed_app.value) {
                return 0;
            }
            var total = (+_this.form.controls.d_accepted.value / +_this.form.controls.d_completed_app.value) * 100;
            return total;
        });
        if (!this.hasWritePermission()) {
            this.form.disable();
        }
        this.listenForFormChanges();
    };
    CmsEntryAbsSubmitPartDComponent.prototype.updateTotals = function (updateField, fields, tweakTotal) {
        var _this = this;
        if (tweakTotal === void 0) { tweakTotal = null; }
        var _updateTotals = function () {
            var total = 0;
            fields.forEach(function (field) {
                total += parseInt(_this.form.controls[field].value || 0);
            });
            if (tweakTotal) {
                total = tweakTotal(total);
            }
            _this.form.controls[updateField].setValue(total);
        };
        fields.forEach(function (field) {
            _this.form.controls[field].valueChanges.subscribe(_updateTotals);
        });
        _updateTotals(); // trigger an update just in case legacy data wasn't saved properly
    };
    CmsEntryAbsSubmitPartDComponent.prototype.saveFinal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hasEmpties, _a, hasEmptiesInPartC, metRequirement, data, entryId, result, previousStatus;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        hasEmpties = false;
                        if (!this.hasEmptiesBeenNotified) {
                            if (this.hasEmptiesInPartA()) {
                                this.hasEmptiesBeenNotified = true;
                                this.empties.a = true;
                                hasEmpties = true;
                            }
                            if (this.hasEmptiesInPartB()) {
                                this.hasEmptiesBeenNotified = true;
                                this.empties.b = true;
                                hasEmpties = true;
                            }
                            if (this.hasEmptiesInPartD()) {
                                this.hasEmptiesBeenNotified = true;
                                this.empties.d = true;
                                hasEmpties = true;
                            }
                            _a = this.hasEmptiesInPartC(), hasEmptiesInPartC = _a[0], metRequirement = _a[1];
                            this.sectionCRequirementMet = metRequirement;
                            if (hasEmptiesInPartC) {
                                this.hasEmptiesBeenNotified = true;
                                this.empties.c = true;
                                hasEmpties = true;
                            }
                            if (!this.sectionCRequirementMet) {
                                this.hasEmptiesBeenNotified = false;
                                hasEmpties = true;
                            }
                        }
                        if (!hasEmpties) return [3 /*break*/, 5];
                        data = this.form.getRawValue();
                        data.naccap_abs_surveys_id = this.cmsResult.report.id;
                        data.naccap_orgs_id = this.orgId;
                        data.user_id = this.sessionUser.userId;
                        data.d_last_user = this.sessionUser.userId;
                        data.d_last_update = new Date();
                        entryId = this.cmsResult.entryId;
                        if (entryId) {
                            data.id = entryId;
                        }
                        if (!data.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.absEntryService.update(data)];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.absEntryService.create(data)];
                    case 3:
                        result = _b.sent();
                        this.form.patchValue({ id: result.insertId });
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                    case 5:
                        previousStatus = this.cmsResult.existingStatus;
                        return [4 /*yield*/, this.save(1)];
                    case 6:
                        _b.sent();
                        this.isSavedFinal = true;
                        // If a user from this org is saving the final data send emails
                        if (this.sessionUser.orgId == this.cmsResult.orgId && previousStatus != this.cmsResult.existingStatus) {
                            this.absEntryService.sendConfirmationEmail(this.sessionUser.userId, this.cmsResult.surveyId);
                            this.absEntryService.sendAdminEmail(this.sessionUser.userId, this.cmsResult.surveyId);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CmsEntryAbsSubmitPartDComponent.prototype.saveTemporary = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.save()];
                    case 1:
                        _a.sent();
                        this.isSavedTemporary = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    CmsEntryAbsSubmitPartDComponent.prototype.saveAndGoBack = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.save()];
                    case 1:
                        _a.sent();
                        this.isSavedAndReturning = true;
                        setTimeout(function () {
                            var url = lodash_1.get(_this.cmsResult.urls, 'abs-submit-part-c');
                            _this.router.navigate([url], { queryParamsHandling: 'merge' });
                        }, 2000);
                        return [2 /*return*/];
                }
            });
        });
    };
    CmsEntryAbsSubmitPartDComponent.prototype.save = function (status, forceStatus) {
        if (status === void 0) { status = 0; }
        if (forceStatus === void 0) { forceStatus = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, entryId, result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.form.invalid) {
                            this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
                            return [2 /*return*/];
                        }
                        if (this.isSubmitting) {
                            return [2 /*return*/];
                        }
                        data = this.form.getRawValue();
                        // NOTE: we set the status (which gets passed into this function as an argument)
                        // if the user is not an admin
                        // or if the user is an admin and the status is higher than the current status i.e. going from incomplete to complete, or complete to verified.
                        if (forceStatus || !this.sessionUser.isAdmin || status > this.cmsResult.existingStatus) {
                            data.status = status;
                        }
                        data.naccap_abs_surveys_id = this.cmsResult.report.id;
                        data.naccap_orgs_id = this.orgId;
                        data.user_id = this.sessionUser.userId;
                        data.d_last_user = this.sessionUser.userId;
                        data.d_last_update = new Date();
                        entryId = this.cmsResult.entryId;
                        if (entryId) {
                            data.id = entryId;
                        }
                        this.isSubmitting = true;
                        this.error = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!data.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.absEntryService.update(data)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.absEntryService.create(data)];
                    case 4:
                        result = _a.sent();
                        this.form.patchValue({ id: result.insertId });
                        _a.label = 5;
                    case 5:
                        this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
                        this.isSaved = true;
                        this.isSubmitting = false;
                        this.onSaveSuccess();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        this.error = err_1.error.error;
                        this.isSubmitting = false;
                        throw err_1;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    // Event emitted from admin actions
    CmsEntryAbsSubmitPartDComponent.prototype.onAdminReportActions = function (status) { };
    CmsEntryAbsSubmitPartDComponent.prototype.hasEmptiesInPartA = function () {
        var a_fields = [
            'a_prof_salaries',
            'a_support_salaries',
            'a_fringe_benefit',
            'a_student_wages',
            'a_salary_subtotal',
            'a_advertising',
            'a_publications',
            'a_elec_media',
            'a_direct_mail',
            'a_travel',
            'a_oncampus_ent',
            'a_phone',
            'a_postage',
            'a_outside_assistance',
            'a_athletics_recruiting',
            'a_crm',
            'a_professional_development',
            'a_office_supplies',
            'a_nonsalary_subtotal',
            'a_grand_total'
        ];
        if (this.cmsResult.a_other_budget) {
            a_fields.push('a_other_breakdown');
        }
        var empty = this.hasEmptyFieldsInReport(a_fields)[0];
        return empty;
    };
    CmsEntryAbsSubmitPartDComponent.prototype.hasEmptiesInPartB = function () {
        var b_fields = ['b_total_enr', 'b_ftns_fall', 'b_ftns_spring', 'b_ftns_total'];
        var empty = this.hasEmptyFieldsInReport(b_fields)[0];
        return empty;
    };
    CmsEntryAbsSubmitPartDComponent.prototype.hasEmptiesInPartC = function () {
        var c_fields = [
            'c_pro_staff',
            'c_pro_staff_avg_sal',
            'c_pro_staff_direct_recruiting',
            'c_support_staff',
            'c_support_staff_avg_sal',
            'c_stud_labor',
            'c_adm_cnslr_start_sal',
            'c_asst_dir_sal',
            'c_asst_dir_yrs_adm',
            'c_asst_dir_yrs_pos',
            'c_assoc_dir_sal',
            'c_assoc_dir_yrs_adm',
            'c_assoc_dir_yrs_pos',
            'c_dir_adm_sal',
            'c_dir_adm_yrs_adm',
            'c_dir_adm_yrs_pos',
            'c_vpdean_title',
            'c_vpdean_sal',
            'c_vpdean_yrs_adm',
            'c_vpdean_yrs_pos',
            'c_min_edu_adm_cnslr',
            'c_min_edu_asst_dir',
            'c_min_edu_dir',
            'c_min_edu_vpdean'
        ];
        if (this.cmsResult.c_sr_cnslr_pos) {
            c_fields.push('c_sr_cnslr_sal');
        }
        if (this.cmsResult.c_car_other_staff) {
            c_fields.push('c_car_others');
        }
        var _a = this.hasEmptyFieldsInReport(c_fields), hasEmpties = _a[0], numEmpties = _a[1];
        return [hasEmpties, numEmpties / c_fields.length < 0.5];
    };
    CmsEntryAbsSubmitPartDComponent.prototype.hasEmptiesInPartD = function () {
        var _this = this;
        var numEmpty = 0;
        var d_fields = ['d_inquired', 'd_applied', 'd_completed_app', 'd_accepted', 'd_acceptance_rate', 'd_enrolled'];
        d_fields.forEach(function (field) {
            var value = _this.form.controls[field].value;
            if (!value || value == 0) {
                numEmpty++;
            }
        });
        return numEmpty > 0;
    };
    CmsEntryAbsSubmitPartDComponent.prototype.hasEmptyFieldsInReport = function (fields) {
        var _this = this;
        var numEmpty = 0;
        fields.forEach(function (field) {
            var value = _this.cmsResult.reportEntry[field];
            if (!value || value == 0) {
                numEmpty++;
            }
        });
        return [numEmpty > 0, numEmpty];
    };
    return CmsEntryAbsSubmitPartDComponent;
}(abs_submit_part_helper_1.AbsSubmitPartHelper));
exports.CmsEntryAbsSubmitPartDComponent = CmsEntryAbsSubmitPartDComponent;
function baseValidator(formGroup) {
    var errors = {};
    var hasErrors = false;
    if (+formGroup.controls.d_inquired.value - +formGroup.controls.d_applied.value < 0) {
        errors.moreApplicantsThanInquiries = true;
        hasErrors = true;
    }
    if (+formGroup.controls.d_applied.value - +formGroup.controls.d_completed_app.value < 0) {
        errors.moreCompletedAppsThanApplications = true;
        hasErrors = true;
    }
    if (+formGroup.controls.d_completed_app.value - +formGroup.controls.d_accepted.value < 0) {
        errors.moreAcceptedThanCompleted = true;
        hasErrors = true;
    }
    if (+formGroup.controls.d_accepted.value - +formGroup.controls.d_enrolled.value < 0) {
        errors.moreEnrolledThanAccepted = true;
        hasErrors = true;
    }
    return hasErrors ? errors : null;
}
