"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var cms_entry_result_homepage_1 = require("@app/cms/results/cms-entry-result-homepage");
var account_1 = require("@app/account/account");
var account_service_1 = require("@app/account/account.service");
var CmsEntryHomepageComponent = /** @class */ (function () {
    function CmsEntryHomepageComponent(accountService, formBuilder) {
        this.accountService = accountService;
        this.formBuilder = formBuilder;
        this.filters = {
            absReport: null,
            memberReport: null
        };
        this.error = null;
        this.attemptedSubmit = false;
        this.saved = false;
        this.isSending = false;
        this.createForm();
    }
    CmsEntryHomepageComponent.prototype.createForm = function () {
        this.form = this.formBuilder.group({
            fullname: ['Self Request'],
            email: ['Self Request'],
            question: ['I need to create a new account'],
            details: ['No additional details.', forms_1.Validators.required],
            new_fullname: ['', forms_1.Validators.required],
            new_title: ['', forms_1.Validators.required],
            new_institution: ['', forms_1.Validators.required],
            new_email: ['', forms_1.Validators.required]
        });
    };
    CmsEntryHomepageComponent.prototype.doesControlHaveError = function (control) {
        return (control.touched || this.attemptedSubmit) && control.invalid;
    };
    CmsEntryHomepageComponent.prototype.ngOnInit = function () {
        var absReport = lodash_1.get(this.cmsResult.completedAbsReports, '0');
        this.filters.absReport = absReport;
        this.setReportGroupMap(this.cmsResult.completedFunnelReports);
        this.filters.funnelReport = lodash_1.get(this.funnelReportGroups, '0');
    };
    CmsEntryHomepageComponent.prototype.setReportGroupMap = function (reports) {
        this.funnelReportGroups = lodash_1.toArray(lodash_1.groupBy(reports, 'reportYear')).reverse();
    };
    CmsEntryHomepageComponent.prototype.onContactSubmit = function () {
        var _this = this;
        this.attemptedSubmit = true;
        this.saved = false;
        if (this.form.invalid) {
            this.error = { message: 'Fix errors and try again.' };
            return;
        }
        this.isSending = true;
        this.accountService
            .sendContactRequest(this.form.value)
            .then(function () {
            _this.saved = true;
            _this.isSending = false;
            _this.form.reset();
            _this.attemptedSubmit = false;
        })
            .catch(function (data) {
            _this.error = { message: data.error.error.message };
            _this.isSending = false;
        });
    };
    return CmsEntryHomepageComponent;
}());
exports.CmsEntryHomepageComponent = CmsEntryHomepageComponent;
