"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var cms_entry_result_1 = require("@app/cms/results/cms-entry-result");
var account_1 = require("@app/account/account");
var CmsEntrySubpageComponent = /** @class */ (function () {
    function CmsEntrySubpageComponent(
    // private sanitizer: DomSanitizer,
    route, router) {
        this.route = route;
        this.router = router;
        this.type = null; // specify a subtype, such as the search page to make tweaks.
        this.safeContent = null;
        this.googleSearch = null;
        this.fragment = null;
    }
    CmsEntrySubpageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.fragment.subscribe(function (fragment) { return (_this.fragment = fragment); });
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                var urlTree = _this.router.parseUrl(_this.router.url);
                _this.resolveFragment(urlTree.fragment);
            }
        });
    };
    CmsEntrySubpageComponent.prototype.ngAfterViewChecked = function () {
        this.resolveFragment(this.fragment);
    };
    CmsEntrySubpageComponent.prototype.ngOnChanges = function () {
        // let content = this.cmsResult!.mainContent || '';
        // this.safeContent = this.sanitizer.bypassSecurityTrustHtml(content);
    };
    CmsEntrySubpageComponent.prototype.resolveFragment = function (fragment) {
        if (fragment) {
            var element = document.querySelector('#' + fragment);
            if (element) {
                element.scrollIntoView(true);
            }
        }
    };
    return CmsEntrySubpageComponent;
}());
exports.CmsEntrySubpageComponent = CmsEntrySubpageComponent;
