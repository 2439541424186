"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("@env/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var OrgService = /** @class */ (function () {
    function OrgService(http) {
        this.http = http;
    }
    OrgService.prototype.listAll = function () {
        return this.http.get(environment_1.environment.apiUrl + "/crud/org/list?show_all=1").toPromise();
    };
    OrgService.prototype.getOrgWatchlist = function (org_id) {
        return this.http.get(environment_1.environment.apiUrl + "/crud/watchlist/org/" + org_id).toPromise();
    };
    OrgService.ngInjectableDef = i0.defineInjectable({ factory: function OrgService_Factory() { return new OrgService(i0.inject(i1.HttpClient)); }, token: OrgService, providedIn: "root" });
    return OrgService;
}());
exports.OrgService = OrgService;
