"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var app_routing_guard_1 = require("./app-routing.guard");
var cms_entry_component_1 = require("@app/cms/cms-entry.component");
var routes = [
    {
        path: '**',
        component: cms_entry_component_1.CmsEntryComponent,
        canDeactivate: [app_routing_guard_1.PendingChangesGuard]
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
