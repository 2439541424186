"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var CmsEntryResult = /** @class */ (function () {
    function CmsEntryResult() {
    }
    Object.defineProperty(CmsEntryResult.prototype, "type", {
        get: function () {
            return lodash_1.get(this.template, 'template');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResult.prototype, "title", {
        get: function () {
            return lodash_1.get(this.entry, ['title']);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResult.prototype, "headerImage", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.main_image.0.image.value');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResult.prototype, "mainContent", {
        get: function () {
            return lodash_1.get(this.entry, 'entries.main.0.value');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * This function is helper function to further sanitize any part of the class that needs it.
     *
     *  i.e. using DomSanitizer to output more html such as iFrames that angular would otherwise
     *  complain about.
     * @param {DomSanitizer} sanitizer [description]
     */
    CmsEntryResult.prototype.sanitize = function (sanitizer) {
        var content = lodash_1.get(this.entry, 'entries.main.0.value');
        lodash_1.set(this.entry, 'entries.main.0.value', sanitizer.bypassSecurityTrustHtml(content));
        return this;
    };
    tslib_1.__decorate([
        cerialize_1.deserializeAs('cms_template'),
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResult.prototype, "template", void 0);
    tslib_1.__decorate([
        cerialize_1.deserializeAs('cms_entry'),
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResult.prototype, "entry", void 0);
    tslib_1.__decorate([
        cerialize_1.deserializeAs('cms_entry_navigation'),
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResult.prototype, "navigation", void 0);
    tslib_1.__decorate([
        cerialize_1.deserializeAs('cms_entry_navigation_levels_deep'),
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResult.prototype, "navigationLevelsDeep", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResult.prototype, "urls", void 0);
    return CmsEntryResult;
}());
exports.CmsEntryResult = CmsEntryResult;
