import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';

export interface ChartData {
	data: number[];
	labels?: string[];
	options?: any;
}

@Component({
	selector: 'rd-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnChanges {
	constructor() {}

	@Input()
	public id: any;

	@Input()
	public base: ChartData;

	@Input()
	public extraCharts: ChartData[];

	@Input()
	public type: string;

	@Input()
	public legend = true;

	public chartLabels: string[] = [];
	public chartDatasets: any = [];
	public options: any = {
		legend: {
			position: 'bottom'
		},
		yAxisID: '0'
	};
	public defaultColors = [
		// #ADC062
		[173, 192, 98],
		// #F6E495
		[246, 228, 149],
		// #AAC1D8
		[170, 193, 216],
		// #A88FCA
		[168, 143, 202],
		// #ACA39A
		[172, 163, 154],
		// #E7A35C
		[231, 163, 92],
		// #B9595C
		[185, 89, 92],
		// #648D3D
		[100, 141, 61],
		// #F4C42A
		[244, 196, 42],
		// #72A3C3
		[114, 163, 194],
		// #A2BB8A
		[162, 187, 138],
		// #C2D52F
		[194, 213, 47],
		// #B88C00
		[184, 140, 0],
		// #144981
		[20, 73, 129],
		// #6F635A
		[111, 99, 90]
	];
	public colors: any;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.base && changes.base.firstChange) {
			this.setDatasetsAndLabels(this.base, this.extraCharts);
		}
		if (changes.id) {
			this.setDatasetsAndLabels(this.base, this.extraCharts);
			this.colors = getColors(this.type, this.defaultColors);
		}
	}

	setDatasetsAndLabels(base: ChartData, extraCharts?: ChartData[]) {
		if (base) {
			this.chartDatasets = [{ data: base.data, ...base.options }];
			this.chartLabels = base.labels || [];
		}
		if (extraCharts) {
			extraCharts.forEach((chart: ChartData) => {
				this.chartDatasets.push({ data: chart.data, ...chart.options });
			});
		}
	}
}

function getColors(chartType: any, colors: any) {
	if (chartType === 'pie' || chartType === 'doughnut') {
		return [formatPieColors(colors)];
	}
	if (chartType === 'polarArea') {
		return formatPolarAreaColors(colors);
	}
	if (chartType === 'line' || chartType === 'radar') {
		return formatLineColor(colors);
	}
	if (chartType === 'bar' || chartType === 'horizontalBar') {
		return formatBarColor(colors);
	}
	throw new Error('Unknown chart type');
}

function rgba(colour: any, alpha: any) {
	return 'rgba(' + colour.concat(alpha).join(',') + ')';
}

function formatLineColor(colors: any) {
	return {
		backgroundColor: rgba(colors, 0.4),
		borderColor: rgba(colors, 1),
		pointBackgroundColor: rgba(colors, 1),
		pointBorderColor: '#fff',
		pointHoverBackgroundColor: '#fff',
		pointHoverBorderColor: rgba(colors, 0.8)
	};
}
function formatBarColor(colors: any) {
	return {
		backgroundColor: rgba(colors, 0.6),
		borderColor: rgba(colors, 1),
		hoverBackgroundColor: rgba(colors, 0.8),
		hoverBorderColor: rgba(colors, 1)
	};
}
function formatPieColors(colors: any) {
	return {
		backgroundColor: colors.map((color: any) => rgba(color, 1)),
		borderColor: colors.map(() => '#fff'),
		pointBackgroundColor: colors.map((color: any) => rgba(color, 0.8)),
		pointBorderColor: colors.map(() => '#fff'),
		pointHoverBackgroundColor: colors.map((color: any) => rgba(color, 1)),
		pointHoverBorderColor: colors.map((color: any) => rgba(color, 1))
	};
}
function formatPolarAreaColors(colors: any) {
	return {
		backgroundColor: colors.map((color: any) => rgba(color, 0.6)),
		borderColor: colors.map((color: any) => rgba(color, 1)),
		hoverBackgroundColor: colors.map((color: any) => rgba(color, 0.8)),
		hoverBorderColor: colors.map((color: any) => rgba(color, 1))
	};
}
