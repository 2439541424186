"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var ChartComponent = /** @class */ (function () {
    function ChartComponent() {
        this.legend = true;
        this.chartLabels = [];
        this.chartDatasets = [];
        this.options = {
            legend: {
                position: 'bottom'
            },
            yAxisID: '0'
        };
        this.defaultColors = [
            // #ADC062
            [173, 192, 98],
            // #F6E495
            [246, 228, 149],
            // #AAC1D8
            [170, 193, 216],
            // #A88FCA
            [168, 143, 202],
            // #ACA39A
            [172, 163, 154],
            // #E7A35C
            [231, 163, 92],
            // #B9595C
            [185, 89, 92],
            // #648D3D
            [100, 141, 61],
            // #F4C42A
            [244, 196, 42],
            // #72A3C3
            [114, 163, 194],
            // #A2BB8A
            [162, 187, 138],
            // #C2D52F
            [194, 213, 47],
            // #B88C00
            [184, 140, 0],
            // #144981
            [20, 73, 129],
            // #6F635A
            [111, 99, 90]
        ];
    }
    ChartComponent.prototype.ngOnChanges = function (changes) {
        if (changes.base && changes.base.firstChange) {
            this.setDatasetsAndLabels(this.base, this.extraCharts);
        }
        if (changes.id) {
            this.setDatasetsAndLabels(this.base, this.extraCharts);
            this.colors = getColors(this.type, this.defaultColors);
        }
    };
    ChartComponent.prototype.setDatasetsAndLabels = function (base, extraCharts) {
        var _this = this;
        if (base) {
            this.chartDatasets = [tslib_1.__assign({ data: base.data }, base.options)];
            this.chartLabels = base.labels || [];
        }
        if (extraCharts) {
            extraCharts.forEach(function (chart) {
                _this.chartDatasets.push(tslib_1.__assign({ data: chart.data }, chart.options));
            });
        }
    };
    return ChartComponent;
}());
exports.ChartComponent = ChartComponent;
function getColors(chartType, colors) {
    if (chartType === 'pie' || chartType === 'doughnut') {
        return [formatPieColors(colors)];
    }
    if (chartType === 'polarArea') {
        return formatPolarAreaColors(colors);
    }
    if (chartType === 'line' || chartType === 'radar') {
        return formatLineColor(colors);
    }
    if (chartType === 'bar' || chartType === 'horizontalBar') {
        return formatBarColor(colors);
    }
    throw new Error('Unknown chart type');
}
function rgba(colour, alpha) {
    return 'rgba(' + colour.concat(alpha).join(',') + ')';
}
function formatLineColor(colors) {
    return {
        backgroundColor: rgba(colors, 0.4),
        borderColor: rgba(colors, 1),
        pointBackgroundColor: rgba(colors, 1),
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: rgba(colors, 0.8)
    };
}
function formatBarColor(colors) {
    return {
        backgroundColor: rgba(colors, 0.6),
        borderColor: rgba(colors, 1),
        hoverBackgroundColor: rgba(colors, 0.8),
        hoverBorderColor: rgba(colors, 1)
    };
}
function formatPieColors(colors) {
    return {
        backgroundColor: colors.map(function (color) { return rgba(color, 1); }),
        borderColor: colors.map(function () { return '#fff'; }),
        pointBackgroundColor: colors.map(function (color) { return rgba(color, 0.8); }),
        pointBorderColor: colors.map(function () { return '#fff'; }),
        pointHoverBackgroundColor: colors.map(function (color) { return rgba(color, 1); }),
        pointHoverBorderColor: colors.map(function (color) { return rgba(color, 1); })
    };
}
function formatPolarAreaColors(colors) {
    return {
        backgroundColor: colors.map(function (color) { return rgba(color, 0.6); }),
        borderColor: colors.map(function (color) { return rgba(color, 1); }),
        hoverBackgroundColor: colors.map(function (color) { return rgba(color, 0.8); }),
        hoverBorderColor: colors.map(function (color) { return rgba(color, 1); })
    };
}
