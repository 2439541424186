"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var account_1 = require("@app/account/account");
var CmsEntryAbsSubmitComponent = /** @class */ (function () {
    function CmsEntryAbsSubmitComponent() {
    }
    CmsEntryAbsSubmitComponent.prototype.ngOnInit = function () { };
    return CmsEntryAbsSubmitComponent;
}());
exports.CmsEntryAbsSubmitComponent = CmsEntryAbsSubmitComponent;
