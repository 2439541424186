"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("@env/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var FunnelEntryService = /** @class */ (function () {
    function FunnelEntryService(http) {
        this.http = http;
    }
    FunnelEntryService.prototype.create = function (data) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/funnel-entry", data).toPromise();
    };
    FunnelEntryService.prototype.update = function (data) {
        return this.http.patch(environment_1.environment.apiUrl + "/crud/funnel-entry", data).toPromise();
    };
    FunnelEntryService.prototype.getLatestOpenReport = function () {
        return this.http.get(environment_1.environment.apiUrl + "/crud/funnel/latest-open").toPromise();
    };
    FunnelEntryService.prototype.sendConfirmationEmail = function (user_id, report_id) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/email/funnel-confirmation", { user_id: user_id, report_id: report_id })
            .toPromise();
    };
    FunnelEntryService.prototype.sendAdminEmail = function (user_id, report_id) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/email/funnel-completed", { user_id: user_id, report_id: report_id }).toPromise();
    };
    FunnelEntryService.prototype.sendRejectedEmail = function (org_id, report_id, reason) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/email/funnel-rejected", { org_id: org_id, report_id: report_id, reason: reason })
            .toPromise();
    };
    FunnelEntryService.prototype.sendAcceptedEmail = function (org_id, report_id) {
        return this.http.post(environment_1.environment.apiUrl + "/crud/email/funnel-accepted", { org_id: org_id, report_id: report_id }).toPromise();
    };
    FunnelEntryService.prototype.sendVerifiedEmail = function (user_id, report_id, org_id) {
        return this.http
            .post(environment_1.environment.apiUrl + "/crud/email/funnel-verified", { user_id: user_id, report_id: report_id, org_id: org_id })
            .toPromise();
    };
    FunnelEntryService.ngInjectableDef = i0.defineInjectable({ factory: function FunnelEntryService_Factory() { return new FunnelEntryService(i0.inject(i1.HttpClient)); }, token: FunnelEntryService, providedIn: "root" });
    return FunnelEntryService;
}());
exports.FunnelEntryService = FunnelEntryService;
