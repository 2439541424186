"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultAbsBase = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultAbsBase, _super);
    function CmsEntryResultAbsBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CmsEntryResultAbsBase.prototype.isVerifiedToViewPartC = function (user, orgId) {
        return user.isAdmin || (user.orgId === orgId && user.isOrgSuperuser);
    };
    CmsEntryResultAbsBase = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultAbsBase);
    return CmsEntryResultAbsBase;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultAbsBase = CmsEntryResultAbsBase;
