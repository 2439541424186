"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./pagination.component");
var styles_PaginationComponent = [];
var RenderType_PaginationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginationComponent, data: {} });
exports.RenderType_PaginationComponent = RenderType_PaginationComponent;
function View_PaginationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPage(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "javascript:void(0);"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["First"]))], null, null); }
function View_PaginationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], [[2, "active", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.page == _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_PaginationComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPage(_co.pageLast) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "javascript:void(0);"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Last"]))], null, null); }
function View_PaginationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "ul", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "li", [["class.disabled", "page == 1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["href", "javascript:void(0);"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00AB"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_3)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "li", [], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["href", "javascript:void(0);"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00BB"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_4)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showFirst; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.pages; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.showLast; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.page == _co.pageLast); _ck(_v, 8, 0, currVal_2); }); }
function View_PaginationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pages.length; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_PaginationComponent_0 = View_PaginationComponent_0;
function View_PaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rd-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i0.ɵdid(1, 573440, null, 0, i2.PaginationComponent, [], null, null)], null, null); }
exports.View_PaginationComponent_Host_0 = View_PaginationComponent_Host_0;
var PaginationComponentNgFactory = i0.ɵccf("rd-pagination", i2.PaginationComponent, View_PaginationComponent_Host_0, { page: "page", perPage: "perPage", numItems: "numItems" }, { onPageChanged: "onPageChanged" }, []);
exports.PaginationComponentNgFactory = PaginationComponentNgFactory;
