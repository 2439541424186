"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var html2canvas = require("html2canvas");
var SaveScreenshotComponent = /** @class */ (function () {
    function SaveScreenshotComponent() {
    }
    SaveScreenshotComponent.prototype.ngOnInit = function () { };
    SaveScreenshotComponent.prototype.save = function () {
        var _this = this;
        var saveData = (function () {
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            return function (blob, fileName) {
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            };
        })();
        var nativeElement = this.download instanceof core_1.ElementRef ? this.download.nativeElement : this.download;
        html2canvas(nativeElement).then(function (canvas) {
            canvas.toBlob(function (blob) {
                saveData(blob, _this.filename);
            });
        });
    };
    return SaveScreenshotComponent;
}());
exports.SaveScreenshotComponent = SaveScreenshotComponent;
