"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./control-errors.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../core/alert/alert.component.ngfactory");
var i3 = require("../../../core/alert/alert.component");
var i4 = require("@angular/common");
var i5 = require("../../../core/currency.pipe");
var i6 = require("./control-errors.component");
var styles_ControlErrorsComponent = [i0.styles];
var RenderType_ControlErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ControlErrorsComponent, data: {} });
exports.RenderType_ControlErrorsComponent = RenderType_ControlErrorsComponent;
function View_ControlErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "rd-alert", [["type", "danger"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(-1, 0, [" This field is required.\n"]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ControlErrorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "rd-alert", [["type", "danger"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(2, 0, [" The max length for this field is ", ".\n"]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.control.errors.maxlength.requiredLength; _ck(_v, 2, 0, currVal_1); }); }
function View_ControlErrorsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "rd-alert", [["type", "danger"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(-1, 0, [" Please enter a proper decimal value. You cannot end the value with a period. Please ensure that you've removed the period or added the proper decimal digits. i.e. 1. is invalid, but 1.0 or 1 is valid. "]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ControlErrorsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlErrorsComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.touched; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ControlErrorsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "rd-alert", [["type", "danger"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(-1, 0, [" Please only enter numbers into this field (commas and decimals are acceptable, but will be rounded). i.e. 12,345.67 will be rounded to 12,346. "]))], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ControlErrorsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlErrorsComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["defaultPattern", 2]], null, 0, null, View_ControlErrorsComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.control.errors.pattern.requiredPattern == "/^\\d+(?:\\.\\d{1,2})?$/"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ControlErrorsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "rd-alert", [["type", "danger"]], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(2, 0, [" The max value for this field is ", ".\n"])), i1.ɵppd(3, 1)], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.control.errors.max.max)); _ck(_v, 2, 0, currVal_1); }); }
function View_ControlErrorsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.CurrencyPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlErrorsComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlErrorsComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlErrorsComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlErrorsComponent_7)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.control.touched && ((_co.control.errors == null) ? null : _co.control.errors.required)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.control.errors == null) ? null : _co.control.errors.maxlength); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.control.errors == null) ? null : _co.control.errors.pattern); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.control.errors == null) ? null : _co.control.errors.max); _ck(_v, 8, 0, currVal_3); }, null); }
exports.View_ControlErrorsComponent_0 = View_ControlErrorsComponent_0;
function View_ControlErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rd-control-errors", [], null, null, null, View_ControlErrorsComponent_0, RenderType_ControlErrorsComponent)), i1.ɵdid(1, 114688, null, 0, i6.ControlErrorsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ControlErrorsComponent_Host_0 = View_ControlErrorsComponent_Host_0;
var ControlErrorsComponentNgFactory = i1.ɵccf("rd-control-errors", i6.ControlErrorsComponent, View_ControlErrorsComponent_Host_0, { control: "control" }, {}, []);
exports.ControlErrorsComponentNgFactory = ControlErrorsComponentNgFactory;
