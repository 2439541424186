"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./alert.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./alert.component");
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
exports.RenderType_AlertComponent = RenderType_AlertComponent;
function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "alert alert--", _co.type, ""); _ck(_v, 0, 0, currVal_0); }); }
exports.View_AlertComponent_0 = View_AlertComponent_0;
function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rd-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AlertComponent_Host_0 = View_AlertComponent_Host_0;
var AlertComponentNgFactory = i1.ɵccf("rd-alert", i2.AlertComponent, View_AlertComponent_Host_0, { type: "type" }, {}, ["*"]);
exports.AlertComponentNgFactory = AlertComponentNgFactory;
