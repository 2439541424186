"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var account_1 = require("@app/account/account");
var abs_submit_part_helper_1 = require("./abs-submit-part-helper");
var currency_input_directive_1 = require("@app/core/currency-input.directive");
var CmsEntryAbsSubmitPartAComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryAbsSubmitPartAComponent, _super);
    function CmsEntryAbsSubmitPartAComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fringeAllowance = 0.3;
        return _this;
    }
    CmsEntryAbsSubmitPartAComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;
        var currencyValidators = [forms_1.Validators.max(8000000), forms_1.Validators.maxLength(9), forms_1.Validators.pattern(/^[0-9]*$/)];
        this.form = this.formBuilder.group({
            a_prof_salaries: [this.cmsResult.a_prof_salaries, currencyValidators],
            a_support_salaries: [this.cmsResult.a_support_salaries, currencyValidators],
            a_fringe_benefit: { value: this.cmsResult.a_fringe_benefit, disabled: true },
            a_student_wages: [this.cmsResult.a_student_wages, currencyValidators],
            a_salary_subtotal: { value: this.cmsResult.a_salary_subtotal, disabled: true },
            a_advertising: [this.cmsResult.a_advertising, currencyValidators],
            a_publications: [this.cmsResult.a_publications, currencyValidators],
            a_elec_media: [this.cmsResult.a_elec_media, currencyValidators],
            a_direct_mail: [this.cmsResult.a_direct_mail, currencyValidators],
            a_travel: [this.cmsResult.a_travel, currencyValidators],
            a_oncampus_ent: [this.cmsResult.a_oncampus_ent, currencyValidators],
            a_phone: [this.cmsResult.a_phone, currencyValidators],
            a_postage: [this.cmsResult.a_postage, currencyValidators],
            a_office_supplies: [this.cmsResult.a_office_supplies, currencyValidators],
            a_outside_assistance: [this.cmsResult.a_outside_assistance, currencyValidators],
            a_athletics_recruiting: [this.cmsResult.a_athletics_recruiting, currencyValidators],
            a_crm: [this.cmsResult.a_crm, currencyValidators],
            a_professional_development: [this.cmsResult.a_professional_development, currencyValidators],
            a_other_budget: [this.cmsResult.a_other_budget, currencyValidators],
            a_other_breakdown: [this.cmsResult.a_other_breakdown],
            a_nonsalary_subtotal: {
                value: this.cmsResult.a_nonsalary_subtotal,
                disabled: true,
                validators: [forms_1.Validators.max(8000000)]
            },
            a_grand_total: { value: this.cmsResult.a_grand_total, disabled: true, validators: [forms_1.Validators.max(16777215)] },
            // Part C Items
            c_pro_staff_avg_sal: { value: this.cmsResult.c_pro_staff_avg_sal, disabled: true },
            c_support_staff_avg_sal: { value: this.cmsResult.c_support_staff_avg_sal, disabled: true },
            c_stud_labor_avg_wage: { value: '', disabled: true }
        });
        if (!this.hasWritePermission()) {
            this.form.disable();
        }
        this.updateTotals('a_fringe_benefit', ['a_prof_salaries', 'a_support_salaries'], function (total) {
            return Math.round(total * _this.fringeAllowance);
        });
        this.updateTotals('a_salary_subtotal', [
            'a_prof_salaries',
            'a_support_salaries',
            'a_fringe_benefit',
            'a_student_wages'
        ]);
        this.updateTotals('a_nonsalary_subtotal', [
            'a_advertising',
            'a_publications',
            'a_elec_media',
            'a_direct_mail',
            'a_travel',
            'a_oncampus_ent',
            'a_phone',
            'a_postage',
            'a_office_supplies',
            'a_other_budget',
            'a_outside_assistance',
            'a_athletics_recruiting',
            'a_crm',
            'a_professional_development'
        ]);
        this.updateTotals('a_grand_total', ['a_salary_subtotal', 'a_nonsalary_subtotal']);
        // Update Part C items that are based on Part A items
        this.updateTotals('c_pro_staff_avg_sal', ['a_prof_salaries'], function (totals) {
            if (!_this.cmsResult.c_pro_staff) {
                return 0;
            }
            return Math.round(+_this.form.controls.a_prof_salaries.value / parseFloat(_this.cmsResult.c_pro_staff));
        });
        this.updateTotals('c_support_staff_avg_sal', ['a_support_salaries'], function (totals) {
            if (!_this.cmsResult.c_support_staff) {
                return 0;
            }
            totals = Math.round(+_this.form.controls.a_support_salaries.value / parseFloat(_this.cmsResult.c_support_staff));
            return isNaN(totals) ? 0 : totals;
        });
        this.updateTotals('c_stud_labor_avg_wage', ['a_student_wages'], function (totals) {
            if (!_this.cmsResult.c_stud_labor) {
                return 0;
            }
            totals = +_this.form.controls.a_student_wages.value / (30 * parseFloat(_this.cmsResult.c_stud_labor));
            return isNaN(totals) ? 0 : totals;
        });
        this.listenForFormChanges();
    };
    CmsEntryAbsSubmitPartAComponent.prototype.updateTotals = function (updateField, fields, tweakTotal) {
        var _this = this;
        if (tweakTotal === void 0) { tweakTotal = null; }
        var _updateTotals = function () {
            var total = 0;
            fields.forEach(function (field) {
                total += currency_input_directive_1.parseCurrency(_this.form.controls[field].value || 0);
            });
            if (tweakTotal) {
                total = tweakTotal(total);
            }
            else {
                total = Math.round(total);
            }
            _this.form.controls[updateField].setValue(total);
        };
        fields.forEach(function (field) {
            _this.form.controls[field].valueChanges.subscribe(_updateTotals);
        });
        _updateTotals(); // trigger an update just in case legacy data wasn't saved properly
    };
    CmsEntryAbsSubmitPartAComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, entryId, result, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.form.invalid) {
                            this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
                            return [2 /*return*/];
                        }
                        if (this.isSubmitting) {
                            return [2 /*return*/];
                        }
                        data = this.form.getRawValue();
                        if (!this.sessionUser.isAdmin) {
                            data.status = 0; // status should always be 0 unless an admin is saving, in which case we keep the status unchanged
                        }
                        data.naccap_abs_surveys_id = this.cmsResult.report.id;
                        data.naccap_orgs_id = this.cmsResult.orgId || this.sessionUser.orgId;
                        data.user_id = this.sessionUser.userId;
                        data.a_last_user = this.sessionUser.userId;
                        data.a_last_update = new Date();
                        entryId = this.cmsResult.entryId;
                        if (entryId) {
                            data.id = entryId;
                        }
                        this.isSubmitting = true;
                        this.error = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!data.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.absEntryService.update(data)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.absEntryService.create(data)];
                    case 4:
                        result = _a.sent();
                        this.form.patchValue({ id: result.insertId });
                        _a.label = 5;
                    case 5:
                        this.isSaved = true;
                        this.onSaveSuccess();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        this.error = err_1.error.error;
                        return [3 /*break*/, 7];
                    case 7:
                        this.isSubmitting = false;
                        this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
                        setTimeout(function () {
                            var url = lodash_1.get(_this.cmsResult.urls, 'abs-submit-part-b');
                            _this.router.navigate([url], { queryParamsHandling: 'merge' });
                        }, 2000);
                        return [2 /*return*/];
                }
            });
        });
    };
    return CmsEntryAbsSubmitPartAComponent;
}(abs_submit_part_helper_1.AbsSubmitPartHelper));
exports.CmsEntryAbsSubmitPartAComponent = CmsEntryAbsSubmitPartAComponent;
