"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var org_service_1 = require("@app/org/org.service");
var AdminAbsSummaryFiltersComponent = /** @class */ (function () {
    function AdminAbsSummaryFiltersComponent(orgService) {
        this.orgService = orgService;
        this.update = new core_1.EventEmitter();
        this.form = {
            org_id: null,
            comparison_schools: null
        };
    }
    AdminAbsSummaryFiltersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgService.listAll().then(function (data) { return (_this.orgs = data.items); });
    };
    AdminAbsSummaryFiltersComponent.prototype.ngOnChanges = function (changes) {
        if (changes.defaults) {
            this.initDefaults();
        }
    };
    AdminAbsSummaryFiltersComponent.prototype.initDefaults = function () {
        this.form.org_id = this.defaults.org_id || undefined;
        this.form.comparison_schools = this.defaults.comparison_schools || undefined;
    };
    AdminAbsSummaryFiltersComponent.prototype.onOrgChanged = function () {
        var _this = this;
        this.orgService.getOrgWatchlist(this.form.org_id).then(function (data) { return (_this.form.comparison_schools = data); });
    };
    AdminAbsSummaryFiltersComponent.prototype.sendUpdate = function () {
        this.update.emit(this.form);
    };
    AdminAbsSummaryFiltersComponent.prototype.reset = function () {
        this.form = {
            org_id: null,
            comparison_schools: null
        };
        this.sendUpdate();
    };
    return AdminAbsSummaryFiltersComponent;
}());
exports.AdminAbsSummaryFiltersComponent = AdminAbsSummaryFiltersComponent;
