"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AbsSubmitSaveMessagesComponent = /** @class */ (function () {
    function AbsSubmitSaveMessagesComponent() {
        this.show = false;
    }
    AbsSubmitSaveMessagesComponent.prototype.ngOnInit = function () { };
    return AbsSubmitSaveMessagesComponent;
}());
exports.AbsSubmitSaveMessagesComponent = AbsSubmitSaveMessagesComponent;
