"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var cms_entry_result_admin_abs_tool_1 = require("@app/cms/results/cms-entry-result-admin-abs-tool");
var AdminAbsTableComponent = /** @class */ (function () {
    function AdminAbsTableComponent() {
        this.fieldFormat = 'currency';
        this.totalLabel = 'Total Increase';
        this.removeMargin = false;
        this.isSmall = false;
    }
    AdminAbsTableComponent.prototype.ngOnInit = function () { };
    AdminAbsTableComponent.prototype.getIncreaseOverPreviousYear = function (field, year) {
        var previousEntry = lodash_1.find(this.cmsResult.averages, function (result) { return result.year == year - 1; });
        if (!previousEntry) {
            return undefined;
        }
        var currentEntry = lodash_1.find(this.cmsResult.averages, function (result) { return result.year == year; });
        if (!currentEntry) {
            return undefined;
        }
        return currentEntry.data[0][field] / previousEntry.data[0][field] - 1;
    };
    AdminAbsTableComponent.prototype.getIncreaseTotal = function (field) {
        var mostRecentValue = lodash_1.get(this.cmsResult.averages, "0.data[0]." + field);
        var firstValue = lodash_1.get(this.cmsResult.averages, this.cmsResult.averages.length - 1 + ".data[0]." + field);
        return mostRecentValue / firstValue - 1;
    };
    return AdminAbsTableComponent;
}());
exports.AdminAbsTableComponent = AdminAbsTableComponent;
var Type;
(function (Type) {
    Type["split"] = "split";
    Type["singleYearSplit"] = "single-year-split";
    Type["range"] = "range";
    Type["fields"] = "fields";
})(Type || (Type = {}));
