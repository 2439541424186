"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("@env/environment");
var SessionHttpInterceptor = /** @class */ (function () {
    function SessionHttpInterceptor() {
    }
    SessionHttpInterceptor.prototype.intercept = function (req, next) {
        if (req.method === 'GET' && environment_1.environment.production) {
            req = req.clone({
                headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache')
            });
        }
        if (environment_1.environment.production) {
            return next.handle(req);
        }
        // This allows for cookies/sessions to be kept over multiple domains.
        req = req.clone({
            withCredentials: true
        });
        return next.handle(req);
    };
    return SessionHttpInterceptor;
}());
exports.SessionHttpInterceptor = SessionHttpInterceptor;
