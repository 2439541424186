import { deserialize, inheritSerialization } from 'cerialize';
import { set, get, find } from 'lodash';

import { CmsEntryResultAbsBase } from './cms-entry-result-abs-base';

@inheritSerialization(CmsEntryResultAbsBase)
export class CmsEntryResultAbsSubmit extends CmsEntryResultAbsBase {
	@deserialize
	public org: any;

	@deserialize
	public report: any;

	@deserialize
	public reportEntry: any;

	@deserialize
	public reportEntryUser: any;

	@deserialize
	public sessionUserReportEntry: any;

	@deserialize
	public previousReportEntry: any;

	public activeInput: string;

	getLabel(field: string): string {
		return get(this.getField(field), 'label.value', '').replace('[[year]]', this.year);
	}

	getHelpText(field: string): string {
		return get(this.getField(field), 'help.value', '').replace('[[year]]', this.year);
	}

	getField(field: string): any {
		return find(get(this.entry, 'entries.field'), cms_field => get(cms_field, 'key.value') === field);
	}

	getDegreeLabel(field: string): string {
		return get(this.reportEntry, `degrees.${field}`);
	}

	getPreviousDegreeLabel(field: string): string {
		return get(this.previousReportEntry, `degrees.${field}`);
	}

	get surveyId(): number {
		return get(this.report, 'id');
	}

	get instructions(): string {
		return get(this.entry, 'entries.instructions[0].value');
	}

	get introduction(): string {
		return get(this.entry, 'entries.introduction[0].value');
	}

	get staff1(): string {
		return get(this.entry, 'entries.staff[0].field_1.value');
	}
	get staff2(): string {
		return get(this.entry, 'entries.staff[0].field_2.value');
	}

	get salary(): string {
		return get(this.entry, 'entries.salary[0].value');
	}

	get car(): string {
		return get(this.entry, 'entries.car[0].value');
	}

	get qualifications(): string {
		return get(this.entry, 'entries.qualifications[0].value');
	}

	get inquiries(): string {
		return get(this.entry, 'entries.inquiries[0].value');
	}

	get applications(): string {
		return get(this.entry, 'entries.applications[0].value');
	}

	get completedApplications(): string {
		return get(this.entry, 'entries.completed_applications[0].value');
	}

	get admitted(): string {
		return get(this.entry, 'entries.admitted[0].value');
	}

	get enrolled(): string {
		return get(this.entry, 'entries.enrolled[0].value');
	}

	isUserReportValidated(): boolean {
		return get(this.sessionUserReportEntry, 'status') == 2;
	}

	get isOpen(): boolean {
		return get(this.report, 'isOpen');
	}

	get year(): number {
		return get(this.report, 'year');
	}

	// Entry data
	get existingStatus(): number {
		return get(this.reportEntry, 'status', 0);
	}

	set existingStatus(status: number) {
		set(this.reportEntry, 'status', status);
	}

	get entryId(): number {
		return get(this.reportEntry, 'id', '');
	}

	get orgId(): number {
		return get(this.org, 'id');
	}

	get naccap_abs_surveys_id(): string {
		return get(this.reportEntry, 'naccap_abs_surveys_id');
	}
	get naccap_orgs_id(): string {
		return get(this.reportEntry, 'naccap_orgs_id');
	}
	get user_id(): string {
		return get(this.reportEntry, 'user_id');
	}
	get a_prof_salaries(): string {
		return get(this.reportEntry, 'a_prof_salaries');
	}
	get a_support_salaries(): string {
		return get(this.reportEntry, 'a_support_salaries');
	}
	get a_fringe_benefit(): string {
		return get(this.reportEntry, 'a_fringe_benefit');
	}
	get a_student_wages(): string {
		return get(this.reportEntry, 'a_student_wages');
	}
	get a_salary_subtotal(): string {
		return get(this.reportEntry, 'a_salary_subtotal');
	}
	get a_advertising(): string {
		return get(this.reportEntry, 'a_advertising');
	}
	get a_publications(): string {
		return get(this.reportEntry, 'a_publications');
	}
	get a_elec_media(): string {
		return get(this.reportEntry, 'a_elec_media');
	}
	get a_direct_mail(): string {
		return get(this.reportEntry, 'a_direct_mail');
	}
	get a_travel(): string {
		return get(this.reportEntry, 'a_travel');
	}
	get a_oncampus_ent(): string {
		return get(this.reportEntry, 'a_oncampus_ent');
	}
	get a_phone(): string {
		return get(this.reportEntry, 'a_phone');
	}
	get a_postage(): string {
		return get(this.reportEntry, 'a_postage');
	}
	get a_ph_post_note(): string {
		return get(this.reportEntry, 'a_ph_post_note');
	}
	get a_outside_assistance(): string {
		return get(this.reportEntry, 'a_outside_assistance');
	}
	get a_athletics_recruiting(): string {
		return get(this.reportEntry, 'a_athletics_recruiting');
	}
	get a_crm(): string {
		return get(this.reportEntry, 'a_crm');
	}
	get a_professional_development(): string {
		return get(this.reportEntry, 'a_professional_development');
	}
	get a_office_supplies(): string {
		return get(this.reportEntry, 'a_office_supplies');
	}
	get a_other_budget(): string {
		return get(this.reportEntry, 'a_other_budget');
	}
	get a_other_breakdown(): string {
		return get(this.reportEntry, 'a_other_breakdown');
	}
	get a_nonsalary_subtotal(): string {
		return get(this.reportEntry, 'a_nonsalary_subtotal');
	}
	get a_grand_total(): string {
		return get(this.reportEntry, 'a_grand_total');
	}
	get a_comments(): string {
		return get(this.reportEntry, 'a_comments');
	}
	get a_last_user(): string {
		return get(this.reportEntry, 'a_last_user');
	}
	get a_last_update(): string {
		return get(this.reportEntry, 'a_last_update');
	}
	get b_total_enr(): string {
		return get(this.reportEntry, 'b_total_enr');
	}
	get b_ftns_fall(): string {
		return get(this.reportEntry, 'b_ftns_fall');
	}
	get b_ftns_spring(): string {
		return get(this.reportEntry, 'b_ftns_spring');
	}
	get b_ftns_total(): string {
		return get(this.reportEntry, 'b_ftns_total');
	}
	get b_males(): string {
		return get(this.reportEntry, 'b_males');
	}
	get b_females(): string {
		return get(this.reportEntry, 'b_females');
	}
	get b_mf_ratio(): string {
		return get(this.reportEntry, 'b_mf_ratio');
	}
	get b_recruited_athletes(): string {
		return get(this.reportEntry, 'b_recruited_athletes');
	}
	get b_comments(): string {
		return get(this.reportEntry, 'b_comments');
	}
	get b_last_user(): string {
		return get(this.reportEntry, 'b_last_user');
	}
	get b_last_update(): string {
		return get(this.reportEntry, 'b_last_update');
	}
	get c_pro_staff(): string {
		return get(this.reportEntry, 'c_pro_staff');
	}
	get c_pro_staff_avg_sal(): string {
		return get(this.reportEntry, 'c_pro_staff_avg_sal');
	}
	get c_pro_staff_direct_recruiting(): string {
		return get(this.reportEntry, 'c_pro_staff_direct_recruiting');
	}
	get c_support_staff(): string {
		return get(this.reportEntry, 'c_support_staff');
	}
	get c_support_staff_avg_sal(): string {
		return get(this.reportEntry, 'c_support_staff_avg_sal');
	}
	get c_stud_labor(): string {
		return get(this.reportEntry, 'c_stud_labor');
	}
	get c_adm_cnslr_start_sal(): string {
		return get(this.reportEntry, 'c_adm_cnslr_start_sal');
	}
	get c_sr_cnslr_pos(): number {
		return get(this.reportEntry, 'c_sr_cnslr_pos');
	}
	get c_sr_cnslr_sal(): string {
		return get(this.reportEntry, 'c_sr_cnslr_sal');
	}
	get c_asst_dir_sal(): string {
		return get(this.reportEntry, 'c_asst_dir_sal');
	}
	get c_asst_dir_yrs_adm(): string {
		return get(this.reportEntry, 'c_asst_dir_yrs_adm');
	}
	get c_asst_dir_yrs_pos(): string {
		return get(this.reportEntry, 'c_asst_dir_yrs_pos');
	}
	get c_assoc_dir_sal(): string {
		return get(this.reportEntry, 'c_assoc_dir_sal');
	}
	get c_assoc_dir_yrs_adm(): string {
		return get(this.reportEntry, 'c_assoc_dir_yrs_adm');
	}
	get c_assoc_dir_yrs_pos(): string {
		return get(this.reportEntry, 'c_assoc_dir_yrs_pos');
	}
	get c_dir_adm_sal(): string {
		return get(this.reportEntry, 'c_dir_adm_sal');
	}
	get c_dir_adm_yrs_adm(): string {
		return get(this.reportEntry, 'c_dir_adm_yrs_adm');
	}
	get c_dir_adm_yrs_pos(): string {
		return get(this.reportEntry, 'c_dir_adm_yrs_pos');
	}
	get c_vpdean_title(): string {
		return get(this.reportEntry, 'c_vpdean_title');
	}
	get c_vpdean_sal(): string {
		return get(this.reportEntry, 'c_vpdean_sal');
	}
	get c_vpdean_yrs_adm(): string {
		return get(this.reportEntry, 'c_vpdean_yrs_adm');
	}
	get c_vpdean_yrs_pos(): string {
		return get(this.reportEntry, 'c_vpdean_yrs_pos');
	}
	get c_car_adm_cnslr(): string {
		return get(this.reportEntry, 'c_car_adm_cnslr');
	}
	get c_car_other_staff(): string {
		return get(this.reportEntry, 'c_car_other_staff');
	}
	get c_car_others(): string {
		return get(this.reportEntry, 'c_car_others');
	}
	get c_min_edu_adm_cnslr(): string {
		return get(this.reportEntry, 'c_min_edu_adm_cnslr');
	}
	get c_min_edu_asst_dir(): string {
		return get(this.reportEntry, 'c_min_edu_asst_dir');
	}
	get c_min_edu_dir(): string {
		return get(this.reportEntry, 'c_min_edu_dir');
	}
	get c_min_edu_vpdean(): string {
		return get(this.reportEntry, 'c_min_edu_vpdean');
	}
	get c_comments(): string {
		return get(this.reportEntry, 'c_comments');
	}
	get c_last_user(): string {
		return get(this.reportEntry, 'c_last_user');
	}
	get c_last_update(): string {
		return get(this.reportEntry, 'c_last_update');
	}
	get d_inquired(): string {
		return get(this.reportEntry, 'd_inquired');
	}
	get d_applied(): string {
		return get(this.reportEntry, 'd_applied');
	}
	get d_completed_app(): string {
		return get(this.reportEntry, 'd_completed_app');
	}
	get d_accepted(): string {
		return get(this.reportEntry, 'd_accepted');
	}
	get d_enrolled(): string {
		return get(this.reportEntry, 'd_enrolled');
	}
	get d_comments(): string {
		return get(this.reportEntry, 'd_comments');
	}
	get d_last_user(): string {
		return get(this.reportEntry, 'd_last_user');
	}
	get d_last_update(): string {
		return get(this.reportEntry, 'd_last_update');
	}
	get status(): string {
		return get(this.reportEntry, 'status');
	}

	// previous year entries
	get previous_a_prof_salaries(): string {
		return get(this.previousReportEntry, 'a_prof_salaries');
	}
	get previous_a_support_salaries(): string {
		return get(this.previousReportEntry, 'a_support_salaries');
	}
	get previous_a_fringe_benefit(): string {
		return get(this.previousReportEntry, 'a_fringe_benefit');
	}
	get previous_a_student_wages(): string {
		return get(this.previousReportEntry, 'a_student_wages');
	}
	get previous_a_salary_subtotal(): string {
		return get(this.previousReportEntry, 'a_salary_subtotal');
	}
	get previous_a_advertising(): string {
		return get(this.previousReportEntry, 'a_advertising');
	}
	get previous_a_publications(): string {
		return get(this.previousReportEntry, 'a_publications');
	}
	get previous_a_elec_media(): string {
		return get(this.previousReportEntry, 'a_elec_media');
	}
	get previous_a_direct_mail(): string {
		return get(this.previousReportEntry, 'a_direct_mail');
	}
	get previous_a_travel(): string {
		return get(this.previousReportEntry, 'a_travel');
	}
	get previous_a_oncampus_ent(): string {
		return get(this.previousReportEntry, 'a_oncampus_ent');
	}
	get previous_a_phone(): string {
		return get(this.previousReportEntry, 'a_phone');
	}
	get previous_a_postage(): string {
		return get(this.previousReportEntry, 'a_postage');
	}
	get previous_a_ph_post_note(): string {
		return get(this.previousReportEntry, 'a_ph_post_note');
	}
	get previous_a_outside_assistance(): string {
		return get(this.previousReportEntry, 'a_outside_assistance');
	}
	get previous_a_athletics_recruiting(): string {
		return get(this.previousReportEntry, 'a_athletics_recruiting');
	}
	get previous_a_crm(): string {
		return get(this.previousReportEntry, 'a_crm');
	}
	get previous_a_professional_development(): string {
		return get(this.previousReportEntry, 'a_professional_development');
	}
	get previous_a_office_supplies(): string {
		return get(this.previousReportEntry, 'a_office_supplies');
	}
	get previous_a_other_budget(): string {
		return get(this.previousReportEntry, 'a_other_budget');
	}
	get previous_a_other_breakdown(): string {
		return get(this.previousReportEntry, 'a_other_breakdown');
	}
	get previous_a_nonsalary_subtotal(): string {
		return get(this.previousReportEntry, 'a_nonsalary_subtotal');
	}
	get previous_a_grand_total(): string {
		return get(this.previousReportEntry, 'a_grand_total');
	}
	get previous_a_comments(): string {
		return get(this.previousReportEntry, 'a_comments');
	}
	get previous_a_last_user(): string {
		return get(this.previousReportEntry, 'a_last_user');
	}
	get previous_a_last_update(): string {
		return get(this.previousReportEntry, 'a_last_update');
	}
	get previous_b_total_enr(): string {
		return get(this.previousReportEntry, 'b_total_enr');
	}
	get previous_b_ftns_fall(): string {
		return get(this.previousReportEntry, 'b_ftns_fall');
	}
	get previous_b_ftns_spring(): string {
		return get(this.previousReportEntry, 'b_ftns_spring');
	}
	get previous_b_ftns_total(): string {
		return get(this.previousReportEntry, 'b_ftns_total');
	}
	get previous_b_males(): string {
		return get(this.previousReportEntry, 'b_males');
	}
	get previous_b_females(): string {
		return get(this.previousReportEntry, 'b_females');
	}
	get previous_b_mf_ratio(): string {
		return get(this.previousReportEntry, 'b_mf_ratio');
	}
	get previous_b_comments(): string {
		return get(this.previousReportEntry, 'b_comments');
	}
	get previous_b_last_user(): string {
		return get(this.previousReportEntry, 'b_last_user');
	}
	get previous_b_last_update(): string {
		return get(this.previousReportEntry, 'b_last_update');
	}
	get previous_c_pro_staff(): string {
		return get(this.previousReportEntry, 'c_pro_staff');
	}
	get previous_c_pro_staff_avg_sal(): string {
		return get(this.previousReportEntry, 'c_pro_staff_avg_sal');
	}
	get previous_c_pro_staff_direct_recruiting(): string {
		return get(this.previousReportEntry, 'c_pro_staff_direct_recruiting');
	}
	get previous_c_support_staff(): string {
		return get(this.previousReportEntry, 'c_support_staff');
	}
	get previous_c_support_staff_avg_sal(): string {
		return get(this.previousReportEntry, 'c_support_staff_avg_sal');
	}
	get previous_c_stud_labor(): string {
		return get(this.previousReportEntry, 'c_stud_labor');
	}
	get previous_c_adm_cnslr_start_sal(): string {
		return get(this.previousReportEntry, 'c_adm_cnslr_start_sal');
	}
	get previous_c_sr_cnslr_pos(): string {
		return get(this.previousReportEntry, 'c_sr_cnslr_pos');
	}
	get previous_c_sr_cnslr_sal(): string {
		return get(this.previousReportEntry, 'c_sr_cnslr_sal');
	}
	get previous_c_asst_dir_sal(): string {
		return get(this.previousReportEntry, 'c_asst_dir_sal');
	}
	get previous_c_asst_dir_yrs_adm(): string {
		return get(this.previousReportEntry, 'c_asst_dir_yrs_adm');
	}
	get previous_c_asst_dir_yrs_pos(): string {
		return get(this.previousReportEntry, 'c_asst_dir_yrs_pos');
	}
	get previous_c_assoc_dir_sal(): string {
		return get(this.previousReportEntry, 'c_assoc_dir_sal');
	}
	get previous_c_assoc_dir_yrs_adm(): string {
		return get(this.previousReportEntry, 'c_assoc_dir_yrs_adm');
	}
	get previous_c_assoc_dir_yrs_pos(): string {
		return get(this.previousReportEntry, 'c_assoc_dir_yrs_pos');
	}
	get previous_c_dir_adm_sal(): string {
		return get(this.previousReportEntry, 'c_dir_adm_sal');
	}
	get previous_c_dir_adm_yrs_adm(): string {
		return get(this.previousReportEntry, 'c_dir_adm_yrs_adm');
	}
	get previous_c_dir_adm_yrs_pos(): string {
		return get(this.previousReportEntry, 'c_dir_adm_yrs_pos');
	}
	get previous_c_vpdean_title(): string {
		return get(this.previousReportEntry, 'c_vpdean_title');
	}
	get previous_c_vpdean_sal(): string {
		return get(this.previousReportEntry, 'c_vpdean_sal');
	}
	get previous_c_vpdean_yrs_adm(): string {
		return get(this.previousReportEntry, 'c_vpdean_yrs_adm');
	}
	get previous_c_vpdean_yrs_pos(): string {
		return get(this.previousReportEntry, 'c_vpdean_yrs_pos');
	}
	get previous_c_car_adm_cnslr(): string {
		return get(this.previousReportEntry, 'c_car_adm_cnslr');
	}
	get previous_c_car_other_staff(): string {
		return get(this.previousReportEntry, 'c_car_other_staff');
	}
	get previous_c_car_others(): string {
		return get(this.previousReportEntry, 'c_car_others');
	}
	get previous_c_min_edu_adm_cnslr(): string {
		return get(this.previousReportEntry, 'c_min_edu_adm_cnslr');
	}
	get previous_c_min_edu_asst_dir(): string {
		return get(this.previousReportEntry, 'c_min_edu_asst_dir');
	}
	get previous_c_min_edu_dir(): string {
		return get(this.previousReportEntry, 'c_min_edu_dir');
	}
	get previous_c_min_edu_vpdean(): string {
		return get(this.previousReportEntry, 'c_min_edu_vpdean');
	}
	get previous_c_comments(): string {
		return get(this.previousReportEntry, 'c_comments');
	}
	get previous_c_last_user(): string {
		return get(this.previousReportEntry, 'c_last_user');
	}
	get previous_c_last_update(): string {
		return get(this.previousReportEntry, 'c_last_update');
	}
	get previous_d_inquired(): string {
		return get(this.previousReportEntry, 'd_inquired');
	}
	get previous_d_applied(): string {
		return get(this.previousReportEntry, 'd_applied');
	}
	get previous_d_completed_app(): string {
		return get(this.previousReportEntry, 'd_completed_app');
	}
	get previous_d_accepted(): string {
		return get(this.previousReportEntry, 'd_accepted');
	}
	get previous_d_enrolled(): string {
		return get(this.previousReportEntry, 'd_enrolled');
	}
	get previous_d_comments(): string {
		return get(this.previousReportEntry, 'd_comments');
	}
	get previous_d_last_user(): string {
		return get(this.previousReportEntry, 'd_last_user');
	}
	get previous_d_last_update(): string {
		return get(this.previousReportEntry, 'd_last_update');
	}
	get previous_status(): string {
		return get(this.previousReportEntry, 'status');
	}
	get onHoldMessages(): any {
		return get(this.reportEntry, 'on_hold_messages', []);
	}
}
