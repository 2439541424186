"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultFunnelReport = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultFunnelReport, _super);
    function CmsEntryResultFunnelReport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.reportEntry = {};
        _this.sessionUserReportEntry = {};
        return _this;
    }
    CmsEntryResultFunnelReport.prototype.isUserReportValidated = function () {
        return lodash_1.get(this.sessionUserReportEntry, 'status') == 2;
    };
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "existingStatus", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'status', 0);
        },
        set: function (status) {
            lodash_1.set(this.reportEntry, 'status', status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "isOpen", {
        get: function () {
            return lodash_1.get(this.report, 'isOpen');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "entryId", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'id', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "reportId", {
        get: function () {
            return lodash_1.get(this.report, 'id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "orgName", {
        get: function () {
            return lodash_1.get(this.org, 'org');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "orgId", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'naccap_orgs_id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_app_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_app_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_app_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_app_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_adm_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_adm_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_adm_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_adm_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_dep_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_dep_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_dep_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_dep_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_dep_canceled_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_dep_canceled_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_dep_canceled_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_dep_canceled_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_app_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_app_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_app_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_app_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_adm_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_adm_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_adm_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_adm_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_dep_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_dep_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_dep_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_dep_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_dep_canceled_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_dep_canceled_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_dep_canceled_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_dep_canceled_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_app_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_app_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_app_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_app_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_adm_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_adm_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_adm_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_adm_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_dep_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_dep_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_dep_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_dep_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_dep_canceled_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_dep_canceled_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_dep_canceled_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_dep_canceled_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "incomplete_explanation", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'incomplete_explanation', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "curr_goal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'curr_goal', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "prev_actual", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'prev_actual', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_enr_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_enr_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "fr_enr_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'fr_enr_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_enr_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_enr_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tr_enr_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tr_enr_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_enr_curr", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_enr_curr', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "tns_enr_prev", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'tns_enr_prev', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "original_goal", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'original_goal', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryResultFunnelReport.prototype, "onHoldMessages", {
        get: function () {
            return lodash_1.get(this.reportEntry, 'on_hold_messages', []);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelReport.prototype, "org", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelReport.prototype, "report", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelReport.prototype, "reportEntry", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelReport.prototype, "sessionUserReportEntry", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Boolean)
    ], CmsEntryResultFunnelReport.prototype, "isPrepopulated", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Boolean)
    ], CmsEntryResultFunnelReport.prototype, "hasWritePermission", void 0);
    CmsEntryResultFunnelReport = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultFunnelReport);
    return CmsEntryResultFunnelReport;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultFunnelReport = CmsEntryResultFunnelReport;
