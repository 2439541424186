"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultAbsLanding = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultAbsLanding, _super);
    function CmsEntryResultAbsLanding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsLanding.prototype, "completedAbsReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsLanding.prototype, "verifiedAbsReportsForUser", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAbsLanding.prototype, "report", void 0);
    CmsEntryResultAbsLanding = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultAbsLanding);
    return CmsEntryResultAbsLanding;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultAbsLanding = CmsEntryResultAbsLanding;
