<p><a *ngIf="isEntryVerified" (click)="export()" class="btn btn--gold float--right">Export report</a></p>
<h2>Comparison Summary</h2>
<h3>{{ cmsResult.org.org }}</h3>

<rd-alert type="warning" *ngIf="cmsResult.org.enrollmentLabel == 'Unknown'">
	<h2>Note:</h2>
	<p>Data for schools of your enrollment size is not presented because you did not submit data for the ABS and we do not have your size on record. To correct this, please send your current total full time enrollment to <a href="mailto:tim@fullerhes.com">Tim Fuller</a>.</p>
</rd-alert>

<div class="grid__row">
	<div [class.grid__col--8]="sessionUser.isAdmin" [class.grid__col--12]="!sessionUser.isAdmin">
		<rd-funnel-report-filter
			[isAbs]="true"
			[reports]="cmsResult.completedAbsReports"
			[includeSearch]="false"
			[defaults]="{ reportId: pageFilters.survey_id || cmsResult.report.id }"
			(update)="onReportUpdate($event)"
		></rd-funnel-report-filter>

		<ng-container *ngIf="isEntryVerified">
			<div class="table--responsive">
				<table class="table table--striped" id="abs-summary-first">
					<thead>
						<tr>
							<th>Average Budget Dollars</th>
							<th *ngIf="isEntryVerified">My School</th>
							<th>
								<span *ngIf="isAdminBuild">School Comparison</span>
								<span *ngIf="!isAdminBuild">My Watchlist</span>
							</th>
							<th>{{ cmsResult.org.region }}</th>
							<th>{{ cmsResult.org.enrollmentLabel }} Enrollment Size</th>
							<th>{{ cmsResult.org.type }}</th>
							<th>Overall Average</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let field of budgetFieldsMarketing">
							<td class="th-header" [innerHtml]="field.name"></td>
							<td *ngIf="isEntryVerified" class="data">
								<span *ngIf="cmsResult.orgAverages[field.field]">${{ cmsResult.orgAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.orgAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.watchlistAverages[field.field]">${{ cmsResult.watchlistAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.watchlistAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.regionAverages[field.field]">${{ cmsResult.regionAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.regionAverages[field.field]">No Data</span>
							</td>
							<td class="data {{ cmsResult.org.enrollmentLabel }}">
								<span *ngIf="cmsResult.enrollmentAverages[field.field]">${{ cmsResult.enrollmentAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.enrollmentAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.typeAverages[field.field]">${{ cmsResult.typeAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.typeAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.nationalAverages[field.field]">${{ cmsResult.nationalAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.nationalAverages[field.field]">No Data</span>
							</td>
						</tr>
						<tr class="subtotal">
							<td class="th-header level1">Marketing Subtotal<br />(sum of above categories)</td>
							<td *ngIf="isEntryVerified" class="data">${{ getMarketingSubtotal('org') | number:'0.2-2' }}</td>
							<td>${{ getMarketingSubtotal('watchlist') | number:'0.2-2' }}</td>
							<td>${{ (getMarketingSubtotal('region')) | number:'0.2-2' }}</td>
							<td class="data {{ cmsResult.org.enrollmentLabel }}">${{ (getMarketingSubtotal('enrollment')) | number:'0.2-2' }}</td>
							<td>${{ (getMarketingSubtotal('type')) | number:'0.2-2' }}</td>
							<td>${{ (getMarketingSubtotal('national')) | number:'0.2-2' }}</td>
						</tr>
						<tr *ngFor="let field of budgetFieldsOther">
							<td class="th-header" [innerHtml]="field.name"></td>
							<td *ngIf="isEntryVerified" class="data">
								<span *ngIf="cmsResult.orgAverages[field.field]">${{ cmsResult.orgAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.orgAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.watchlistAverages[field.field]">${{ cmsResult.watchlistAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.watchlistAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.regionAverages[field.field]">${{ cmsResult.regionAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.regionAverages[field.field]">No Data</span>
							</td>
							<td class="data {{ cmsResult.org.enrollmentLabel }}">
								<span *ngIf="cmsResult.enrollmentAverages[field.field]">${{ cmsResult.enrollmentAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.enrollmentAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.typeAverages[field.field]">${{ cmsResult.typeAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.typeAverages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.nationalAverages[field.field]">${{ cmsResult.nationalAverages[field.field] | number:'0.2-2' }}</span>
								<span *ngIf="!cmsResult.nationalAverages[field.field]">No Data</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="table--responsive">
				<table class="table table--striped">
					<thead>
						<tr>
							<th>Average % Of Total Budget</th>
							<th *ngIf="isEntryVerified">My School</th>
							<th>
								<span *ngIf="isAdminBuild">School Comparison</span>
								<span *ngIf="!isAdminBuild">My Watchlist</span>
							</th>
							<th>{{ cmsResult.org.region }}</th>
							<th>{{ cmsResult.org.enrollmentLabel }} Enrollment Size</th>
							<th>{{ cmsResult.org.type }}</th>
							<th>Overall Average</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let field of budgetFieldsMarketing">
							<td class="th-header" [innerHtml]="field.name"></td>
							<td *ngIf="isEntryVerified" class="data">
								<span *ngIf="cmsResult.orgPercentages[field.field]">{{ cmsResult.orgPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.orgPercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.watchlistPercentages[field.field]">{{ cmsResult.watchlistPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.watchlistPercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.regionPercentages[field.field]">{{ cmsResult.regionPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.regionPercentages[field.field]">No Data</span>
							</td>
							<td class="data {{ cmsResult.org.enrollmentLabel }}">
								<span *ngIf="cmsResult.enrollmentPercentages[field.field]">{{ cmsResult.enrollmentPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.enrollmentPercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.typePercentages[field.field]">{{ cmsResult.typePercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.typePercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.nationalPercentages[field.field]">{{ cmsResult.nationalPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.nationalPercentages[field.field]">No Data</span>
							</td>
						</tr>
						<tr class="subtotal">
							<td class="th-header level1">Marketing Subtotal<br />(sum of above categories)</td>
							<td *ngIf="isEntryVerified" class="data">{{ getMarketingPercentageSubtotal('org') * 100 | number:'0.2-2' }}%</td>
							<td>{{ getMarketingPercentageSubtotal('watchlist') * 100 | number:'0.2-2' }}%</td>
							<td>{{ (getMarketingPercentageSubtotal('region')) * 100 | number:'0.2-2' }}%</td>
							<td class="data {{ cmsResult.org.enrollmentLabel }}">{{ (getMarketingPercentageSubtotal('enrollment')) * 100 | number:'0.2-2' }}%</td>
							<td>{{ (getMarketingPercentageSubtotal('type')) * 100 | number:'0.2-2' }}%</td>
							<td>{{ (getMarketingPercentageSubtotal('national')) * 100 | number:'0.2-2' }}%</td>
						</tr>
						<tr *ngFor="let field of percentageFields2">
							<td class="th-header" [innerHtml]="field.name"></td>
							<td *ngIf="isEntryVerified" class="data">
								<span *ngIf="cmsResult.orgPercentages[field.field]">{{ cmsResult.orgPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.orgPercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.watchlistPercentages[field.field]">{{ cmsResult.watchlistPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.watchlistPercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.regionPercentages[field.field]">{{ cmsResult.regionPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.regionPercentages[field.field]">No Data</span>
							</td>
							<td class="data {{ cmsResult.org.enrollmentLabel }}">
								<span *ngIf="cmsResult.enrollmentPercentages[field.field]">{{ cmsResult.enrollmentPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.enrollmentPercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.typePercentages[field.field]">{{ cmsResult.typePercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.typePercentages[field.field]">No Data</span>
							</td>
							<td>
								<span *ngIf="cmsResult.nationalPercentages[field.field]">{{ cmsResult.nationalPercentages[field.field] * 100 | number:'0.2-2' }}%</span>
								<span *ngIf="!cmsResult.nationalPercentages[field.field]">No Data</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</ng-container>
	</div>
	<div *ngIf="sessionUser.isAdmin" class="grid__col--4 grid__col-offset-sidebar" id="admin">
		<rd-admin-abs-summary-filters (update)="onFilterUpdate($event)" [defaults]="pageFilters"></rd-admin-abs-summary-filters>
	</div>
</div>

<ng-container *ngIf="isEntryVerified">
	<h3>Percentage of Total Budget, All Respondents</h3>
	<rd-chart [id]="chartId" [base]="percentageChartData()" type="pie"></rd-chart>


	<div class="table--responsive">
		<table class="table table--striped">
			<thead>
				<tr>
					<th>Enrollment, Staffing, & Ratios</th>
					<th *ngIf="isEntryVerified">My School</th>
					<th>
						<span *ngIf="isAdminBuild">School Comparison</span>
						<span *ngIf="!isAdminBuild">My Watchlist</span>
					</th>
					<th>{{ cmsResult.org.region }}</th>
					<th>{{ cmsResult.org.enrollmentLabel }} Enrollment Size</th>
					<th>{{ cmsResult.org.type }}</th>
					<th>Overall Average</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let field of enrollmentFields">
					<td class="th-header" [innerHtml]="field.name"></td>
					<td *ngIf="isEntryVerified" class="data">
						<span *ngIf="cmsResult.orgAverages[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.orgAverages[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.orgAverages[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.orgAverages[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.orgAverages[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.orgAverages[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.orgAverages[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.watchlistAverages[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.watchlistAverages[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.watchlistAverages[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.watchlistAverages[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.watchlistAverages[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.watchlistAverages[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.watchlistAverages[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.regionAverages[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.regionAverages[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.regionAverages[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.regionAverages[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.regionAverages[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.regionAverages[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.regionAverages[field.field]">No Data</span>
					</td>
					<td class="data {{ cmsResult.org.enrollmentLabel }}">
						<span *ngIf="cmsResult.enrollmentAverages[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.enrollmentAverages[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.enrollmentAverages[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.enrollmentAverages[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.enrollmentAverages[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.enrollmentAverages[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.enrollmentAverages[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.typeAverages[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.typeAverages[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.typeAverages[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.typeAverages[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.typeAverages[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.typeAverages[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.typeAverages[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.nationalAverages[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.nationalAverages[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.nationalAverages[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.nationalAverages[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.nationalAverages[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.nationalAverages[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.nationalAverages[field.field]">No Data</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<h3>Average Cost to Recruit a Student by Region and Enrollment Size</h3>
	<rd-chart [id]="chartId" [base]="averageCostToRecruitChartData" [extraCharts]="[nationalAverageChartData]" type="bar"></rd-chart>


	<div class="table--responsive">
		<table class="table table--striped">
			<thead>
				<tr>
					<th>Average Yield Rates, All Respondents</th>
					<th *ngIf="isEntryVerified">My School</th>
					<th>
						<span *ngIf="isAdminBuild">School Comparison</span>
						<span *ngIf="!isAdminBuild">My Watchlist</span>
					</th>
					<th>{{ cmsResult.org.region }}</th>
					<th>{{ cmsResult.org.enrollmentLabel }} Enrollment Size</th>
					<th>{{ cmsResult.org.type }}</th>
					<th>Overall Average</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let field of admissionFields">
					<td class="th-header" [innerHtml]="field.name"></td>
					<td *ngIf="isEntryVerified" class="data">
						<span *ngIf="cmsResult.orgAdmits[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.orgAdmits[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.orgAdmits[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.orgAdmits[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.orgAdmits[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.orgAdmits[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.orgAdmits[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.watchlistAdmits[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.watchlistAdmits[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.watchlistAdmits[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.watchlistAdmits[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.watchlistAdmits[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.watchlistAdmits[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.watchlistAdmits[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.regionAdmits[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.regionAdmits[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.regionAdmits[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.regionAdmits[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.regionAdmits[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.regionAdmits[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.regionAdmits[field.field]">No Data</span>
					</td>
					<td class="data {{ cmsResult.org.enrollmentLabel }}">
						<span *ngIf="cmsResult.enrollmentAdmits[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.enrollmentAdmits[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.enrollmentAdmits[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.enrollmentAdmits[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.enrollmentAdmits[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.enrollmentAdmits[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.enrollmentAdmits[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.typeAdmits[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.typeAdmits[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.typeAdmits[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.typeAdmits[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.typeAdmits[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.typeAdmits[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.typeAdmits[field.field]">No Data</span>
					</td>
					<td>
						<span *ngIf="cmsResult.nationalAdmits[field.field]">
							<span *ngIf="field.type == 'currency'">${{ cmsResult.nationalAdmits[field.field] | number:'0.2-2' }}</span>
							<span *ngIf="field.type == 'percentage'">{{ cmsResult.nationalAdmits[field.field] * 100 | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'percentage_only'">{{ cmsResult.nationalAdmits[field.field] | number:'0.2-2' }}%</span>
							<span *ngIf="field.type == 'number'">{{ cmsResult.nationalAdmits[field.field] | number:'0.0-0' }}</span>
							<span *ngIf="field.type == 'round'">{{ cmsResult.nationalAdmits[field.field] | number:'0.2-2' }}</span>
						</span>
						<span *ngIf="!cmsResult.nationalAdmits[field.field]">No Data</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<h3>Average Yield Rates, All Respondents</h3>
	<rd-chart [id]="chartId" [base]="admitsChartData()" type="doughnut"></rd-chart>
</ng-container>

<rd-alert *ngIf="!isEntryVerified" type="warning">
	<h2>Not Authorized</h2>
	<p>You must have your organization’s data verified for this survey to view this page.</p>
	<p>You may view previous reports for which you provided verified data using the filters above.</p>
</rd-alert>