"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var lodash_1 = require("lodash");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultFunnelBase = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultFunnelBase, _super);
    function CmsEntryResultFunnelBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CmsEntryResultFunnelBase.prototype.isFinalReport = function (id) {
        return this.getReportById(id).isFinalReport;
    };
    CmsEntryResultFunnelBase.prototype.getReportYear = function (id) {
        return this.getReportById(id).reportYear;
    };
    CmsEntryResultFunnelBase.prototype.getReportById = function (id) {
        return lodash_1.find(this.completedReports, function (report) { return report.id == id; });
    };
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultFunnelBase.prototype, "completedReports", void 0);
    CmsEntryResultFunnelBase = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultFunnelBase);
    return CmsEntryResultFunnelBase;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultFunnelBase = CmsEntryResultFunnelBase;
