"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var account_1 = require("@app/account/account");
var abs_submit_part_helper_1 = require("./abs-submit-part-helper");
var CmsEntryAbsSubmitPartBComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryAbsSubmitPartBComponent, _super);
    function CmsEntryAbsSubmitPartBComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CmsEntryAbsSubmitPartBComponent.prototype.ngOnInit = function () {
        this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;
        var numberValidators = [forms_1.Validators.max(8000000), forms_1.Validators.maxLength(5), forms_1.Validators.pattern(/^[0-9]*$/)];
        var decimalValidators = [
            forms_1.Validators.max(99.99),
            forms_1.Validators.maxLength(5),
            forms_1.Validators.pattern(/^\d+(?:\.\d{1,2})?$/)
        ];
        this.form = this.formBuilder.group({
            b_total_enr: [this.cmsResult.b_total_enr, numberValidators],
            b_ftns_fall: [this.cmsResult.b_ftns_fall, numberValidators],
            b_ftns_spring: [this.cmsResult.b_ftns_spring, numberValidators],
            b_ftns_total: { value: this.cmsResult.b_ftns_total, disabled: true },
            b_recruited_athletes: [this.cmsResult.b_recruited_athletes, decimalValidators]
        });
        if (!this.hasWritePermission()) {
            this.form.disable();
        }
        this.updateTotals('b_ftns_total', ['b_ftns_fall', 'b_ftns_spring']);
        this.listenForFormChanges();
    };
    CmsEntryAbsSubmitPartBComponent.prototype.updateTotals = function (updateField, fields, tweakTotal) {
        var _this = this;
        if (tweakTotal === void 0) { tweakTotal = null; }
        var _updateTotals = function () {
            var total = 0;
            fields.forEach(function (field) {
                total += parseInt(_this.form.controls[field].value || 0);
            });
            if (tweakTotal) {
                total = tweakTotal(total);
            }
            _this.form.controls[updateField].setValue(total);
        };
        fields.forEach(function (field) {
            _this.form.controls[field].valueChanges.subscribe(_updateTotals);
        });
        _updateTotals(); // trigger an update just in case legacy data wasn't saved properly
    };
    CmsEntryAbsSubmitPartBComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, entryId, result, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.form.invalid) {
                            this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
                            return [2 /*return*/];
                        }
                        if (this.isSubmitting) {
                            return [2 /*return*/];
                        }
                        data = this.form.getRawValue();
                        if (!this.sessionUser.isAdmin) {
                            data.status = 0; // status should always be 0 unless an admin is saving, in which case we keep the status unchanged
                        }
                        data.naccap_abs_surveys_id = this.cmsResult.report.id;
                        data.naccap_orgs_id = this.cmsResult.orgId || this.sessionUser.orgId;
                        data.user_id = this.sessionUser.userId;
                        data.b_last_user = this.sessionUser.userId;
                        data.b_last_update = new Date();
                        entryId = this.cmsResult.entryId;
                        if (entryId) {
                            data.id = entryId;
                        }
                        this.isSubmitting = true;
                        this.error = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!data.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.absEntryService.update(data)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.absEntryService.create(data)];
                    case 4:
                        result = _a.sent();
                        this.form.patchValue({ id: result.insertId });
                        _a.label = 5;
                    case 5:
                        this.isSaved = true;
                        this.onSaveSuccess();
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        this.error = err_1.error.error;
                        return [3 /*break*/, 7];
                    case 7:
                        this.isSubmitting = false;
                        this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
                        setTimeout(function () {
                            var url = lodash_1.get(_this.cmsResult.urls, 'abs-submit-part-c');
                            _this.router.navigate([url], { queryParamsHandling: 'merge' });
                        }, 2000);
                        return [2 /*return*/];
                }
            });
        });
    };
    return CmsEntryAbsSubmitPartBComponent;
}(abs_submit_part_helper_1.AbsSubmitPartHelper));
exports.CmsEntryAbsSubmitPartBComponent = CmsEntryAbsSubmitPartBComponent;
