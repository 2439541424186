"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./cms-entry-subpage.component");
var i2 = require("@angular/router");
var styles_CmsEntrySubpageComponent = [];
var RenderType_CmsEntrySubpageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CmsEntrySubpageComponent, data: {} });
exports.RenderType_CmsEntrySubpageComponent = RenderType_CmsEntrySubpageComponent;
function View_CmsEntrySubpageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "grid__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "grid__col--10 grid__col-offset--1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cmsResult.mainContent; _ck(_v, 2, 0, currVal_0); }); }
exports.View_CmsEntrySubpageComponent_0 = View_CmsEntrySubpageComponent_0;
function View_CmsEntrySubpageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rd-cms-entry-subpage", [], null, null, null, View_CmsEntrySubpageComponent_0, RenderType_CmsEntrySubpageComponent)), i0.ɵdid(1, 9027584, null, 0, i1.CmsEntrySubpageComponent, [i2.ActivatedRoute, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CmsEntrySubpageComponent_Host_0 = View_CmsEntrySubpageComponent_Host_0;
var CmsEntrySubpageComponentNgFactory = i0.ɵccf("rd-cms-entry-subpage", i1.CmsEntrySubpageComponent, View_CmsEntrySubpageComponent_Host_0, { cmsResult: "cmsResult", sessionUser: "sessionUser", type: "type" }, {}, []);
exports.CmsEntrySubpageComponentNgFactory = CmsEntrySubpageComponentNgFactory;
