"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./chart.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("ng-chartjs");
var i3 = require("./chart.component");
var styles_ChartComponent = [i0.styles];
var RenderType_ChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartComponent, data: {} });
exports.RenderType_ChartComponent = RenderType_ChartComponent;
function View_ChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "display: block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "canvas", [["ngChartjs", ""]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i2.NgChartjsDirective, [i1.ElementRef, i2.NgChartjsService, i2.ɵd], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"], legend: [5, "legend"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartDatasets; var currVal_1 = _co.chartLabels; var currVal_2 = _co.options; var currVal_3 = _co.type; var currVal_4 = _co.colors; var currVal_5 = _co.legend; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
exports.View_ChartComponent_0 = View_ChartComponent_0;
function View_ChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rd-chart", [], null, null, null, View_ChartComponent_0, RenderType_ChartComponent)), i1.ɵdid(1, 573440, null, 0, i3.ChartComponent, [], null, null)], null, null); }
exports.View_ChartComponent_Host_0 = View_ChartComponent_Host_0;
var ChartComponentNgFactory = i1.ɵccf("rd-chart", i3.ChartComponent, View_ChartComponent_Host_0, { id: "id", base: "base", extraCharts: "extraCharts", type: "type", legend: "legend" }, {}, []);
exports.ChartComponentNgFactory = ChartComponentNgFactory;
