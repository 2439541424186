"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var cms_entry_service_1 = require("@app/cms/cms-entry.service");
var account_service_1 = require("@app/account/account.service");
var CmsEntryComponent = /** @class */ (function () {
    function CmsEntryComponent(route, cmsEntryService, accountService) {
        var _this = this;
        this.route = route;
        this.cmsEntryService = cmsEntryService;
        this.accountService = accountService;
        this.cmsResult = null;
        this.accountService.loggedIn.subscribe(function (loggedIn) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.accountService.getSessionUser()];
                    case 1:
                        _a.sessionUser = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        this.cmsEntryService.navigationIncludingHidden$.subscribe(function (navigation) {
            _this.navigation = navigation;
            _this.navigationFlat = lodash_1.keyBy(_this._flattenNavigation(navigation), 'id');
            _this._setBreadcrumbs();
        });
    }
    CmsEntryComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.accountService.getSessionUser()];
                    case 1:
                        _a.sessionUser = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.cmsEntryService.getSiteUrlMap()];
                    case 2:
                        _b.urls = _c.sent();
                        rxjs_1.combineLatest(this.route.url, this.route.queryParams).subscribe(function (_a) {
                            var urlParts = _a[0], queryParams = _a[1];
                            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var url, _b;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            url = this.route.snapshot.url.join('/');
                                            _b = this;
                                            return [4 /*yield*/, this.getCmsEntry(url, this.route.snapshot.queryParams)];
                                        case 1:
                                            _b.cmsResult = _c.sent();
                                            this._setBreadcrumbs();
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    CmsEntryComponent.prototype.getCmsEntry = function (url, params) {
        if (url === '') {
            url = '/index';
        }
        if (!params) {
            params = this.route.snapshot.queryParams;
        }
        return this.cmsEntryService.getEntryByUrl(url, params);
    };
    CmsEntryComponent.prototype._flattenNavigation = function (navigation) {
        var _this = this;
        return navigation.reduce(function (results, item) {
            results.push(item);
            if (item.children) {
                results = results.concat(_this._flattenNavigation(item.children));
            }
            return results;
        }, []);
    };
    CmsEntryComponent.prototype._setBreadcrumbs = function () {
        var _this = this;
        if (!this.navigation) {
            return;
        }
        if (!this.cmsResult) {
            return;
        }
        var entry = this.navigationFlat[this.cmsResult.entry.id];
        if (!entry) {
            return (this.breadcrumbs = []);
        }
        var _breadcrumbs = [entry];
        var _attachParentEntry = function (entry, breadcrumbs) {
            if (!entry.parent_id) {
                return breadcrumbs;
            }
            var parentEntry = _this.navigationFlat[entry.parent_id];
            breadcrumbs.unshift(parentEntry);
            return _attachParentEntry(parentEntry, breadcrumbs);
        };
        _attachParentEntry(entry, _breadcrumbs);
        this.breadcrumbs = _breadcrumbs;
    };
    return CmsEntryComponent;
}());
exports.CmsEntryComponent = CmsEntryComponent;
