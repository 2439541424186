"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var Filename = /** @class */ (function () {
    function Filename() {
    }
    Filename.prototype.transform = function (path) {
        return getFilenameFromPath(path);
    };
    Filename.ngInjectableDef = i0.defineInjectable({ factory: function Filename_Factory() { return new Filename(); }, token: Filename, providedIn: "root" });
    return Filename;
}());
exports.Filename = Filename;
function getFilenameFromPath(path) {
    if (!path) {
        return path;
    }
    return path.replace(/^.*[\\\/]/, '');
}
exports.getFilenameFromPath = getFilenameFromPath;
