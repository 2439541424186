"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultReportHistory = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultReportHistory, _super);
    function CmsEntryResultReportHistory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultReportHistory.prototype, "completedAbsReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultReportHistory.prototype, "completedFunnelReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultReportHistory.prototype, "funnelReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultReportHistory.prototype, "funnelReportsData", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultReportHistory.prototype, "absReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultReportHistory.prototype, "orgs", void 0);
    CmsEntryResultReportHistory = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultReportHistory);
    return CmsEntryResultReportHistory;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultReportHistory = CmsEntryResultReportHistory;
