"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var cms_entry_result_report_history_1 = require("@app/cms/results/cms-entry-result-report-history");
var account_service_1 = require("@app/account/account.service");
var account_1 = require("@app/account/account");
var CmsEntryReportHistoryComponent = /** @class */ (function () {
    function CmsEntryReportHistoryComponent(accountService) {
        this.accountService = accountService;
        this.error = null;
        this.form = {};
        this.filledIds = {};
        this.allSubmitetReportsForOrg = {};
    }
    CmsEntryReportHistoryComponent.prototype.ngOnInit = function () {
        this.form.org_id = this.sessionUser.orgId;
    };
    CmsEntryReportHistoryComponent.prototype.ngOnChanges = function (changes) {
        if (changes.cmsResult) {
            this.cmsResult.completedFunnelReports = this.cmsResult.completedFunnelReports.map(function (report) {
                report.label = report.monthLabel + ", " + report.year;
                return report;
            });
        }
    };
    CmsEntryReportHistoryComponent.prototype.export = function () {
        var _this = this;
        this.error = null;
        if (!this.form.org_id) {
            this.error = { message: 'Please select an organization.' };
            return;
        }
        if (!this.form.type) {
            this.error = { message: 'Please select a report type.' };
            return;
        }
        var report_ids = this.form.type == 'funnel_reports' ? this.form.funnel_reports : this.form.abs_reports;
        // allow export only for submited reports
        if (typeof report_ids !== 'undefined') {
            if (this.form.type == 'funnel_reports') {
                // filter out only reports for selected org
                this.allSubmitetReportsForOrg = this.cmsResult.funnelReportsData.filter(function (item) { return item.naccap_orgs_id == _this.form.org_id; });
                this.filledIds = this.allSubmitetReportsForOrg.map(function (report) {
                    return report.naccap_app_reports_id;
                });
                var check = this.containsValue(report_ids, this.filledIds);
                if (!check) {
                    this.error = { message: 'You can only access data for studies where you have shared your data.' };
                    return;
                }
            }
            else if (this.form.type == 'abs_reports') {
                this.filledIds = this.cmsResult.absReports.map(function (report) {
                    return report.id;
                });
                var check = this.containsValue(report_ids, this.filledIds);
                if (!check) {
                    this.error = { message: 'You can only access data for studies where you have shared your data.' };
                    return;
                }
            }
        }
        this.accountService.exportHistory(this.form.type, this.form.org_id, report_ids, this.form.include_watchlist);
    };
    CmsEntryReportHistoryComponent.prototype.containsValue = function (arrA, arrB) {
        return arrA.some(function (x) { return arrB.includes(x); });
    };
    return CmsEntryReportHistoryComponent;
}());
exports.CmsEntryReportHistoryComponent = CmsEntryReportHistoryComponent;
