"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("@angular/common");
var i3 = require("./base-template.component");
var styles_BaseTemplateComponent = [];
var RenderType_BaseTemplateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BaseTemplateComponent, data: {} });
exports.RenderType_BaseTemplateComponent = RenderType_BaseTemplateComponent;
function View_BaseTemplateComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.url; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (_v.parent.context.$implicit.nav_title ? _v.parent.context.$implicit.nav_title : _v.parent.context.$implicit.title); _ck(_v, 2, 0, currVal_3); }); }
function View_BaseTemplateComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.nav_title ? _v.parent.context.$implicit.nav_title : _v.parent.context.$implicit.title); _ck(_v, 1, 0, currVal_0); }); }
function View_BaseTemplateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BaseTemplateComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BaseTemplateComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.last; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.last; _ck(_v, 4, 0, currVal_1); }, null); }
function View_BaseTemplateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "body__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "breadcrumbs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Home"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BaseTemplateComponent_2)), i0.ɵdid(7, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.breadcrumbs; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = _co.title; _ck(_v, 9, 0, currVal_4); }); }
function View_BaseTemplateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BaseTemplateComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "container content"]], null, null, null, null, null)), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_BaseTemplateComponent_0 = View_BaseTemplateComponent_0;
function View_BaseTemplateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "rd-base-template", [], null, null, null, View_BaseTemplateComponent_0, RenderType_BaseTemplateComponent)), i0.ɵdid(1, 114688, null, 0, i3.BaseTemplateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BaseTemplateComponent_Host_0 = View_BaseTemplateComponent_Host_0;
var BaseTemplateComponentNgFactory = i0.ɵccf("rd-base-template", i3.BaseTemplateComponent, View_BaseTemplateComponent_Host_0, { title: "title", breadcrumbs: "breadcrumbs" }, {}, ["*"]);
exports.BaseTemplateComponentNgFactory = BaseTemplateComponentNgFactory;
