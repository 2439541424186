"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var cerialize_1 = require("cerialize");
var cms_entry_result_1 = require("./cms-entry-result");
var CmsEntryResultAdminAbsTool = /** @class */ (function (_super) {
    tslib_1.__extends(CmsEntryResultAdminAbsTool, _super);
    function CmsEntryResultAdminAbsTool() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAdminAbsTool.prototype, "report", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAdminAbsTool.prototype, "participants", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAdminAbsTool.prototype, "enrollmentSort", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAdminAbsTool.prototype, "completedAbsReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAdminAbsTool.prototype, "allAbsReports", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAdminAbsTool.prototype, "averages", void 0);
    tslib_1.__decorate([
        cerialize_1.deserialize,
        tslib_1.__metadata("design:type", Object)
    ], CmsEntryResultAdminAbsTool.prototype, "funnelAverages", void 0);
    CmsEntryResultAdminAbsTool = tslib_1.__decorate([
        cerialize_1.inheritSerialization(cms_entry_result_1.CmsEntryResult)
    ], CmsEntryResultAdminAbsTool);
    return CmsEntryResultAdminAbsTool;
}(cms_entry_result_1.CmsEntryResult));
exports.CmsEntryResultAdminAbsTool = CmsEntryResultAdminAbsTool;
