"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var cms_entry_result_funnel_summary_1 = require("@app/cms/results/cms-entry-result-funnel-summary");
var account_1 = require("@app/account/account");
var CmsEntryFunnelSummaryComponent = /** @class */ (function () {
    function CmsEntryFunnelSummaryComponent(router, route) {
        this.router = router;
        this.route = route;
        this.defaults = {};
    }
    CmsEntryFunnelSummaryComponent.prototype.ngOnInit = function () {
        this.defaults = {
            reportId: this.route.snapshot.queryParams.id || this.cmsResult.mostRecentReport.id
        };
    };
    CmsEntryFunnelSummaryComponent.prototype.onFilterUpdate = function (filters) {
        var id = filters.reportId;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { id: id }
        });
    };
    return CmsEntryFunnelSummaryComponent;
}());
exports.CmsEntryFunnelSummaryComponent = CmsEntryFunnelSummaryComponent;
