"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NumberInputDirective = /** @class */ (function () {
    function NumberInputDirective() {
    }
    NumberInputDirective.prototype.onKeyDown = function (e) {
        if (
        // Allow: Delete, Backspace, Tab, Escape, Enter
        [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
            (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
            (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
            (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
            (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
            (e.keyCode === 82 && e.ctrlKey === true) || // Allow: Ctrl+R
            (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
            (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
            (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
            (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
            (e.keyCode === 82 && e.metaKey === true) || // Cmd+R (Mac)
            (e.keyCode >= 35 && e.keyCode <= 39) // Home, End, Left, Right
        ) {
            return; // let it happen, don't do anything
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
            (e.keyCode < 96 || e.keyCode > 105) &&
            (!this.settings.allowDecimals || (e.keyCode != 190 && e.keyCode != 110)) // decimals are ok
        ) {
            e.preventDefault();
        }
    };
    return NumberInputDirective;
}());
exports.NumberInputDirective = NumberInputDirective;
