"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var account_1 = require("@app/account/account");
var abs_entry_service_1 = require("@app/cms/abs-submit/abs-entry.service");
var AbsSubmitCommentComponent = /** @class */ (function () {
    function AbsSubmitCommentComponent(absEntryService) {
        this.absEntryService = absEntryService;
        this.newComment = '';
        this.error = null;
    }
    AbsSubmitCommentComponent.prototype.ngOnChanges = function (changes) {
        if (changes.entryId) {
            this.getComments();
        }
        if (changes.part) {
            this.getComments();
        }
    };
    AbsSubmitCommentComponent.prototype.getComments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.entryId) {
                            return [2 /*return*/];
                        }
                        if (!this.part) {
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.absEntryService.getComments(this.part, this.entryId)];
                    case 1:
                        _a.comments = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AbsSubmitCommentComponent.prototype.addComment = function () {
        var _this = this;
        this.error = null;
        var isCommentEmpty = lodash_1.trim(this.stripTags(this.newComment));
        if (!isCommentEmpty) {
            this.error = { message: 'Please add a comment first.' };
            return;
        }
        this.absEntryService
            .addComment(this.part, this.user.fullName, this.newComment, this.user.userId, this.entryId)
            .then(function () {
            if (_this.user.isAdmin) {
                _this.absEntryService.sendCommentToOrg(_this.cmsResult.orgId, _this.cmsResult.surveyId, _this.part, _this.newComment);
            }
            else {
                _this.absEntryService.sendCommentToAdmin(_this.user.userId, _this.cmsResult.surveyId, _this.part, _this.newComment);
            }
            _this.getComments();
            _this.newComment = '';
        })
            .catch(function (data) {
            _this.error = { message: data.error.error.message };
        });
    };
    AbsSubmitCommentComponent.prototype.stripTags = function (str) {
        var html = str;
        var div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };
    return AbsSubmitCommentComponent;
}());
exports.AbsSubmitCommentComponent = AbsSubmitCommentComponent;
