"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var cms_entry_result_abs_list_1 = require("@app/cms/results/cms-entry-result-abs-list");
var account_service_1 = require("@app/account/account.service");
var account_1 = require("@app/account/account");
var CmsEntryAbsListComponent = /** @class */ (function () {
    function CmsEntryAbsListComponent(router, route, accountService) {
        this.router = router;
        this.route = route;
        this.accountService = accountService;
        this.page = 1;
        this.filters = {};
        this.defaults = {};
    }
    CmsEntryAbsListComponent.prototype.ngOnInit = function () {
        this.defaults = {
            reportId: this.route.snapshot.queryParams.id || this.cmsResult.report.id,
            search: this.route.snapshot.queryParams.search
        };
        this.page = this.route.snapshot.queryParams.page;
    };
    Object.defineProperty(CmsEntryAbsListComponent.prototype, "reportId", {
        get: function () {
            return this.filters.reportId || this.defaults.reportId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryAbsListComponent.prototype, "search", {
        get: function () {
            return this.filters.search || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryAbsListComponent.prototype, "isVerifiedToViewResults", {
        get: function () {
            return this.sessionUser.isAdmin || this.cmsResult.isEntryVerified;
        },
        enumerable: true,
        configurable: true
    });
    CmsEntryAbsListComponent.prototype.isVerifiedToViewPartC = function (orgId) {
        return this.cmsResult.isVerifiedToViewPartC(this.sessionUser, orgId);
    };
    CmsEntryAbsListComponent.prototype.export = function () {
        this.accountService.exportAbsBudget(this.cmsResult.report.id);
    };
    CmsEntryAbsListComponent.prototype.onPageChanged = function (page) {
        this.page = page;
        this.updatePage();
    };
    CmsEntryAbsListComponent.prototype.onFilterUpdate = function (filters) {
        this.filters = filters;
        this.page = 1;
        this.updatePage();
    };
    CmsEntryAbsListComponent.prototype.updatePage = function () {
        var page = this.page;
        var search = this.search;
        var id = this.reportId;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: page, search: search, id: id }
        });
    };
    return CmsEntryAbsListComponent;
}());
exports.CmsEntryAbsListComponent = CmsEntryAbsListComponent;
