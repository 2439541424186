"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var cms_entry_result_1 = require("@app/cms/results/cms-entry-result");
var account_service_1 = require("@app/account/account.service");
var account_1 = require("@app/account/account");
var CmsEntryContactUsComponent = /** @class */ (function () {
    function CmsEntryContactUsComponent(accountService, formBuilder) {
        this.accountService = accountService;
        this.formBuilder = formBuilder;
        this.error = null;
        this.attemptedSubmit = false;
        this.saved = false;
        this.isSending = false;
        this.questionTypes = [
            'I have a question about the study',
            'I have a question about the site',
            'I have a question about my account',
            'I need to create a new account',
            'I need to delete an account',
            'Other...'
        ];
        this.createForm();
    }
    CmsEntryContactUsComponent.prototype.createForm = function () {
        var _this = this;
        this.form = this.formBuilder.group({
            fullname: ['', [forms_1.Validators.required]],
            email: ['', [forms_1.Validators.required, forms_1.Validators.email]],
            question: ['', forms_1.Validators.required],
            details: ['', forms_1.Validators.required],
            new_fullname: [''],
            new_title: [''],
            new_institution: [''],
            new_email: [''],
            delete_fullname: [''],
            delete_institution: ['']
        });
        this.form.controls.question.valueChanges.subscribe(function (value) {
            var newValidators = value === 'I need to create a new account' ? [forms_1.Validators.required] : null;
            var deleteValidators = value === 'I need to delete an account' ? [forms_1.Validators.required] : null;
            ['new_fullname', 'new_title', 'new_institution', 'new_email'].forEach(function (field) {
                _this.form.controls[field].setValidators(newValidators);
                _this.form.controls[field].updateValueAndValidity();
            });
            ['delete_fullname', 'delete_institution'].forEach(function (field) {
                _this.form.controls[field].setValidators(deleteValidators);
                _this.form.controls[field].updateValueAndValidity();
            });
        });
    };
    CmsEntryContactUsComponent.prototype.doesControlHaveError = function (control) {
        return (control.touched || this.attemptedSubmit) && control.invalid;
    };
    CmsEntryContactUsComponent.prototype.ngOnInit = function () { };
    CmsEntryContactUsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.attemptedSubmit = true;
        this.saved = false;
        if (this.form.invalid) {
            this.error = { message: 'Fix errors and try again.' };
            return;
        }
        this.isSending = true;
        this.accountService
            .sendContactRequest(this.form.value)
            .then(function () {
            _this.saved = true;
            _this.isSending = false;
            _this.form.reset();
            _this.attemptedSubmit = false;
        })
            .catch(function (data) {
            _this.error = { message: data.error.error.message };
            _this.isSending = false;
        });
    };
    return CmsEntryContactUsComponent;
}());
exports.CmsEntryContactUsComponent = CmsEntryContactUsComponent;
