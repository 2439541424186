"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var html2canvas = require("html2canvas");
var cms_entry_result_admin_abs_tool_1 = require("@app/cms/results/cms-entry-result-admin-abs-tool");
var account_1 = require("@app/account/account");
var CmsEntryAdminAbsToolComponent = /** @class */ (function () {
    function CmsEntryAdminAbsToolComponent(route, router) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.RANGE_LENGTH_DEFAULT = 5;
        this.RANGE_LENGTH_SHORT_DEFAULT = 4;
        this.rangeLength = this.RANGE_LENGTH_DEFAULT;
        this.rangeLengthShort = this.RANGE_LENGTH_SHORT_DEFAULT;
        this.pageFilters = {};
        this.salaryFields = [
            {
                label: 'Starting Counselor',
                field: 'c_adm_cnslr_start_sal'
            },
            {
                label: 'Senior Counselor',
                field: 'c_sr_cnslr_sal'
            },
            {
                label: 'Assistant Director',
                field: 'c_asst_dir_sal'
            },
            {
                label: 'Associate Director',
                field: 'c_assoc_dir_sal'
            },
            {
                label: 'Director of Admission',
                field: 'c_dir_adm_sal'
            },
            {
                label: 'VP/Dean of Enrollment',
                field: 'c_vpdean_sal'
            }
        ];
        this.carFields = [
            {
                label: 'Admission Counselors',
                field: 'c_car_adm_cnslr'
            },
            {
                label: 'Other Staff Members',
                field: 'c_car_other_staff'
            }
        ];
        this.funnelFields = [
            {
                label: 'Inquiry to Application',
                field: 'inquiry_to_app_percentage'
            },
            {
                label: 'App to Completed App',
                field: 'app_to_completed_percentage'
            },
            {
                label: 'Completed App to Admitted',
                field: 'completed_app_to_admitted_percentage'
            },
            {
                label: 'Admitted to Enrolled',
                field: 'admits_to_enrolled_percentage'
            },
            {
                label: 'Inquired to Enrolled',
                field: 'inquiry_to_enrolled_percentage'
            }
        ];
        this.participantsBreakdown = {};
        this.route.queryParams.subscribe(function (params) {
            _this.pageFilters = {
                id: +params.id || undefined
            };
        });
    }
    Object.defineProperty(CmsEntryAdminAbsToolComponent.prototype, "averagesFirstHalf", {
        get: function () {
            return lodash_1.take(this.cmsResult.averages, Math.ceil(this.cmsResult.averages.length / 2));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryAdminAbsToolComponent.prototype, "averagesLastHalf", {
        get: function () {
            return lodash_1.takeRight(this.cmsResult.averages, this.cmsResult.averages.length / 2);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryAdminAbsToolComponent.prototype, "averagesCurrentYearFirstHalf", {
        get: function () {
            return lodash_1.take(this.cmsResult.averages[0].data, Math.ceil(this.cmsResult.averages[0].data.length / 2));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CmsEntryAdminAbsToolComponent.prototype, "averagesCurrentYearLastHalf", {
        get: function () {
            return lodash_1.takeRight(this.cmsResult.averages[0].data, this.cmsResult.averages[0].data.length / 2);
        },
        enumerable: true,
        configurable: true
    });
    CmsEntryAdminAbsToolComponent.prototype.ngOnChanges = function (changes) {
        if (changes.cmsResult) {
            this.cmsResult.averages = this.cmsResult.averages.map(function (yearlyAverages) {
                yearlyAverages.data = yearlyAverages.data.map(function (averages) {
                    if (averages.c_pro_staff && averages.c_pro_staff_direct_recruiting) {
                        averages._professional_staff_overhead = averages.c_pro_staff - averages.c_pro_staff_direct_recruiting;
                    }
                    return averages;
                });
                return yearlyAverages;
            });
            this.rangeLength =
                this.RANGE_LENGTH_DEFAULT > this.cmsResult.completedAbsReports.length
                    ? this.cmsResult.completedAbsReports.length
                    : this.RANGE_LENGTH_DEFAULT;
            this.rangeLengthShort =
                this.RANGE_LENGTH_SHORT_DEFAULT > this.cmsResult.completedAbsReports.length
                    ? this.cmsResult.completedAbsReports.length
                    : this.RANGE_LENGTH_SHORT_DEFAULT;
            this.averagesSplit = [this.averagesFirstHalf, this.averagesLastHalf];
            this.averagesForCurrentYear = [this.averagesCurrentYearFirstHalf, this.averagesCurrentYearLastHalf];
            this.averageRange = lodash_1.take(this.cmsResult.averages, this.rangeLength).reverse();
            this.averageRangeShort = lodash_1.take(this.cmsResult.averages, this.rangeLengthShort).reverse();
            this.createParticipantBreakdown();
        }
    };
    CmsEntryAdminAbsToolComponent.prototype.createParticipantBreakdown = function () {
        var region = lodash_1.groupBy(this.cmsResult.participants, 'region');
        var regionSort = Object.keys(region).sort();
        var type = lodash_1.groupBy(this.cmsResult.participants, 'type');
        var typeSort = ['CCCU', 'Bible College', 'Other CLA'];
        var enrollment = lodash_1.groupBy(this.cmsResult.participants, 'enrollmentLabel');
        var enrollmentSort = Object.keys(enrollment).sort();
        this.participantsBreakdown = { region: region, type: type, enrollment: enrollment, regionSort: regionSort, typeSort: typeSort, enrollmentSort: enrollmentSort };
    };
    CmsEntryAdminAbsToolComponent.prototype.getIncreaseOverPreviousYear = function (field, year) {
        var previousEntry = lodash_1.find(this.cmsResult.averages, function (result) { return result.year == year - 1; });
        if (!previousEntry) {
            return undefined;
        }
        var currentEntry = lodash_1.find(this.cmsResult.averages, function (result) { return result.year == year; });
        if (!currentEntry) {
            return undefined;
        }
        return currentEntry.data[0][field] / previousEntry.data[0][field] - 1;
    };
    CmsEntryAdminAbsToolComponent.prototype.getIncreaseTotal = function (field) {
        var mostRecentValue = lodash_1.get(this.cmsResult.averages, "0.data[0]." + field);
        var firstValue = lodash_1.get(this.cmsResult.averages, this.cmsResult.averages.length - 1 + ".data[0]." + field);
        return mostRecentValue / firstValue - 1;
    };
    CmsEntryAdminAbsToolComponent.prototype.onReportUpdate = function (filters) {
        this.pageFilters = tslib_1.__assign({}, this.pageFilters, { id: filters.reportId });
        this.updatePage();
    };
    CmsEntryAdminAbsToolComponent.prototype.updatePage = function () {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: this.pageFilters
        });
    };
    CmsEntryAdminAbsToolComponent.prototype.onSave = function (element, filename) {
        var saveData = (function () {
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            return function (blob, fileName) {
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            };
        })();
        console.log('element', element, element.nativeElement);
        var nativeElement = element instanceof core_1.ElementRef ? element.nativeElement : element;
        html2canvas(nativeElement).then(function (canvas) {
            canvas.toBlob(function (blob) {
                saveData(blob, filename);
                // const link = window.URL.createObjectURL(blob);
                // window.open(link);
            });
        });
    };
    return CmsEntryAdminAbsToolComponent;
}());
exports.CmsEntryAdminAbsToolComponent = CmsEntryAdminAbsToolComponent;
