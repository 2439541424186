import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isArray } from 'lodash';

import { CmsEntryResultAbsSummary } from '@app/cms/results/cms-entry-result-abs-summary';
import { Account } from '@app/account/account';
import { AccountService } from '@app/account/account.service';
import { ChartData } from '@app/core/chart/chart.component';
import { SummaryFilters } from '@app/admin/admin-abs-summary-filters/admin-abs-summary-filters.component';
import { ReportFilters } from '@app/cms/funnel-report-filter/funnel-report-filter.component';

@Component({
	selector: 'rd-cms-entry-abs-summary',
	templateUrl: './cms-entry-abs-summary.component.html'
})
export class CmsEntryAbsSummaryComponent implements OnInit, OnChanges {
	constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountService) {
		this.route.queryParams.subscribe((params: any) => {
			let schools;
			if (params.comparison_schools) {
				schools = params.comparison_schools;
				if (!isArray(schools)) {
					schools = [schools];
				}
				schools = schools.map((id: number) => +id);
			}
			this.pageFilters = {
				survey_id: params.survey_id ? +params.survey_id : undefined,
				org_id: params.org_id ? +params.org_id : undefined,
				comparison_schools: schools
			};
		});
	}

	get isEntryVerified(): boolean {
		return this.cmsResult.isEntryVerified || this.sessionUser.isAdmin;
	}

	get isAdminBuild(): boolean {
		return !!this.pageFilters.org_id;
	}

	@Input()
	cmsResult: CmsEntryResultAbsSummary;

	@Input()
	sessionUser: Account;

	filters: any = {
		publicReport: null,
		memberReport: null
	};

	chartId = 0;

	pageFilters: AbsSummaryFilters = {};

	budgetFieldsMarketing = [
		{ name: 'Advertising', field: 'a_advertising' },
		{ name: 'Publications', field: 'a_publications' },
		{ name: 'Digital Media', field: 'a_elec_media' },
		{ name: 'Direct Mail', field: 'a_direct_mail' }
	];

	budgetFieldsOther = [
		{ name: 'Travel', field: 'a_travel' },
		{ name: 'Campus Guest Experiences', field: 'a_oncampus_ent' },
		{ name: 'Student Wages', field: 'a_student_wages' },
		{ name: 'Phone', field: 'a_phone' },
		{ name: 'Postage', field: 'a_postage' },
		{ name: 'Office Supplies', field: 'a_office_supplies' },
		{ name: 'Outside Assistance', field: 'a_outside_assistance' },
		{ name: 'Athletics Recruiting', field: 'a_athletics_recruiting' },
		{ name: 'CRM and Admission Technology', field: 'a_crm' },
		{ name: 'Professional Development', field: 'a_professional_development' },
		{ name: 'Other', field: 'a_other_budget' }
	];

	percentageFields2 = [
		{ name: 'Travel', field: 'a_travel' },
		{ name: 'Campus Guest Experiences', field: 'a_oncampus_ent' },
		{ name: 'Phone', field: 'a_phone' },
		{ name: 'Postage', field: 'a_postage' },
		{ name: 'Office Supplies', field: 'a_office_supplies' },
		{ name: 'Outside Assistance', field: 'a_outside_assistance' },
		{ name: 'Athletics Recruiting', field: 'a_athletics_recruiting' },
		{ name: 'CRM and Admission Technology', field: 'a_crm' },
		{ name: 'Professional Development', field: 'a_professional_development' },
		{ name: 'Other', field: 'a_other_budget' },
		{ name: 'Non-Salary Total', field: 'a_nonsalary_subtotal' },
		{ name: 'Professional Salaries', field: 'a_prof_salaries' },
		{ name: 'Support Salaries', field: 'a_support_salaries' },
		{ name: 'Student Wages', field: 'a_student_wages' },
		{ name: 'Total Salary and Fringe', field: 'a_salary_subtotal' }
	];

	fteAdministartorStaffFields = [
		{ name: 'FTE Professional Staff', field: 'c_pro_staff' },
		{ name: 'FTE Support Staff', field: 'c_support_staff' }
	];

	enrollmentFields: any;

	admissionFields: any;

	averageCostToRecruitChartData: ChartData;
	nationalAverageChartData: ChartData;
	setEnrollmentFields() {
		this.enrollmentFields = [
			{ name: 'Total Undergrad Enrollment', field: 'b_total_enr', type: 'number' },
			{ name: `Fall ${this.cmsResult.report.year} FT New Students`, field: 'b_ftns_fall', type: 'number' },
			{ name: `Spring ${this.cmsResult.report.year} FT New Students`, field: 'b_ftns_spring', type: 'number' },
			{ name: `Total ${this.cmsResult.report.year} FT New Students`, field: 'b_ftns_total', type: 'number' },
			{ name: 'FTE Professional Staff', field: 'c_pro_staff', type: 'round' },
			{ name: 'FTE Prof Staff &ndash; Direct Recruiting', field: 'c_pro_staff_direct_recruiting', type: 'round' },
			{ name: 'FTE Support Staff', field: 'c_support_staff', type: 'round' },
			{ name: 'FTE Total Admission Staff', field: 'total_admission_staff', type: 'round' },
			{ name: 'Starting Counselor Salary', field: 'c_adm_cnslr_start_sal', type: 'currency' },
			{ name: 'Ratio: New Students / Prof Staff FTE', field: 'ratio_new_students_per_staff', type: 'round' },
			{ name: 'Ratio: New Students / Recruiting FTE', field: 'ratio_new_students_per_recruiting', type: 'round' },
			{ name: 'Ratio: Total Budget $ / New Student', field: 'ratio_total_budget_per_new_student', type: 'currency' },
			{ name: 'Professional Staff Overhead', field: 'professional_staff_overhead', type: 'round' },
			{ name: 'Support Staff / Total Staff', field: 'ratio_support_staff_per_total_staff', type: 'percentage' },
			{ name: 'New Transfers', field: 'new_transfers', type: 'number' },
			{
				name: `Transfer % of Total Fall ${this.cmsResult.report.year} New Students`,
				field: 'percent_new_student_transfers',
				type: 'percentage'
			},
			{ name: '% Recruited Student Athletes', field: 'b_recruited_athletes', type: 'percentage_only' }
		];
	}
	setAdmissionFields() {
		this.admissionFields = [
			{ name: `${this.cmsResult.report.year} Grads Who Inquired`, field: 'd_inquired', type: 'number' },
			{ name: 'Inquiry to Application %', field: 'inquiry_to_app_percentage', type: 'percentage' },
			{ name: `${this.cmsResult.report.year} Grads Who Applied`, field: 'd_applied', type: 'number' },
			{ name: 'App to Completed App %', field: 'app_to_completed_percentage', type: 'percentage' },
			{ name: `${this.cmsResult.report.year} Grads Who Completed Apps`, field: 'd_completed_app', type: 'number' },
			{ name: 'Completed App to Admitted %', field: 'completed_app_to_admitted_percentage', type: 'percentage' },
			{ name: `${this.cmsResult.report.year} Grads Who Were Admitted`, field: 'd_accepted', type: 'number' },
			{ name: 'Admitted to Enrolled %', field: 'admits_to_enrolled_percentage', type: 'percentage' },
			{ name: `${this.cmsResult.report.year} Grads Who Enrolled`, field: 'd_enrolled', type: 'number' }
		];
	}

	percentageChartData(): ChartData {
		return {
			data: [
				+((this.cmsResult.nationalPercentages.a_prof_salaries || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_support_salaries || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_fringe_benefit || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_student_wages || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_advertising || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_publications || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_elec_media || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_direct_mail || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_travel || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_oncampus_ent || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_phone || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_postage || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_office_supplies || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalPercentages.a_other_budget || 0) * 100).toFixed(2)
			],
			labels: [
				'Professional Salaries',
				'Support Salaries',
				'Fringe Benefit Allowance',
				'Student Wages',
				'Advertising',
				'Publications',
				'Digital Media',
				'Direct Mail',
				'Travel',
				'Campus Guest Experiences',
				'Phone',
				'Postage',
				'Office Supplies',
				'Other'
			]
		};
	}
	setAverageCostToRecruitChartData() {
		const data: any = [];
		const nationalData: any = [];
		const labels: any = [];
		this.cmsResult.costToRecruitData.forEach((item: any) => {
			if (item.grouping != 'National') {
				data.push(+item.average.toFixed(2));
				labels.push(item.grouping);
				nationalData.push(+this.cmsResult.costToRecruitData[0].average.toFixed(2));
			}
		});
		this.averageCostToRecruitChartData = { data, labels, options: { label: 'Enrollment & Region Cost To Recruit' } };
		this.nationalAverageChartData = {
			data: nationalData,
			options: { label: 'National Average', type: 'line', fill: false }
		};
	}

	admitsChartData(): ChartData {
		return {
			data: [
				+((this.cmsResult.nationalAdmits.admits_to_enrolled_percentage || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalAdmits.completed_app_to_admitted_percentage || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalAdmits.app_to_completed_percentage || 0) * 100).toFixed(2),
				+((this.cmsResult.nationalAdmits.inquiry_to_app_percentage || 0) * 100).toFixed(2)
			],
			labels: [
				'Admitted to Enrolled',
				'Completed App to Admitted',
				'Application to Completed App',
				'Inquired to Applied'
			]
		};
	}

	ngOnInit(): void {
		this.setEnrollmentFields();
		this.setAdmissionFields();
		this.pageFilters.survey_id = this.cmsResult.report.id;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setFTEAdmissionStaffTotals();
		this.setAverageCostToRecruitChartData();
		this.chartId = +this.pageFilters.survey_id! || 0;
	}

	getMarketingSubtotal(type: string): number {
		let total = 0;
		switch (type) {
			case 'org':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.orgAverages[field.field] || 0));
				break;
			case 'watchlist':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.watchlistAverages[field.field] || 0));
				break;
			case 'region':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.regionAverages[field.field] || 0));
				break;
			case 'enrollment':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.enrollmentAverages[field.field] || 0));
				break;
			case 'type':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.typeAverages[field.field] || 0));
				break;
			case 'national':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.nationalAverages[field.field] || 0));
				break;
		}
		return total;
	}

	setFTEAdmissionStaffTotals(): void {
		let total = 0;
		this.fteAdministartorStaffFields.forEach(field => (total += this.cmsResult.orgAverages[field.field] || 0));
		this.cmsResult.orgAverages['total_admission_staff'] = total;
		total = 0;
		this.fteAdministartorStaffFields.forEach(field => (total += this.cmsResult.watchlistAverages[field.field] || 0));
		this.cmsResult.watchlistAverages['total_admission_staff'] = total;
		total = 0;
		this.fteAdministartorStaffFields.forEach(field => (total += this.cmsResult.regionAverages[field.field] || 0));
		this.cmsResult.regionAverages['total_admission_staff'] = total;
		total = 0;
		this.fteAdministartorStaffFields.forEach(field => (total += this.cmsResult.enrollmentAverages[field.field] || 0));
		this.cmsResult.enrollmentAverages['total_admission_staff'] = total;
		total = 0;
		this.fteAdministartorStaffFields.forEach(field => (total += this.cmsResult.typeAverages[field.field] || 0));
		this.cmsResult.typeAverages['total_admission_staff'] = total;
		total = 0;
		this.fteAdministartorStaffFields.forEach(field => (total += this.cmsResult.nationalAverages[field.field] || 0));
		this.cmsResult.nationalAverages['total_admission_staff'] = total;
	}

	getMarketingPercentageSubtotal(type: string): number {
		let total = 0;
		switch (type) {
			case 'org':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.orgPercentages[field.field] || 0));
				break;
			case 'watchlist':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.watchlistPercentages[field.field] || 0));
				break;
			case 'region':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.regionPercentages[field.field] || 0));
				break;
			case 'enrollment':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.enrollmentPercentages[field.field] || 0));
				break;
			case 'type':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.typePercentages[field.field] || 0));
				break;
			case 'national':
				this.budgetFieldsMarketing.forEach(field => (total += this.cmsResult.nationalPercentages[field.field] || 0));
				break;
		}
		return total;
	}

	updatePage() {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: this.pageFilters
		});
	}

	onFilterUpdate(filters: SummaryFilters) {
		this.pageFilters = { ...this.pageFilters, ...filters };
		this.updatePage();
	}

	onReportUpdate(filters: ReportFilters) {
		this.pageFilters = { ...this.pageFilters, survey_id: filters.reportId };
		this.updatePage();
	}

	export() {
		if (this.isAdminBuild) {
			this.accountService.exportAbsCustomSummary(
				this.cmsResult.report.id,
				this.cmsResult.org.id,
				this.pageFilters.comparison_schools
			);
		} else {
			this.accountService.exportAbsSummary(this.cmsResult.report.id);
		}
	}
}

interface AbsSummaryFilters {
	survey_id?: number;
	org_id?: number;
	comparison_schools?: number[];
}
