"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var cms_entry_result_abs_submit_1 = require("@app/cms/results/cms-entry-result-abs-submit");
var AbsSubmitWrapperComponent = /** @class */ (function () {
    function AbsSubmitWrapperComponent() {
    }
    AbsSubmitWrapperComponent.prototype.ngOnInit = function () { };
    return AbsSubmitWrapperComponent;
}());
exports.AbsSubmitWrapperComponent = AbsSubmitWrapperComponent;
