"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var account_service_1 = require("@app/account/account.service");
var cms_entry_result_1 = require("@app/cms/results/cms-entry-result");
var account_1 = require("@app/account/account");
var CmsEntryMyAccountComponent = /** @class */ (function () {
    function CmsEntryMyAccountComponent(accountService, formBuilder) {
        this.accountService = accountService;
        this.formBuilder = formBuilder;
        this.error = null;
        this.passwordError = null;
        this.saved = false;
        this.passwordSaved = false;
    }
    CmsEntryMyAccountComponent.prototype.createForm = function () {
        this.form = this.formBuilder.group({
            username: [this.sessionUser.username, [forms_1.Validators.required]],
            email: [this.sessionUser.email, [forms_1.Validators.required, forms_1.Validators.email]]
        });
        this.passwordForm = this.formBuilder.group({
            old_password: ['', forms_1.Validators.required],
            new_password: ['', forms_1.Validators.required],
            confirm_new_password: ['', forms_1.Validators.required]
        });
    };
    CmsEntryMyAccountComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    CmsEntryMyAccountComponent.prototype.onSubmit = function () {
        var _this = this;
        this.error = null;
        this.saved = false;
        if (this.form.invalid) {
            this.error = { message: 'Fix errors and try again.' };
            return;
        }
        this.sessionUser.username = this.form.value.username;
        this.sessionUser.email = this.form.value.email;
        this.accountService
            .saveAccount(this.sessionUser, false)
            .then(function () {
            _this.saved = true;
        })
            .catch(function (data) {
            _this.error = { message: data.error.error.message };
        });
    };
    CmsEntryMyAccountComponent.prototype.updatePassword = function () {
        var _this = this;
        this.passwordError = null;
        this.passwordSaved = false;
        if (this.passwordForm.invalid) {
            this.passwordError = { message: 'Fix errors and try again.' };
            return;
        }
        var values = this.passwordForm.value;
        this.accountService
            .updatePassword(this.sessionUser.userId, values.old_password, values.new_password, values.confirm_new_password)
            .then(function () {
            _this.passwordSaved = true;
        })
            .catch(function (data) {
            if (data.message) {
                _this.passwordError = { message: data.message };
                return;
            }
            _this.passwordError = { message: data.error.error.message };
        });
    };
    return CmsEntryMyAccountComponent;
}());
exports.CmsEntryMyAccountComponent = CmsEntryMyAccountComponent;
